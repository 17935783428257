import { produce } from 'immer';
import {
  mutateInitializeResult,
  mutateUpdateTrigger,
  newTrigger,
} from '../utils/triggers';
import { fillEmptyAdminTablesBoxes } from '../utils/table';
import { mutateDeleteSection, mutateInjectSection } from '../utils/sections';
import { ItemTypes } from '../constants/constants';

export const triggersActions = (set, get) => ({
  addTrigger: (parent, index = null) => {
    set(
      produce((draft) => {
        const parentId = parent.id;
        const trigger = newTrigger({ triggerType: ItemTypes.QUESTION });
        mutateInitializeResult({ parent, trigger });
        if (!draft.triggers[parentId]) {
          draft.triggers[parentId] = [];
        }
        if (index !== null) {
          draft.triggers[parentId].splice(index + 1, 0, trigger);
        } else {
          draft.triggers[parentId].push(trigger);
        }
      }),
      false,
      `add trigger to parent ${parent.id}`
    );
  },

  updateTrigger: (
    id,
    parentId,
    { type, triggerId, triggerIdFromSection, value, resultCallback }
  ) => {
    set(
      produce((draft) => {
        mutateUpdateTrigger({
          draft,
          parentId,
          id,
          type,
          triggerId,
          value,
          resultCallback,
          triggerIdFromSection,
        });
      }),
      false,
      `update trigger of id ${id} and parentId ${parentId}`
    );
  },
  reorderTriggers: (parentId, triggerIds) => {
    set(
      produce((draft) => {
        const parentTrigger = draft.triggers[parentId];
        parentTrigger.sort(
          (a, b) =>
            triggerIds.findIndex((id) => a.id === id) -
            triggerIds.findIndex((id) => b.id === id)
        );
      }),
      false,
      `Reorder triggers for parent ${parentId}`
    );
  },

  deleteTrigger: (id, parentId) => {
    set(
      produce((draft) => {
        draft.triggers[parentId] = draft.triggers[parentId].filter(
          ({ id: tId }) => tId !== id
        );
      }),
      false,
      `delete Trigger of id ${id}`
    );
  },

  deleteItemChangedByTriggers: (id) => {
    set(
      produce((draft) => {
        draft.itemsChangedByTriggers = draft.itemsChangedByTriggers.filter(
          ({ key }) => key !== id
        );
      }),
      false,
      `Delete Item Changed By Triggers of id ${id}`
    );
  },

  handleSectionsTriggersActivation: async () => {
    const sections = await Promise.all(
      get().sectionsToActivate.map(({ sectionId }) =>
        sectionId ? get().getTemplate(sectionId) : null
      )
    );
    const currentDocument = get().currentDocument;
    const sectionsContent = await Promise.all(
      sections.map(async (section) => {
        if (!section) return null;
        return {
          ...section.content,
          boxes: await fillEmptyAdminTablesBoxes({
            boxes: section.content.boxes,
            proposable_id: currentDocument.id,
            type: currentDocument.type,
          }),
        };
      })
    );
    set(
      produce((draft) => {
        sectionsContent.forEach((sectionContent, idx) => {
          const { id, sectionId, noId } = draft.sectionsToActivate[idx];
          mutateDeleteSection({ draft, boxId: id, optionId: noId });
          if (sectionContent)
            mutateInjectSection({
              draft,
              sectionContent,
              boxId: id,
              sectionId: sectionId,
              areItemsChangedByTriggers: true,
            });
        });
        draft.sectionsToActivate.splice(0, sectionsContent.length);
      }),
      false,
      `handleSectionsTriggersActivation}`
    );
  },
});
