import React from 'react'
import FormBoxName from './FormBoxName'
import { variableStyle } from '../../../utils/styles'
import { Checkbox, Form, Select } from 'antd'
import { useStore } from '../../../store/store';
import FormBoxRemark from './FormBoxRemark';
import { useTranslation } from 'react-i18next';
import TriggerArray from './TriggerArray';
import DefaultValueBox from './DefaultValueBox';

const selector = (id) =>
  ({ updateBox, selectedBoxId, configuration }) => {
    return {
      isSelected: selectedBoxId === id,
      updateBox,
      configuration
    };
  };

const LeftPart = ({ box, onBlur, form }) => {
  const { t } = useTranslation();
  const configuration = useStore(({ configuration }) => configuration)
  const isDisabled = box.from_linked_section
  const { updateBox } = useStore(selector(box.id));
  const handleChecked = () => {
    updateBox(box.id, (box) => {
      box.value_required = !box.value_required
    })
  }
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      <FormBoxName box={box} onBlur={onBlur} form={form} />

      <Form.Item
        label="Valeur requise"
        valuePropName="checked"
        rules={[{ required: true }]}
        className='variable-editor__input auto'
      >
        <Checkbox defaultChecked={box.value_required} onClick={() => handleChecked()} disabled={isDisabled} />
      </Form.Item>
      <Form.Item
        label={t('text-style')}
        name="textStyleId"
        initialValue={box?.textStyleId ?? configuration.textStyles[0].id}
      >
        <Select
          onSelect={onBlur}
          options={configuration.textStyles.map(style => ({ value: style.id, label: style.name }))}
          disabled={isDisabled}
        />
      </Form.Item>
      <FormBoxRemark box={box} onBlur={onBlur} form={form} />
    </div>
  )
}

const RightPart = ({ box, variablesGroupedByContainer }) => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      <DefaultValueBox box={box} />
      <TriggerArray box={box} variablesGroupedByContainer={variablesGroupedByContainer} />
    </div>
  )
}

const FormText = ({ box, onBlur, form, formPart, variablesGroupedByContainer }) => {

  return (
    <>
      {formPart === 'left' && <LeftPart box={box} onBlur={onBlur} form={form} />}
      {formPart === 'right' && <RightPart box={box} variablesGroupedByContainer={variablesGroupedByContainer} />}
    </>
  )
}

export default FormText
