import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Badge } from 'antd';
import { PRIMARY_COLOR } from '../../constants/constants';
import { checkOffset, checkStyle } from '../../utils/styles';

export const Checkable = ({ checked, children }) => {
  return (
    <Badge count={checked ? < FontAwesomeIcon icon={icon({ name: 'circle-check', style: 'regular' })} style={checkStyle} /> : 0} offset={checkOffset}>
      {children}
    </Badge>
  );
};
