import React, { useCallback } from 'react'
import { variableStyle } from '../../../utils/styles'
import FormCustomTextInfos from './FormCustomTextInfos';
import { useTranslation } from 'react-i18next';
import ButtonCommon from '../../common/ButtonCommon';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useStore } from '../../../store/store';
import TriggerArray from './TriggerArray';
import { useCreateCustomTextVariable, useUpdateVariableGroup } from '../../../hooks';
import { mutateInsertVariableInOrderArray } from '../../../utils/variableOrderArray';
import { convertToRaw } from 'draft-js';
import FormBoxEditorName from './FormBoxEditorName';
import { constructVariableIds, mutateHandleLinkedVariable } from '../../../utils/variableGroups';

const { confirm } = Modal;

const LeftPart = ({ box, validateFormAndRetrieveValues, form, setCreateItemType, variablesGroupedByContainerSelected }) => {
  const { t } = useTranslation()
  const deleteVariableInDocument = useStore(({ deleteVariableInDocument }) => deleteVariableInDocument)
  const addVariable = useStore(({ addVariable }) => addVariable)
  const updateVariableGroup = useStore(({ updateVariableGroup }) => updateVariableGroup)

  const {
    mutate: createCustomTextVariable,
    isLoading
  } = useCreateCustomTextVariable();
  const {
    mutate: updateVariableGroupMutation,
    isLoading: isLoadingUpdateVariableGroup
  } = useUpdateVariableGroup({
    onSettled: () => {
      setCreateItemType()
    },
    onSuccess: (updatedVariableGroup, { variableKey, id }) => {
      updateVariableGroup(id,
        (variableGroup, draft) => {
          mutateHandleLinkedVariable({
            variableGroup,
            draft,
            variableIds: updatedVariableGroup.variable_ids,
            variableId: variableKey
          })
        })
    }
  });


  const onCreate = useCallback(async () => {
    try {
      const values = await validateFormAndRetrieveValues()
      createCustomTextVariable({ name: values.name }, {
        onSuccess: (newVariable) => {
          addVariable(newVariable, (variables, variableKey, draft) => {
            variables[variableKey].remark = JSON.stringify(convertToRaw(values.remark?.getCurrentContent()))
            draft.boxVariableSelectedId = variableKey
            mutateInsertVariableInOrderArray(draft, variableKey)
            if (values["linked-group"]) {
              const variableGroup = draft.variableGroups.find(
                (v) => v.id === values["linked-group"]
              )
              const variableIds = constructVariableIds({ variableGroup, variableId: variableKey })
              updateVariableGroupMutation({ id: variableGroup.id, variableIds, variableKey })
            } else {
              setCreateItemType()
            }
          }
          )
        }
      })
    } catch (errors) {
    }
  }, [validateFormAndRetrieveValues, createCustomTextVariable, addVariable, setCreateItemType, updateVariableGroupMutation])

  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      {box.id === 'create-variable' && <div style={{
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: 8
      }}>
        <ButtonCommon
          type="add"
          onClick={onCreate}
          loading={isLoading || isLoadingUpdateVariableGroup}
          disabled={isLoading || isLoadingUpdateVariableGroup}
        >
          {t('create')}
        </ButtonCommon>
      </div>}
      {box.id !== 'create-variable' && <FormBoxEditorName box={box} variablesGroupedByContainerSelected={variablesGroupedByContainerSelected} />}
      <FormCustomTextInfos box={box} form={form} validateFormAndRetrieveValues={validateFormAndRetrieveValues} />
      {box.id !== 'create-variable' && <ButtonCommon
        type='delete'
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        onClick={() => {
          confirm({
            title: t('delete-custom-variable-text-modal', { name: box.name }),
            icon: <ExclamationCircleFilled />,
            okText: t('delete'),
            okType: "danger",
            cancelText: t('cancel'),
            onOk() {
              deleteVariableInDocument(box.id)
              return false;
            },
            onCancel() {
              return false;
            },
          });
        }}
      >
        {t('delete-custom-variable-text')}
      </ButtonCommon>}
    </div>
  )
}

const RightPart = ({ box, variablesGroupedByContainer }) => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      {box.id !== 'create-variable' && <TriggerArray box={box} variablesGroupedByContainer={variablesGroupedByContainer} />}
    </div>
  )
}

const FormCustomText = ({ box, form, validateFormAndRetrieveValues, formPart, setCreateItemType, variablesGroupedByContainer, variablesGroupedByContainerSelected }) => {
  return (
    <>
      {formPart === 'left' && <LeftPart setCreateItemType={setCreateItemType} box={box} validateFormAndRetrieveValues={validateFormAndRetrieveValues} form={form} variablesGroupedByContainerSelected={variablesGroupedByContainerSelected} />}
      {formPart === 'right' && <RightPart box={box} variablesGroupedByContainer={variablesGroupedByContainer} />}
    </>
  )
}

export default FormCustomText
