import React, { useMemo } from 'react'
import { ItemTypes, tableHeaderSecondaryBgColor } from '../../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Popover } from 'antd';
import { useStore } from '../../../store/store';
import { simulateVariableTextBox } from '../../../utils/boxes';
import DefaultValueBox from './DefaultValueBox';

const PopoverTriggerBox = ({ boxId }) => {
  const boxes = useStore(({ boxes }) => boxes)
  const variables = useStore(({ variables }) => variables)

  const box = useMemo(() => {
    const foundedBox = boxes.find(({ id }) => id === boxId)
    if (foundedBox) return foundedBox
    if (variables[boxId]) return simulateVariableTextBox([boxId, variables[boxId]])
    return null
  }, [boxId, boxes, variables])

  if (!box) return null

  return (
    <Popover
      placement='top'
      mouseLeaveDelay='0'
      zIndex={9997}
      content={<>
        {
          box.type !== ItemTypes.CUSTOM_TEXT_VARIABLE &&
          <DefaultValueBox box={box} onSelect={() => null} isShowing />
        }
        {box.type === ItemTypes.CUSTOM_TEXT_VARIABLE && box.name}
      </>}
    >
      < FontAwesomeIcon
        style={{
          color: tableHeaderSecondaryBgColor,
          marginLeft: 'auto',
          alignSelf: 'center'
        }}
        icon={icon({
          name: 'eye', style: 'duotone'
        })} />
    </Popover>
  )
}

export default PopoverTriggerBox
