import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import MenuWrapper from './MenuWrapper';
import { throttle } from 'lodash';
import SelectionVariableTranslator from './SelectionVariableTranslator';
import './SelectionVariableTranslator.less'
import SelectionCommandsTranslator from './SelectionCommandsTranslator';

const ContextualMenu = ({ editorState, setEditorState, withoutDelimiters, menuAncestorType, menuAncestorIds, defaultSelection, setDefaultSelection }) => {
  const [options, setOptions] = useState([])
  const [selectedOptionKey, setSelectedOptionKey] = useState()

  useLayoutEffect(() => {
    if (options?.length > 0) {
      setSelectedOptionKey(options[0].key)
    }
  }, [options])

  const startOffset = useMemo(() => {
    return editorState.getSelection().getStartOffset();
  }, [editorState]);

  const endOffset = useMemo(() => {
    return editorState.getSelection().getEndOffset();
  }, [editorState]);

  const blockText = useMemo(() => {
    const blockKey = editorState.getSelection().getStartKey()
    return editorState
      .getCurrentContent()
      .getBlockForKey(blockKey)
      .getText()
  }, [editorState])

  const handleNavigationInMenu = useCallback((event) => {
    if (!['ArrowUp', 'ArrowDown'].includes(event.key) || !options || options?.length < 1) return;

    event.stopPropagation()
    event.preventDefault()
    const isArrowUp = event.key === "ArrowUp"
    const isArrowDown = !isArrowUp

    setSelectedOptionKey((optionKey) => {
      const idx = options.findIndex(({ key }) => {
        return key === optionKey
      })

      if (idx === 0 && isArrowUp) return optionKey;
      if (idx === options.length - 1 && isArrowDown) return optionKey;

      const newVariableKeyIdx = isArrowUp ? idx - 1 : idx + 1
      return options[newVariableKeyIdx].key
    })
  }, [options])

  useEffect(() => {
    const throttledNavigation = throttle(handleNavigationInMenu, 10)
    window.addEventListener('keydown', throttledNavigation, true);
    return () => {
      window.removeEventListener('keydown', throttledNavigation, true);
    };
  }, [handleNavigationInMenu]);

  const delimiter = useMemo(() => {
    let startVariableName;
    let usedDelimiters = ["@", "/"]
    if (withoutDelimiters) {
      usedDelimiters = usedDelimiters.filter((delimiter) => !withoutDelimiters.includes(delimiter))
    }
    for (
      startVariableName = startOffset - 1;
      startVariableName > 0 && !usedDelimiters.includes(blockText[startVariableName]);
      startVariableName -= 1);
    if (!usedDelimiters.includes(blockText[startVariableName])) {
      return ''
    }
    return blockText[startVariableName]
  }, [blockText, startOffset, withoutDelimiters])

  useEffect(() => {
    if (!delimiter) setOptions([])
  }, [delimiter])

  return (
    <MenuWrapper startOffset={startOffset} hide={!options || options.length < 1}>
      {delimiter === '@' && <SelectionVariableTranslator
        options={options}
        selectedOptionKey={selectedOptionKey}
        setSelectedOptionKey={setSelectedOptionKey}
        setEditorState={setEditorState}
        startOffset={startOffset}
        endOffset={endOffset}
        blockText={blockText}
        setOptions={setOptions}
      />}
      {delimiter === '/' && <SelectionCommandsTranslator
        options={options}
        selectedOptionKey={selectedOptionKey}
        setSelectedOptionKey={setSelectedOptionKey}
        setEditorState={setEditorState}
        startOffset={startOffset}
        endOffset={endOffset}
        blockText={blockText}
        setOptions={setOptions}
        menuAncestorType={menuAncestorType}
        menuAncestorIds={menuAncestorIds}
        defaultSelection={defaultSelection}
        setDefaultSelection={setDefaultSelection}
      />}
    </MenuWrapper>
  )
}

export default ContextualMenu
