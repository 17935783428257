import React from 'react'
import './VariableHandler.less'
import { navbarHeight, pageHeight, pageWidth } from '../../constants/gridConfig'
import { useStore } from '../../store/store'
import { getPrimaryDark, pageBackground } from '../../constants/constants'
import FormViewer from './FormViewer/FormViewer'
import { ConfigProvider } from 'antd'
import FormCreator from './FormCreator/FormCreator'
import FormItemsArray from './FormCreator/FormItemsArray'

const selector = ({
  currentDocument,
  boxVariableSelectedId,
  landscape
}) => {
  return {
    documentType: currentDocument.type,
    landscape,
    boxVariableSelectedId
  }
}

const VariableHandler = ({ variablesGroupedByContainer, columnToShow, hidePreview }) => {
  const { documentType, landscape } = useStore(selector)
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')

  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            colorTextDisabled: '#0000003f',
            colorBgContainerDisabled: '#000000a'
          },
        }
      }}
    >
      <div
        className='variable-editor__wrapper'
        data-role={pageBackground}
        style={{
          position: 'fixed',
          top: navbarHeight,
          left: 0,
          width: '100vw',
          backgroundColor: "#f5f5f5",
          height: window.innerHeight - navbarHeight,
          zIndex: 16,
        }}
      >
        <div style={{
          position: 'relative',
          width: "100%",
          height: "100%",
          display: 'flex',
          flexDirection: 'column'
        }}
          data-role={pageBackground}
        >

          {documentType === 'template' ?
            <FormCreator variablesGroupedByContainer={variablesGroupedByContainer} columnToShow={columnToShow} hidePreview={hidePreview} /> :
            <div style={{
              display: 'grid',
              gridTemplateColumns: `minmax(auto, ${landscape ? pageHeight : pageWidth + 64}px) minmax(${landscape ? pageHeight : pageWidth + 64}px, 60%)`,
              flex: 1,
              backgroundColor: getPrimaryDark(isTemplate),
              overflow: 'hidden',
              width: "100%",
              padding: '0 16px 16px 16px',
              flexDirection: 'column',
              height: "100%",
              paddingTop: 60,
              gap: 32
            }}
              data-role={pageBackground}>
              <div style={{}}>
                <FormItemsArray variablesGroupedByContainer={variablesGroupedByContainer} />
              </div>
              <div style={{ marginLeft: 'auto', width: "100%" }}>
                <FormViewer variablesGroupedByContainer={variablesGroupedByContainer} />
              </div>
            </div>
          }

        </div>
      </div>
    </ConfigProvider>
  )
}

export default VariableHandler
