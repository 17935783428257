export const variableIsAnImage = (item) => {
  return (
    item.indexOf('LOGO') > -1 ||
    item.indexOf('PHOTO') > -1 ||
    item.indexOf('PICTURE') > -1 ||
    item.indexOf('CHART') > -1 ||
    item.indexOf('OFFICE_PICTURE') > -1 ||
    item.indexOf('ORGANIZATIONAL_CHART') > -1 ||
    item.indexOf('QUALITY_TAGS') > -1 ||
    item.indexOf('TEAM_PICTURE') > -1 ||
    item.indexOf('REFERENCIES_PICTURE') > -1
  );
};

export const checkIfVariableIsEmpty = (variable) => {
  return !variable || (variable && variable.length === 0);
};

export const formateVariable = (item, variable) => {
  if (item.indexOf('PROVIDERS') > -1 || item.indexOf('SITE_TYPOLOGIES') > -1) {
    let result = '';
    let idx = 0;

    while (idx < variable.length) {
      result += variable[idx];
      if (idx + 1 !== variable.length) {
        result += ', ';
      }
      idx++;
    }
    return result;
  } else if (
    item.indexOf('QUOTATION_START_DATE') > -1 ||
    item.indexOf('QUOTATION_END_DATE') > -1 ||
    item.indexOf('SUBMISSION_DATE') > -1 ||
    item.indexOf('CUSTOMER_DECISION_DATE') > -1
  ) {
    const day = new Date(variable).getDate();
    const month = new Date(variable).getMonth();
    const year = new Date(variable).getFullYear();
    return `${day}/${month + 1}/${year}`;
  } else {
    return variable;
  }
};
