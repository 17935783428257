//ANT Design
import { message } from 'antd';

// Axios
import Axios from 'axios';
import { getUrlParamsObject } from '../utils/browser';
require('dotenv').config();

const instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

instance.interceptors.request.use(
  (config) => {
    const jwt = getUrlParamsObject().jwt || window.localStorage.getItem('jwt');
    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`;
    }
    return config;
  },
  (error) => {
    message.error({
      content: "Nous n'avons pas réussi à vous identifier.",
      key: 401,
    });
    Promise.reject(error);
  }
);

// instance.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      message.error({
        content:
          "Vous n'êtes pas autorisé à utiliser ce template ou ce document",
        key: 401,
      });
    } else if (error.response.status === 404) {
      message.error({
        content: "Le document demandé n'existe pas",
        key: 404,
      });
    }
    throw error;
  }
);

export { instance };
