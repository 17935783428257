import { Transfer } from 'antd';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import { addStylesheetURLPreview, fontsUrlBuilder } from '../../constants/constants';
import { searchInputWidth } from '../../constants/gridConfig';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const selector = ({
  changeFontFamilyListInConfig,
  configuration,
}) => ({
  changeFontFamilyListInConfig,
  selectedFontFamilies: configuration.fontFamilyList,
});

export const FamilyPicker = ({ selectFamily }) => {
  const { changeFontFamilyListInConfig, selectedFontFamilies } = useStore(selector);
  const [fontFamilyListFromApi, setFontFamilyListFromApi] = useState([]);//list of items from api call
  const [localFamilyList, setLocalFamilyList] = useState([]);//list transmitted to transfer component
  const { t } = useTranslation();
  // const [inputFamilyName, setInputFamilyName] = useState('');

  const getFonts = async () => {
    const res = await Axios.get(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDsgQOOM-7PyeGE3bSedUNYyQ9NTQvANMk'
    );
    const apiFontFamilyList = [];
    res?.data?.items.forEach(family => {
      const data = {
        key: family.family.toString().replace(/\s+/g, "_"),
        familyName: family.family.toString()
      }
      apiFontFamilyList.push(data);
    });
    setFontFamilyListFromApi(apiFontFamilyList);
  };

  useEffect(() => {
    if (fontFamilyListFromApi.length <= 0) { getFonts(); }
    setLocalFamilyList(fontFamilyListFromApi);
  }, [fontFamilyListFromApi])

  const onChange = (newFamiliesKeys, _direction, _moveKeys) => {
    changeFontFamilyListInConfig(newFamiliesKeys.map((familyKey) => fontFamilyListFromApi.find((f) => f.key === familyKey)));
  };

  const filterOption = (inputValue, option) => _.capitalize(option.familyName).indexOf(_.capitalize(inputValue)) > -1;

  return (
    <Transfer
      className={selectedFontFamilies.length > 1 ? '' : 'transfer-remove-icon'}
      showSelectAll={false}
      dataSource={localFamilyList}
      targetKeys={selectedFontFamilies.map((f) => f.key)}
      showSearch
      titles={[t('accessible-fonts'), t('selected-fonts')]}
      onChange={onChange}
      filterOption={filterOption}
      locale={{
        itemUnit: t('font'),
        itemsUnit: t('fonts'),
        searchPlaceholder: t('search-font'),
        notFoundContent: t('no-font'),
      }}
      render={(item) =>
        <div
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => {
            addStylesheetURLPreview(fontsUrlBuilder([item]));
            selectFamily([item.familyName]);
          }}
          style={{ fontFamily: `${item.familyName}` }}
        >
          {item.familyName}
        </div>
      }
      oneWay={true}
      listStyle={{ width: searchInputWidth, height: 300 }}
    />
  );
};
