import React, { useCallback, useState } from 'react'
import { Button, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { newQuestionBox } from '../../../utils/boxes';
import { useStore } from '../../../store/store';
import { ItemTypes, MASKS_TYPE } from '../../../constants/constants';
import { convertToRaw } from 'draft-js';
import produce from 'immer';
import { mutateUpdateTrigger } from '../../../utils/triggers';
import { transformTextToEditorState } from '../../../utils/dataSync';
import FormCustomTextInfos from './FormCustomTextInfos';
import { useCreateCustomTextVariable } from '../../../hooks';
import { mutateInsertVariableInOrderArray } from '../../../utils/variableOrderArray';
import FormCheckboxInfos from './FormCheckboxInfos';

const FormBoxCreate = ({ closeModal, parentId, record, newBoxType }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation()
  const gridConfiguration = useStore(({ gridConfiguration }) => gridConfiguration)
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const configuration = useStore(({ configuration }) => configuration)
  const landscape = useStore(({ landscape }) => landscape)
  const addBox = useStore(({ addBox }) => addBox)
  const addVariable = useStore(({ addVariable }) => addVariable)
  const {
    mutate: createCustomTextVariable,
    isLoading
  } = useCreateCustomTextVariable();

  const instantiateBox = useCallback(() => {
    if (newBoxType === ItemTypes.QUESTION)
      return newQuestionBox({
        gridConfiguration,
        landscape,
        configuration,
        header,
        footer,
      })
    return {
      name: "",
      type: ItemTypes.CUSTOM_TEXT_VARIABLE,
      remark: transformTextToEditorState(),
      id: "create-variable"
    }
  }, [configuration, footer, gridConfiguration, header, landscape, newBoxType])
  const [box, setBox] = useState(instantiateBox());

  const onFinish = useCallback((values, callback) => {
    setBox(produce((b) => {
      if (typeof callback === "function") callback(b)
      Object.keys(values).forEach((key) => {
        if (Array.isArray(values[key])) {
          values[key].forEach((value, index) => {
            b[key][index] = {
              ...b[key][index],
              ...value
            }
          })
        }
        else {
          if (key === 'remark') {
            b[key] = JSON.stringify(convertToRaw(values[key].getCurrentContent()))
          } else {
            b[key] = values[key]
          }
        }
      })
    }))
  }, [])

  const validateFormAndRetrieveValues = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue()
      return values
    } catch (errors) {
      throw errors
    }
  }, [form])

  const onBlur = useCallback(async (callback) => {
    try {
      const values = await validateFormAndRetrieveValues()
      onFinish(values, callback)
    } catch (errors) {
    }
  }, [validateFormAndRetrieveValues, onFinish])

  const updateBoxFn = useCallback((boxId, callback) => {
    setBox(produce((b) => {
      callback(b)
    }))
  }, [])

  const onCreateCustomText = useCallback(async (values) => {
    try {
      createCustomTextVariable({ name: values.name }, {
        onSuccess: (newVariable) => {
          addVariable(newVariable, (variables, variableKey, draft) => {
            variables[variableKey].remark = JSON.stringify(convertToRaw(values.remark?.getCurrentContent()))
            mutateUpdateTrigger({
              draft,
              parentId,
              id: record.id,
              triggerId: variableKey,
            })
            mutateInsertVariableInOrderArray(draft, variableKey)
          })
        }
      })
    } catch (errors) {
    }
  }, [createCustomTextVariable, addVariable, parentId, record.id])

  const addNewBox = useCallback(async () => {
    try {
      const values = await validateFormAndRetrieveValues()
      if (newBoxType === ItemTypes.QUESTION)
        addBox(box, (box, draft) => {
          mutateUpdateTrigger({
            draft,
            parentId,
            id: record.id,
            triggerId: box.id,
          })
        })
      else onCreateCustomText(values)
      closeModal()
    } catch (errors) {
    }
  }, [addBox, box, closeModal, newBoxType, onCreateCustomText, parentId, record.id, validateFormAndRetrieveValues])

  const getTitle = () => {
    if (newBoxType === ItemTypes.QUESTION) {
      return t('create_question')
    }
    if (newBoxType === ItemTypes.CUSTOM_TEXT_VARIABLE) {
      return t('create_variable')
    }
    return null
  }

  return (
    <Form
      style={{
        overflow: 'hidden'
      }}
      form={form}
      colon={false}
      validateMessages={{ required: "Champ obligatoire." }}
      className='variable-editor__form'
    >
      <div style={{
        color: 'white',
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 24
      }}>{getTitle()}</div>
      {newBoxType === ItemTypes.QUESTION && <FormCheckboxInfos box={box} onBlur={onBlur} form={form} updateBoxFn={updateBoxFn} />}
      {newBoxType === ItemTypes.CUSTOM_TEXT_VARIABLE && <FormCustomTextInfos box={box} form={form} validateFormAndRetrieveValues={validateFormAndRetrieveValues} />}
      <div style={{
        width: "100%",
        display: 'flex',
        gap: 16,
        marginTop: 32,
        justifyContent: 'end'
      }}>
        <Button
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="primary"
          onClick={addNewBox}
          loading={isLoading}
          disabled={isLoading}
        >
          {t('create')}
        </Button>
      </div>
    </Form>
  )
}

const FormBoxCreateModal = ({ isModalOpen, setIsModalOpen, parentId, record, newBoxType, setNewBoxType }) => {
  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    setNewBoxType()
  }, [setIsModalOpen, setNewBoxType])


  return (
    <Modal
      open={isModalOpen}
      onCancel={closeModal}
      destroyOnClose
      className='form-box-create-modal'
      wrapClassName='form-box-create-modal-mask'
      closable={false}
      footer={null}
    >
      <FormBoxCreate closeModal={closeModal} parentId={parentId} record={record} newBoxType={newBoxType} />
    </Modal>
  )
}

export default FormBoxCreateModal
