import { Select } from 'antd';

export const bulletList = [
  '\u2192',
  '\u21A3',
  '\u21A6',
  '\u21AA',
  '\u21B3',
  '\u21C0',
  '\u21C9',
  '\u21CF',
  '\u21D2',
  '\u21DB',
  '\u21E2',
  '\u21E5',
  '\u21E8',
  '\u21F0',
  '\u21FE',
  '\u27F6',
  '\u27F9',
  '\u27FC',
  '\u27FE',
  '\u2905',
  '\u290D',
  '\u290F',
  '\u2911',
  '\u2916',
  '\u292B',
  '\u292C',
  '\u2933',
  '\u2937',
  '\u294A',
  '\u2964',
  '\u2794',
  '\u2799',
  '\u279B',
  '\u279C',
  '\u279D',
  '\u279E',
  '\u279F',
  '\u27A0',
  '\u27A1',
  '\u27A2',
  '\u27A4',
  '\u27A5',
  '\u27A7',
  '\u27A8',
  '\u27A9',
  '\u27AA',
  '\u27AB',
  '\u27AE',
  '\u27AF',
  '\u27B2',
  '\u27B5',
  '\u27BC',
  '\u002B',
  '\u2212',
  '\u00D7',
  '\u003E',
  '\u00B0',
  '\u2217',
  '\u2218',
  '\u2219',
  // '\u221F',
  // '\u2225',
  '\u222B',
  '\u222C',
  '\u222D',
  '\u2234',
  '\u2235',
  '\u2237',
  '\u223C',
  '\u223F',
  '\u2248',
  '\u2261',
  '\u2265',
  '\u226B',
  '\u227B',
  '\u2283',
  // '\u228F',
  // '\u2290',
  '\u2295',
  '\u2296',
  '\u2297',
  '\u2298',
  '\u2299',
  '\u229B',
  '\u229E',
  '\u229F',
  '\u22A0',
  // '\u22A2',
  // '\u22A3',
  '\u22B3',
  '\u22B9',
  '\u22C4',
  '\u22C5',
  '\u22C6',
  // '\u22C8',
  '\u22D9',
  // '\u22EE',
  // '\u22EF',
  // '\u22F0',
  // '\u22F1',
  '\u2070',
  '\u207A',
  '\u207B',
  '\u0023',
  '\u002A',
  '\u002E',
  '\u002F',
  '\u005F',
  '\u007E',
  '\u00B7',
  '\u2010',
  '\u2013',
  '\u2014',
  '\u2022',
  '\u2023',
  '\u2024',
  '\u2025',
  '\u2026',
  '\u203A',
  '\u203B',
  '\u2055',
  '\u2126',
  '\u2605',
  '\u2606',
  '\u2609',
  // '\u260E',
  // '\u260F',
  '\u2610',
  '\u2611',
  '\u2612',
  '\u261E',
  // '\u2622',
  '\u2630',
  // '\u2639',
  // '\u263A',
  // '\u263B',
  '\u2662',
  // '\u2690',
  // '\u2691',
  '\u269D',
  '\u26AC',
  // '\u2706',
  // '\u2709',
  '\u2713',
  '\u2714',
  '\u2715',
  '\u2716',
  '\u2717',
  '\u2718',
  '\u271A',
  '\u2726',
  '\u2727',
  '\u272A',
  '\u272D',
  '\u2731',
  '\u273D',
  '\u2756',
  '\u004F',
  '\u006F',
  // '\u1', Format non valide
  // '\u9', Format non valide
  // '\u152', Format non valide
  // '\u173', Format non valide
  // '\u183', Format non valide
  // '\u189', Format non valide
  // '\u194', Format non valide
  // '\u205', Format non valide
  // '\u993', Format non valide
  // '\u1010',
  // '\u1020',
  // '\u1277',
  // '\u1377',
  // '\u1379',
  // '\u1380',
  // '\u1392',
  // '\u1401',
  // '\u1439',
  // '\u1451',
  // '\u1452',
  // '\u1484',
  // '\u1485',
  // '\u1486',
  // '\u1488',
  // '\u1493',
  // '\u1494',
  // '\u1558',
  // '\u1567',
  // '\u1568',
  // '\u1569',
  // '\u1570',
  // '\u1582',
  // '\u1586',
  '👉',
  '✅',
  '❎',
  '✔',
  '💥',
  '⭕',
  '✳',
  'Defaut',
];


//WIP Child to Parent communiaction
export const BulletListComponent = () => {
  const { Option } = Select;
  return (
    <Select style={{ width: '150px', textAlign: 'center' }}>
      {bulletList.map((element, index) => {
        return (
          <Option
            key={index}
            value={element}
            style={{ textAlign: 'center', fontSize: '1.5vw' }}
          >
            {element}
          </Option>
        );
      })}
    </Select>
  );
};
