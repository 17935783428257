import React from 'react'
import { ImageComponent } from '../Image/Image'

const FloatyImageMenu = ({
  positions,
  simulatedBox,
  updateImage
}) => {
  if (positions.top === null || positions.left === null) return null
  return (
    <div style={{
      position: 'absolute',
      zIndex: 9998,
      background: '#0b2239',
      padding: '4px 6px',
      borderRadius: 8,
      top: positions.top - 50,
      left: positions.left
    }}
      onMouseDown={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <ImageComponent box={simulatedBox} updateImage={updateImage} />
    </div>
  )
}

export default FloatyImageMenu
