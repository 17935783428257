import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import TabsCheckbox from './checkboxTab';
import { useStore } from '../../../store/store';
import { ItemTypes } from '../../../constants/constants';
import TabsText from './textTab';
import SelectorTab from './selectorTab';

const selector = ({
  configuration,
  setConfiguration,
}) => ({
  configuration,
  setConfiguration
});

export const TabsVariable = () => {
  const { t } = useTranslation();
  const {
    configuration,
    setConfiguration,
  } = useStore(selector);

  const styleRuleChangeObject = useCallback((options = {}) => {
    return {
      setFunction: setConfiguration,
      configObject: configuration,
    };
  }, [configuration, setConfiguration]);

  const handleFontStyle =
    useCallback((type, options = {}) =>
      (e) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            fontStyle: e.target.checked ? 'italic' : '',
          },
        });
      }, [styleRuleChangeObject]);

  const handleColor =
    useCallback((type, options = {}) =>
      (selectedColor) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            color: configuration.colors.find(
              (color) => color.label === selectedColor
            ),
          },
        });
      }, [configuration?.colors, styleRuleChangeObject]);

  const handleFontWeight =
    useCallback((type, options = {}) =>
      (e) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            fontWeight: e.target.checked ? 'bold' : '',
          },
        });
      }, [styleRuleChangeObject]);

  const handleTextDecoration =
    useCallback((type, options = {}) =>
      (e) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            textDecoration: e.target.checked ? 'underline' : '',
          },
        });
      }, [styleRuleChangeObject]);

  const handleFontSize =
    useCallback((type, options = {}) =>
      (value) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: { ...change.configObject[type], fontSize: value },
        });
      }, [styleRuleChangeObject]);

  const handleBackgroundColor = useCallback((type) => (selectedColor) => {
    setConfiguration({
      [type]: {
        ...configuration[type],
        backgroundColor: configuration.colors.find(
          (color) => color.label === selectedColor
        ),
      },
    });
  }, [configuration, setConfiguration]);

  const handleBorderColor = useCallback((type) => (selectedColor) => {
    setConfiguration({
      [type]: {
        ...configuration[type],
        borders: {
          ...configuration[type].borders,
          color: configuration.colors.find(
            (color) => color.label === selectedColor
          ),
        },
      },
    });
  }, [configuration, setConfiguration]);

  const handleBorderPlace = useCallback((type) => (place) => {
    if (place !== 'all') {
      setConfiguration({
        [type]: {
          ...configuration[type],
          borders: {
            ...configuration[type].borders,
            [place]: !configuration[type].borders[place],
          },
        },
      });
    } else {
      if (
        configuration[type].borders.top === true &&
        configuration[type].borders.bottom === true &&
        configuration[type].borders.left === true &&
        configuration[type].borders.right === true
      ) {
        setConfiguration({
          [type]: {
            ...configuration[type],
            borders: {
              ...configuration[type].borders,
              top: false,
              bottom: false,
              left: false,
              right: false,
            },
          },
        });
      } else {
        setConfiguration({
          [type]: {
            ...configuration[type],
            borders: {
              ...configuration[type].borders,
              top: true,
              bottom: true,
              left: true,
              right: true,
            },
          },
        });
      }
    }
  }, [configuration, setConfiguration]);

  const changeHandler = useCallback((type) => ({
    color: handleColor(type),
    fontStyle: handleFontStyle(type),
    fontWeight: handleFontWeight(type),
    textDecoration: handleTextDecoration(type),
    fontSize: handleFontSize(type),
    backgroundColor: handleBackgroundColor(type),
    borderColor: handleBorderColor(type),
    borderPlace: handleBorderPlace(type),
  }), [
    handleBackgroundColor,
    handleBorderColor,
    handleBorderPlace,
    handleColor,
    handleFontSize,
    handleFontStyle,
    handleFontWeight,
    handleTextDecoration
  ])

  const tabsItems = useMemo(() => {
    return [
      {
        label: t('checkboxs_variable'),
        key: 'checkboxs_variable',
        children: <TabsCheckbox changeHandler={changeHandler(ItemTypes.CHECKBOXS_VARIABLE)} />,
      },
      {
        label: t('text_variable'),
        key: 'text_variable',
        children: <TabsText changeHandler={changeHandler(ItemTypes.TEXT_VARIABLE)} />,
      },
      {
        label: "Choix",
        key: "selector_variable",
        children: <SelectorTab changeHandler={changeHandler(ItemTypes.SELECTOR_VARIABLE)} />
      }
    ]
  }, [t, changeHandler])

  return (
    <>
      <Tabs
        items={tabsItems}
        destroyInactiveTabPane
      />
    </>
  );
};
