import produce from 'immer';
import { mutateDeleteSection, mutateInjectSection } from '../utils/sections';
import { fillEmptyAdminTablesBoxes } from '../utils/table';

export const sectionsActions = (set, get) => ({
  injectSection: async ({ id, boxId, linked_section = false }) => {
    const section = await get().getTemplate(id);
    if (!section) return;
    const currentDocument = get().currentDocument;
    const sectionContent =
      currentDocument.type === 'template'
        ? section.content
        : {
            ...section.content,
            boxes: await fillEmptyAdminTablesBoxes({
              boxes: section.content.boxes,
              proposable_id: currentDocument.id,
              type: currentDocument.type,
            }),
          };
    set(
      produce((draft) => {
        mutateInjectSection({
          draft,
          sectionContent,
          boxId,
          sectionId: section.id,
          linked_section
        });
      }),
      false,
      `Inject section ${id}`
    );
  },
  handleSectionFromSectionVariable: async ({
    boxId,
    optionId,
    sectionId,
    action,
  }) => {
    const section = await get().getTemplate(sectionId);
    if (!section) return;
    const currentDocument = get().currentDocument;
    const sectionContent =
      currentDocument.type === 'template'
        ? section.content
        : {
            ...section.content,
            boxes: await fillEmptyAdminTablesBoxes({
              boxes: section.content.boxes,
              proposable_id: currentDocument.id,
              type: currentDocument.type,
            }),
          };
    set(
      produce((draft) => {
        const box = draft.boxes.find((b) => b.id === boxId);
        box.answer = optionId;
        if (action === 'inject')
          mutateInjectSection({
            draft,
            sectionContent,
            boxId,
            sectionId: section.id,
          });
        else {
          mutateDeleteSection({ draft, boxId, optionId });
        }
      }),
      false,
      `${
        action === 'inject' ? 'Inject' : 'Remove'
      } section from section variable ${boxId}`
    );
  },
});
