import React, { useMemo } from 'react'
import { ItemTypes } from '../../../constants/constants';
import TriggerValueSelect from './TriggerValueSelect';
import TriggerValueInput from './TriggerValueInput';

const TriggerValueWrapper = ({ box, value, record, variablesBoxes, triggerType }) => {
  const trigger = useMemo(() => variablesBoxes.find(({ id }) => id === record.triggerId), [record.triggerId, variablesBoxes])
  if (!trigger) return null
  return (
    <>
      {(
        triggerType === ItemTypes.CHECKBOXS_VARIABLE || triggerType === ItemTypes.SELECTOR_VARIABLE ||
        triggerType === ItemTypes.QUESTION || triggerType === ItemTypes.SECTION_VARIABLE
      ) && <TriggerValueSelect box={box} value={value} record={record} trigger={trigger} />}
      {(triggerType === ItemTypes.CUSTOM_TEXT_VARIABLE || triggerType === 'zone-name') && <TriggerValueInput box={box} value={value} record={record} />}
    </>
  )
}

export default TriggerValueWrapper
