import { TitlesTypes, ItemTypes } from '../constants/constants';
import _ from 'lodash';
import { isATitleBox } from './boxes';
import { calculateWidth } from '../constants/gridConfig';

// CONSTANTS
// =============================================================================
export const headerIndentation = {
  [ItemTypes.TITLE]: 1,
  [ItemTypes.TITLE_2]: 2,
  [ItemTypes.TITLE_3]: 3,
  [ItemTypes.TITLE_4]: 4,
};
export const letterMaj = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
export const letterMin = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];
export const letterBlank = [''];
export const romanNumber = [
  '',
  'C',
  'CC',
  'CCC',
  'CD',
  'D',
  'DC',
  'DCC',
  'DCCC',
  'CM',
  '',
  'X',
  'XX',
  'XXX',
  'XL',
  'L',
  'LX',
  'LXX',
  'LXXX',
  'XC',
  '',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
];
// =============================================================================

// FUNCTIONS
// =============================================================================

export const romanize = (num) => {
  if (isNaN(num)) return NaN;
  var digits = String(+num).split(''),
    roman = '',
    i = 3;
  while (i--) roman = (romanNumber[+digits.pop() + i * 10] || '') + roman;
  return Array(+digits.join('') + 1).join('M') + roman;
};

export const getTitlesConfig = (config) => {
  const titlesConfig = _.pick(config, Object.values(TitlesTypes));
  const titlesDisplayTypes = Object.values(TitlesTypes).map((t) => {
    return titlesConfig[t].typeTitle;
  });
  return titlesDisplayTypes;
};

// for data sync when indexTitle is still string like "1.2.1"
export const createIndexArray = (indexTitleStr) => {
  return indexTitleStr.match(/\d+/g);
};

export const displayTitleIndex = (
  indexTitleArray,
  separator,
  titlesTypeConfig,
  hideTitleIndex
) => {
  const indexesToDisplay = transcriptTitle(
    indexTitleArray,
    titlesTypeConfig,
    hideTitleIndex
  ).filter((_i, level) => !(hideTitleIndex && level === 0));
  if (indexesToDisplay.length > 0) {
    return indexesToDisplay.join(separator) + separator;
  } else {
    return '';
  }
};

export const transcriptTitle = (indexCounter, titlesTypeConfig) => {
  return indexCounter.map((counter, index) => {
    const titleTypeConfig = titlesTypeConfig[index];
    if (titleTypeConfig === 'letterMin') {
      return letterMin[counter - 1];
    } else if (titleTypeConfig === 'letterMaj') {
      return letterMaj[counter - 1];
    } else if (titleTypeConfig === 'romain') {
      return romanize(counter);
    } else if (titleTypeConfig === 'letterBlank') {
      return letterMaj[0];
    } else {
      return counter;
    }
  });
};

const getTitleLevelIndex = (boxType) => {
  // starts at 0 for "TITLE" ("title1")
  return Number(boxType[boxType.length - 1]) - 1;
};

export const mutateTitlesIndexes = (draft, maskId = null) => {
  // titlesTypeConfig is an array of each level type
  // ex: ['roman', '', 'letterMaj', 'letterMin']
  let indexCounter = [0, 0, 0, 0];
  let typeTitle = ['title1', 'title2', 'title3', 'title4'];
  const mask = draft.masks.find((m) => m.id === maskId);
  let boxesToUpdate = maskId === null ? draft.boxes : mask.boxes;
  // return produce(boxes, (draftBoxes) => {
  boxesToUpdate = boxesToUpdate.map((box, index, boxes) => {
    if (!isATitleBox(box.type)) {
      return box;
    }
    const boxLevel = getTitleLevelIndex(box.type);
    if (boxLevel + 1 < indexCounter.length) {
      const indexesToReset = _.range(boxLevel + 1, indexCounter.length);
      indexesToReset.forEach((indexValue) => {
        indexCounter[indexValue] = 0;
      });
    }
    indexCounter[boxLevel] += 1;
    const indexTitle = indexCounter.slice(0, boxLevel + 1);
    return {
      ...box,
      type: typeTitle[boxLevel],
      content: {
        ...box.content,
        indexTitle,
      },
    };
  });
  if (maskId === null) {
    draft.boxes = boxesToUpdate;
  } else {
    mask.boxes = boxesToUpdate;
  }
};

// export const resizeSummary = (nextBoxes, summaryConfig) => {
//   let totalHeight = 0;
//   nextBoxes.forEach((box) => {
//     if (
//       // If it's a title (type included in ["title1", "title2"...])
//       Object.values(TitlesTypes).includes(box.type) &&
//       // And it's visible
//       summaryConfig[box.type].visibility === 'visible'
//     ) {
//       totalHeight += box.height;
//     }
//   });

//   return nextBoxes.map((box) => {
//     if (box.type === ItemTypes.SUMMARY) {
//       return {
//         // same as previous box
//         ...box,
//         // BUT the height is new
//         height: totalHeight,
//       };
//     } else {
//       return box;
//     }
//   });
// };

export const mutateResizeSummary = (draft, maskId = null) => {
  const mask = draft.masks.find((m) => m.id === maskId);
  const boxesToUpdate = maskId === null ? draft.boxes : mask.boxes;
  const summaryConfig = draft.summaryConfiguration;
  let totalHeight = 0;
  boxesToUpdate.forEach((box) => {
    if (
      // If it's a title (type included in ["title1", "title2"...])
      Object.values(TitlesTypes).includes(box.type) &&
      // And it's visible
      summaryConfig[box.type].visibility === 'visible'
    ) {
      totalHeight += box.height;
    }
  });
  // ICI voir si pas de problème avec la fonctionnalité d'affichage
  // de certaines lignes seulement dans certains sommaires
  boxesToUpdate
    .filter((box) => box.type === ItemTypes.SUMMARY)
    .map((b) => {
      return {
        // same as previous box
        ...b,
        // BUT the height is new
        height: totalHeight,
      };
    });
};

export const indentTitles = ({ box, columns, margins, indentationTitles }) => {
  const column = columns.find((c) => c.id === box.columnId);

  if (!column || column.drawMode || !isATitleBox(box.type)) return box;

  return {
    ...box,
    width: calculateWidth(
      margins,
      indentationTitles,
      box.content.indexTitle.length - 1
    ),
  };
};
