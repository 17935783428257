import React, { useCallback, } from 'react'
import { ItemTypes } from '../../../constants/constants'
import { Form } from 'antd'
import { useStore } from '../../../store/store'
import { convertToRaw, } from 'draft-js'
import { variableStyle } from '../../../utils/styles'
import FormCheckbox from './FormCheckbox'
import FormTable from './FormTable'
import FormImage from './FormImage'
import FormText from './FormText'
import FormSelector from './FormSelector'
import FormSection from './FormSection'
import FormCustomText from './FormCustomText'
import FormGroup from './FormGroup'
import FormPdf from './FormPdf'
import { useWindowDimensions } from '../../../hooks'
import FormViewer from '../FormViewer/FormViewer'

const renderSpecificBoxEditor = (box, { form, onBlur, validateFormAndRetrieveValues, formPart, variablesGroupedByContainer, setCreateItemType, variablesGroupedByContainerSelected }) => {
  switch (box.type) {
    case ItemTypes.FILE_PDF_VARIABLE:
      return <FormPdf box={box} onBlur={onBlur} form={form} formPart={formPart} variablesGroupedByContainer={variablesGroupedByContainer} />;
    case ItemTypes.TABLE_VARIABLE:
      return <FormTable box={box} onBlur={onBlur} form={form} formPart={formPart} variablesGroupedByContainer={variablesGroupedByContainer} />;
    case ItemTypes.IMG_VARIABLE:
      return <FormImage box={box} onBlur={onBlur} form={form} formPart={formPart} />;
    case ItemTypes.CHECKBOXS_VARIABLE:
    case ItemTypes.QUESTION:
      return <FormCheckbox box={box} onBlur={onBlur} form={form} formPart={formPart} variablesGroupedByContainer={variablesGroupedByContainer} />;
    case ItemTypes.TEXT_VARIABLE:
      return <FormText box={box} onBlur={onBlur} form={form} formPart={formPart} variablesGroupedByContainer={variablesGroupedByContainer} />;
    case ItemTypes.SELECTOR_VARIABLE:
      return <FormSelector box={box} onBlur={onBlur} form={form} formPart={formPart} variablesGroupedByContainer={variablesGroupedByContainer} />;
    case ItemTypes.SECTION_VARIABLE:
      return <FormSection box={box} onBlur={onBlur} form={form} formPart={formPart} variablesGroupedByContainer={variablesGroupedByContainer} />;
    case ItemTypes.CUSTOM_TEXT_VARIABLE:
      return <FormCustomText box={box} form={form} validateFormAndRetrieveValues={validateFormAndRetrieveValues} formPart={formPart} setCreateItemType={setCreateItemType} variablesGroupedByContainer={variablesGroupedByContainer} variablesGroupedByContainerSelected={variablesGroupedByContainerSelected} />
    case ItemTypes.VARIABLE_GROUP:
      return <FormGroup box={box} validateFormAndRetrieveValues={validateFormAndRetrieveValues} form={form} variablesGroupedByContainer={variablesGroupedByContainer} formPart={formPart} setCreateItemType={setCreateItemType} />

    default:
      return null;
  }
}


const FormBoxEditor = ({ variablesGroupedByContainer, setCreateItemType, box, variablesGroupedByContainerSelected, columnToShow }) => {
  const updateBox = useStore(({ updateBox }) => updateBox)
  const boxVariableSelectedId = useStore(({ boxVariableSelectedId }) => boxVariableSelectedId)
  const { width } = useWindowDimensions()

  const [form] = Form.useForm();

  const onFinish = useCallback((values, callback) => {
    updateBox(boxVariableSelectedId, (b) => {
      if (typeof callback === "function") callback(b)
      Object.keys(values).forEach((key) => {
        if (Array.isArray(values[key])) {
          values[key].forEach((value, index) => {
            b[key][index] = {
              ...b[key][index],
              ...value
            }
          })
        }
        else {
          if (key === 'remark') {
            b[key] = JSON.stringify(convertToRaw(values[key].getCurrentContent()))
          } else {
            b[key] = values[key]
          }
        }
      })
    })
  }, [boxVariableSelectedId, updateBox])

  const validateFormAndRetrieveValues = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue()
      return values
    } catch (errors) {
      throw errors
    }
  }, [form])

  const onBlur = useCallback(async (callback) => {
    try {
      const values = await validateFormAndRetrieveValues()
      onFinish(values, callback)
    } catch (errors) {
    }
  }, [validateFormAndRetrieveValues, onFinish])

  return (
    <Form
      style={{
        display: 'grid',
        gridTemplateColumns: '45% 55%',
        overflow: 'hidden'
      }}
      form={form}
      colon={false}
      // To override antd form style
      className='variable-editor__form'
      layout='horizontal'
      validateMessages={{ required: "Champ obligatoire." }}
    >
      {!!box ?
        renderSpecificBoxEditor(box, {
          form,
          onBlur,
          validateFormAndRetrieveValues,
          variablesGroupedByContainer,
          formPart: 'left',
          setCreateItemType,
          variablesGroupedByContainerSelected
        }) :
        <div style={variableStyle["middle"]} />
      }
      {!!box && (width >= 1860 || columnToShow === "triggers") && renderSpecificBoxEditor(box, {
        form,
        onBlur,
        validateFormAndRetrieveValues,
        variablesGroupedByContainer,
        formPart: 'right',
        setCreateItemType
      })}
      {!box && width >= 1860 && <div style={variableStyle["middle"]} />}
      {!box && (width < 1860 && columnToShow !== 'preview') && <div style={variableStyle["right"]} />}
      {(width < 1860 && columnToShow === 'preview') && <div style={variableStyle["right"]}>
        <FormViewer variablesGroupedByContainer={variablesGroupedByContainer} factor={70 * 0.55} />
      </div>}
    </Form>
  )
}

export default FormBoxEditor
