// Axios
import { instance } from './axios.instance';

export const createVariableGroup = async ({ name, remark, disposition }) => {
  const { data } = await instance.post('/variable_group/create', {
    variable_group: { name, remark, disposition },
  });
  return data;
};

export const updateVariableGroup = async ({
  name,
  remark,
  variableIds,
  id,
  disposition,
}) => {
  const { data } = await instance.post('/variable_group/update', {
    id,
    variable_group: { name, remark, variable_ids: variableIds, disposition },
  });
  return data;
};
