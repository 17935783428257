import React, { useEffect, useState } from 'react';
import { useStore } from './store/store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfigProvider } from 'antd';
import { MASKS_TYPE, PRIMARY_COLOR, applyFontsOnThePage } from './constants/constants';
import { formatResContent } from './utils/dataSync';
import ContainerWrapper from './components/Container/ContainerWrapper';
import Container from './components/Container/Container';
import { pageHeight, pageWidth } from './constants/gridConfig';

const selector = (state) => ({
  setStore: state.setStore,
  containers: state.containers,
  defaultVariablesApi: state.defaultVariablesApi,
  masks: state.masks,
  landscape: state.landscape,
  fromPdf: state.fromPdf,
  fontFamilyList: state.configuration.fontFamilyList,
});

const PdfApp = () => {
  const { setStore, containers, defaultVariablesApi, masks, landscape, fromPdf, fontFamilyList } =
    useStore(selector);
  const [containersIdxToShow, setContainersIdxToShow] = useState([])

  useEffect(() => {
    if (fontFamilyList.length > 0) {
      applyFontsOnThePage(fontFamilyList)
    }
  }, [fontFamilyList]);

  useEffect(() => {
    if (fromPdf) {
      return;
    }

    const interval = setInterval(() => {
      const reactDataElement = document.getElementById('editor-store-data');
      if (reactDataElement && reactDataElement.textContent) {
        const value = JSON.parse(reactDataElement.textContent);
        const rest = value.isTemplate ? {
          currentDocument: { type: 'template' },
          user: { isAdmin: true }
        } : {}
        setStore({
          ...formatResContent(
            value,
            defaultVariablesApi,
          ),
          ...rest
        });
      }
    }, 100);

    return () => clearInterval(interval);
  }, [fromPdf, setStore, defaultVariablesApi]);

  useEffect(() => {
    if (!fromPdf) {
      return;
    }

    const interval = setInterval(() => {
      const reactDataElement = document.getElementById('containers-idx-to-show');
      if (reactDataElement && reactDataElement.textContent) {
        setContainersIdxToShow((oldContainersIdxToShow) => {
          const newContainersIdxToShow = JSON.parse(reactDataElement.textContent)
          return newContainersIdxToShow
        });
      }
    }, 10);

    return () => clearInterval(interval);
  }, [fromPdf]);

  const renderHeader = () => {
    const header = masks.find((mask) => mask?.id === MASKS_TYPE.HEADER.id);
    return (
      <div id='header-mask-pdf' style={{ display: 'none' }}>
        {header && (
          <Container id={header.id} key={header.id} />
        )}
      </div>
    );
  };

  const renderFooter = () => {
    const footer = masks.find((mask) => mask?.id === MASKS_TYPE.FOOTER.id);
    return (
      <div id='footer-mask-pdf' style={{ display: 'none' }}>
        {footer && (
          <Container id={footer.id} key={footer.id} />
        )}
      </div>
    );
  };

  if (!fromPdf) return null;

  return (
    <div
      style={{
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <DndProvider backend={HTML5Backend}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: PRIMARY_COLOR,
            },
          }}
        >
          {renderHeader()}
          {renderFooter()}
          <div id='pdf-app'>
            {containers?.map(({ id, height }, idx) => (
              containersIdxToShow.includes(idx) ? <React.Fragment key={id}>
                <div
                  id={`container-wrapper-${id}`}
                  // style={{ backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}` }}
                  className={`pdf-page ${height === (landscape ? pageWidth : pageHeight)
                    ? 'pdf-page-full-height'
                    : 'pdf-page-header-footer'
                    }`}
                >
                  <ContainerWrapper containerId={id} windowItemsIndex={idx + 1} type='container' />
                </div>
                <div id={`container-pdf-${id}`} />
              </React.Fragment> : <div id={`container-pdf-hidden-${id}`} />
            ))}
          </div>
        </ConfigProvider>
      </DndProvider>
    </div>
  );
};

export default PdfApp
