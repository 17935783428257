// Axios
import { instance } from './axios.instance';

export const getUser = async () => {
  const res = await instance.get('/user', {
    headers: { Authorization: `Bearer ${window.localStorage.getItem('jwt')}` },
  });
  if (res.status === 200) {
    return res;
  }
  return null;
};
