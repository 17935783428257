import { instance } from './axios.instance';

export const getProposalSections = async (proposalId) => {
  const { data } = await instance.get(`/proposal/${proposalId}/sections`);
  return data;
};

export const getTemplateSections = async (teamplateId) => {
  const { data } = await instance.get(
    `/proposals/templates/${teamplateId}/sections`
  );
  return data;
};
