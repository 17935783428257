import { EditorState } from 'draft-js';
import { produce } from 'immer';
import { setDefaultInlineStyleIfNeeded } from '../components/RichEditor';
import {
  isATextBox,
  isATitleBox,
  reinitializeBoxWidthAndHeight,
} from '../utils/boxes';
import { indentTitles, mutateResizeSummary } from '../utils/titles';
import { computeColumnWidth } from '../utils/columns';
import { gridUnit } from '../constants/gridConfig';
import { MASKS_TYPE } from '../constants/constants';
import { decorator } from '../components/RichEditor/utils';

export const configurationActions = (set, get) => ({
  updateColor: (colorIndex, newValue) => {
    set(
      produce((state) => {
        state.configuration.colors[colorIndex].style = newValue;
      }),
      false,
      `update configuration color number ${colorIndex + 1}`
    );
  },
  updateHighlightColors: (colorIndex, newValue) => {
    set(
      produce((state) => {
        state.configuration.highlightColors[colorIndex].style = newValue;
      }),
      false,
      `update configuration highlightColor number ${colorIndex + 1}`
    );
  },
  setConfiguration: (values, callback) => {
    set(
      produce((state) => {
        if (values.columnGap && values.columnGap !== state.configuration.columnGap) {

          state.boxes = state.boxes.map((box) => {
            const column = state.columns.find((c) => c.id === box.columnId);

            if (!column || column.drawMode) return box;
            const container = state.containers.find((c) =>
              c.columnsIds.includes(column.id)
            );
            if(container.columnsIds.length === 1) return box;
            return reinitializeBoxWidthAndHeight({
              header: state.masks.find(({ id }) => id === MASKS_TYPE.HEADER.id),
              footer: state.masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id),
              box,
              columnWidth: computeColumnWidth({
                landscape: state.landscape,
                marginLeft: state.configuration.margins.left * gridUnit,
                marginRight: state.configuration.margins.right * gridUnit,
                containerColumnsLength: container.columnsIds.length,
                size: column.size,
                columnGap: values.columnGap,
              }),
            });
          });
        }

        if (
          values.margins &&
          (values.margins.left !== state.configuration.margins.left ||
            values.margins.right !== state.configuration.margins.right)
        ) {
          state.boxes = state.boxes.map((box) => {
            const column = state.columns.find((c) => c.id === box.columnId);

            if ((!column && !box.onlyForm) || column?.drawMode) return box;
            const containerColumnsLength = box.onlyForm
              ? 1
              : state.containers.find((c) => c.columnsIds.includes(column.id))
                  .columnsIds.length;
            const size = box.onlyForm ? 1 : column.size;
            return reinitializeBoxWidthAndHeight({
              header: state.masks.find(({ id }) => id === MASKS_TYPE.HEADER.id),
              footer: state.masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id),
              box,
              columnWidth: computeColumnWidth({
                landscape: state.landscape,
                marginLeft: values.margins.left * gridUnit,
                marginRight: values.margins.right * gridUnit,
                containerColumnsLength,
                size,
              }),
            });
          });
        }
        if (
          values.margins &&
          values.indentationTitles !== state.indentationTitles
        ) {
          state.boxes = state.boxes.map((box) =>
            indentTitles({
              box,
              columns: state.columns,
              margins: state.configuration.margins,
              indentationTitles: values.indentationTitles,
            })
          );
        }
        state.configuration = { ...state.configuration, ...values };
        if (typeof callback === 'function') callback(state);
      }),
      false,
      `setConfiguration for ${Object.keys(values).join(', ')}`
    );
  },
  setSummaryConfiguration: (values) => {
    set(
      produce((state) => {
        state.summaryConfiguration = {
          ...state.summaryConfiguration,
          ...values,
        };
        mutateResizeSummary(state);
      }),
      false,
      'setSummaryConfiguration'
    );
  },
  setGridConfiguration: (values) => {
    set(
      produce((draft) => {
        draft.gridConfiguration = { ...draft.gridConfiguration, ...values };
      }),
      false,
      'setGridConfiguration'
    );
  },
  changeFontFamilyListInConfig: (newFontFamilyList) => {
    if (newFontFamilyList.length > 0) {
      set(
        produce((draft) => {
          const configKeys = draft.configuration.fontFamilyList.map(
            (f) => f.key
          );
          const newKeys = newFontFamilyList.map((f) => f.key);
          const removedKey = configKeys.find((f) => !newKeys.includes(f));
          if (removedKey) {
            const removedFontFamily = draft.configuration.fontFamilyList.find(
              (font) => font.key === removedKey
            );
            //selection 1st family of the list uppon delete configuration.fontFamily
            if (
              draft.configuration.fontFamily === removedFontFamily.familyName
            ) {
              draft.configuration.fontFamily = newFontFamilyList[0].familyName;
            }
            //modification family title in configuration uppon main family delete
            if (
              draft.configuration.title1.fontFamily ===
              removedFontFamily.familyName
            ) {
              draft.configuration.title1.fontFamily =
                draft.configuration.fontFamily;
            }
            if (
              draft.configuration.title2.fontFamily ===
              removedFontFamily.familyName
            ) {
              draft.configuration.title2.fontFamily =
                draft.configuration.fontFamily;
            }
            if (
              draft.configuration.title3.fontFamily ===
              removedFontFamily.familyName
            ) {
              draft.configuration.title3.fontFamily =
                draft.configuration.fontFamily;
            }
            if (
              draft.configuration.title4.fontFamily ===
              removedFontFamily.familyName
            ) {
              draft.configuration.title4.fontFamily =
                draft.configuration.fontFamily;
            }
            //modification family title in summaryConfiguration uppon main family delete
            if (
              draft.summaryConfiguration.title1.fontFamily ===
              removedFontFamily.familyName
            ) {
              draft.summaryConfiguration.title1.fontFamily =
                draft.summaryConfiguration.fontFamily;
            }
            if (
              draft.summaryConfiguration.title2.fontFamily ===
              removedFontFamily.familyName
            ) {
              draft.summaryConfiguration.title2.fontFamily =
                draft.summaryConfiguration.fontFamily;
            }
            if (
              draft.summaryConfiguration.title3.fontFamily ===
              removedFontFamily.familyName
            ) {
              draft.summaryConfiguration.title3.fontFamily =
                draft.summaryConfiguration.fontFamily;
            }
            if (
              draft.summaryConfiguration.title4.fontFamily ===
              removedFontFamily.familyName
            ) {
              draft.summaryConfiguration.title4.fontFamily =
                draft.summaryConfiguration.fontFamily;
            }
            draft.configuration.checkboxStyles.forEach((cStyle) => {
              if (cStyle.fontFamily === removedFontFamily.familyName) {
                cStyle.fontFamily = draft.configuration.fontFamily;
              }
            });
            draft.configuration.textStyles.forEach((tStyle) => {
              if (tStyle.fontFamily === removedFontFamily.familyName) {
                tStyle.fontFamily = draft.configuration.fontFamily;
              }
            });
            draft.configuration.selectorStyles.forEach((sStyle) => {
              if (sStyle.fontFamily === removedFontFamily.familyName) {
                sStyle.fontFamily = draft.configuration.fontFamily;
              }
            });
            const defaultProperty = newFontFamilyList.find(
              (f) => f.familyName === draft.configuration.fontFamily
            );
            draft.boxes
              .filter((box) => isATextBox(box.type) || isATitleBox(box.type))
              .forEach((box) => {
                const newEditorState = setDefaultInlineStyleIfNeeded(
                  box.content.editorState,
                  removedFontFamily.key,
                  defaultProperty.key,
                  'FONT_FAMILY_'
                );
                box.content.editorState = EditorState.createWithContent(
                  newEditorState.getCurrentContent(),
                  decorator
                );
              });
            if (
              draft.configuration.fontFamily === removedFontFamily.familyName
            ) {
              draft.configuration.fontFamily = defaultProperty;
            }
          }
          draft.configuration.fontFamilyList = newFontFamilyList;
        }),
        false,
        `Change font Family list in Config and SummaryConfig`
      );
    }
  },
});
