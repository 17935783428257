import React, { useState } from 'react'
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';
import { VARIABLE_COLOR } from '../../constants/constants';


const VariableDecorator = (props) => {
  const { updateLeftShownStatus } = useOverlay();
  const [isHovered, setIsHovered] = useState(false)
  return (
    <span
      data-offset-key={props.offsetkey}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={
        {
          backgroundColor: 'transparent',
          ...(isHovered && {
            color: "#fff",
            backgroundColor: `${VARIABLE_COLOR}c7`
          })
        }}
      onDoubleClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        const entity = props.contentState.getEntity(props.entityKey);
        const { key } = entity.getData();
        updateLeftShownStatus(leftShownStatus["VARIABLES"], key)
      }}
    >
      {props.children}
    </span>
  );
};

export default VariableDecorator
