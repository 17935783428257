import React from 'react'
import { useStore } from '../../../store/store';
import { Form, Switch } from 'antd';

export const DocumentTab = () => {
  const setConfiguration = useStore(({ setConfiguration }) => setConfiguration)
  const configuration = useStore(({ configuration }) => configuration)

  return (
    <Form.Item label="Afficher le formulaire par défaut : " valuePropName="checked">
      <Switch checked={configuration.defaultDocumentView === 'form'} onChange={(checked) => {
        setConfiguration({
          ...configuration,
          defaultDocumentView: checked ? 'form' : 'document'
        });
      }} />
    </Form.Item>
  );
};
