import React from 'react';
import Layout from './components/Layout/Layout';
import Providers from './components/Providers';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Modal, Spin } from 'antd';
import { useStore } from './store/store';

const queryClient = new QueryClient();

const selector = (state) => ({
  loaderPdfToImage: state.loaderPdfToImage
})

const ProposalApp = () => {
  const { loaderPdfToImage } = useStore(selector);

  return (
    <QueryClientProvider client={queryClient}>
      <Modal
        visible={loaderPdfToImage}
        footer={null}
        closable={false}
        centered
      >
        <Spin spinning={loaderPdfToImage} size="large">
          <div style={{ height: '100px' }}>Traitement du PDF en cours... </div>
        </Spin>
      </Modal>
      <Providers />
      <DndProvider backend={HTML5Backend}>
        <Layout />
      </DndProvider>
    </QueryClientProvider>
  );
}

export default ProposalApp;
