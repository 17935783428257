import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { tableHeaderSecondaryBgColor } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useStore } from '../../../store/store';

const TriggerArrayFooter = ({ box, setIsFooterHovered, isFooterHovered }) => {
  const { t } = useTranslation()
  const addTrigger = useStore(({ addTrigger }) => addTrigger)

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: isFooterHovered ? "#f0f2f5" : "white",
      cursor: 'pointer',
      padding: 8
    }}
      onMouseEnter={() => setIsFooterHovered(true)}
      onMouseLeave={() => setIsFooterHovered(false)}
      onClick={() => addTrigger(box)}
    >
      < FontAwesomeIcon icon={icon({ name: 'circle-plus', style: 'duotone' })}
        style={{
          margin: 0,
          marginRight: 8,
          fontSize: 14,
          "--fa-primary-color": "white",
          "--fa-secondary-color": tableHeaderSecondaryBgColor,
          "--fa-secondary-opacity": 1
        }} />
      {t('add-trigger')}
    </div>
  )
}

export default TriggerArrayFooter
