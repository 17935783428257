import React, { useMemo, useState } from 'react'
import { useStore } from '../../../store/store';
import { Select } from 'antd';
import { Editor } from 'draft-js';
import { ItemTypes } from '../../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons';
import FormBoxCreateModal from './FormBoxCreateModal';
import PopoverTriggerBox from './PopoverTriggerBox';
const { Option } = Select

const TriggerNameSelect = ({ box, triggerId, record, variablesBoxes }) => {
  const updateTrigger = useStore(({ updateTrigger }) => updateTrigger)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newBoxType, setNewBoxType] = useState()
  const selectableTriggers = useMemo(() => {
    const baseTriggers = variablesBoxes.filter((vB) => vB.type === record.triggerType && vB.id !== box.id)
    if (record.triggerType === ItemTypes.QUESTION) {
      return [{ name: 'Créer une question', id: 'create-question' }, ...baseTriggers]
    }
    if (record.triggerType === ItemTypes.CUSTOM_TEXT_VARIABLE) {
      return [{ name: 'Créer une variable texte', id: 'create-variable' }, ...baseTriggers]
    }
    return baseTriggers
  }, [box.id, record.triggerType, variablesBoxes])

  return (
    <div style={{
      display: 'flex',
      width: "100%"
    }}>
      <FormBoxCreateModal record={record} isModalOpen={isModalOpen} newBoxType={newBoxType} setNewBoxType={setNewBoxType} setIsModalOpen={setIsModalOpen} parentId={box.id} />
      <Select
        style={{
          width: "100%",
          padding: 0,
        }}
        disabled={record.from_linked_section}
        popupClassName="trigger-select-name"
        bordered={false}
        showSearch={false}
        optionLabelProp="label"
        value={triggerId}
        showArrow={!triggerId}
        onSelect={(triggerId) => {
          if (triggerId === 'create-question' || triggerId === 'create-variable') {
            setIsModalOpen(true);
            setNewBoxType(triggerId === 'create-question' ? ItemTypes.QUESTION : ItemTypes.CUSTOM_TEXT_VARIABLE)
          }
          else {
            const selectedTrigger = selectableTriggers.find(({ id }) => id === triggerId)
            const triggerIdFromSection = selectedTrigger.from_linked_section ? selectedTrigger.boxSectionId : undefined
            updateTrigger(record.id, box.id, { triggerId, triggerIdFromSection })
          }
        }}
      >
        {selectableTriggers
          .map(({ name, id }) =>
            <Option key={id} value={id} label={<div style={{
              display: 'flex',
              gap: 4,
              height: '100%'
            }}>
              {typeof name === "string" ? <div style={{
                overflow: "hidden"
              }}>{name}</div> : <Editor
                readOnly
                editorState={name} />}
              <PopoverTriggerBox boxId={id} />
            </div>}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                fontWeight: id === 'create-question' || id === 'create-variable' ? 'bold' : 'normal',
              }}>
                {(id === 'create-question' || id === 'create-variable') && <FontAwesomeIcon
                  icon={faPenToSquare}
                  style={{ marginRight: 4 }}
                />}
                {typeof name === "string" ? <div style={{
                  overflow: "hidden"
                }}>{name}</div> : <Editor
                  readOnly
                  editorState={name} />}
                {id !== 'create-question' && id !== 'create-variable' && <PopoverTriggerBox boxId={id} />}
              </div>
            </Option>
          )
        }
      </Select>
    </div>
  )
}

export default TriggerNameSelect
