import create from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  getDocumentFromParams,
  getJwt,
  getUrlParamsObject,
} from '../utils/browser';
import {
  defaultConfiguration,
  defaultItemValues,
  defaultMasks,
  defaultSlider,
} from '../constants/constants';
import { apiActions } from './api';
import { boxesActions } from './boxes';
import { configurationActions } from './configuration';
import { masksActions } from './masks';
import { pdfActions } from './pdf';
import { styleActions } from './style';
import { titlesActions } from './titles';
import { browserActions } from './browser';
import { containerActions } from './containers';
import { columnsActions } from './columns';
import { sectionsActions } from './sections';
import { variablesActions } from './variables';
import { variableGroupsActions } from './variableGroups';
import { variableOrderArrayActions } from './variableOrderArray';
import { triggersActions } from './triggers';
import produce from 'immer';

const currentDocument = getDocumentFromParams(getUrlParamsObject());
const currentProposal =
  currentDocument.type === 'template'
    ? { id: null }
    : { id: getUrlParamsObject().pid ?? null };

const defaultStoreValues = {
  loadingData: false,
  currentDocument,
  currentTemplate: null,
  currentProposal,
  landscape: false,
  PDFRenderState: false,
  masks: defaultMasks,
  selectedBoxId: null,
  selectedContainer: null,
  groupSelection: [],
  tables: [],
  templates: [],
  sections: [],
  proposals: [],
  proposalList: true,
  user: getJwt(),
  // pages: [newPage()],
  configuration: defaultConfiguration,
  summaryConfiguration: defaultItemValues.summary.content.configuration,
  copy: null,
  gridConfiguration: defaultItemValues,
  boxes: [],
  variables: {},
  defaultVariablesApi: null,
  displayMargins: false,
  boxIsMovable: false,
  slider: defaultSlider,
  maskEditing: [],
  containers: [],
  columns: [],
  eventManagerEnabled: true,
  variableGroups: [],
  boxVariableSelectedId: null,
  loaderPdfToImage: false,
  tempContainerPdfToImage: null,
  variableOrderArray: [],
  triggers: {},
  sectionsToActivate: [],
  hasOpportunityOption: false,
  hasCostingActivated: false,
  zoneNames: [],
  zoneIcon: 'fa-kit fa-solid-bars-progress-gear',
  itemsChangedByTriggers: [],
};

const store = (set, get) => ({
  ...defaultStoreValues,
  ...apiActions(set, get),
  ...boxesActions(set, get),
  ...configurationActions(set, get),
  ...masksActions(set, get),
  // ...pagesActions(set, get),
  ...pdfActions(set, get),
  ...styleActions(set, get),
  ...titlesActions(set, get),
  ...browserActions(set, get),
  ...sectionsActions(set, get),
  ...containerActions(set, get),
  ...columnsActions(set, get),
  ...variablesActions(set, get),
  ...variableGroupsActions(set, get),
  ...variableOrderArrayActions(set, get),
  ...triggersActions(set, get),
});

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

const actionMiddleware = (config) => (set, get, api) => {
  const result = config(
    async (partial, replace, ...args) => {
      // Exécuter la mise à jour de l'état
      set(partial, replace, ...args);

      // Après la mise à jour de l'état
      if (get().sectionsToActivate.length > 0 && !get().areSectionsActivating) {
        set(
          produce((draft) => {
            draft.areSectionsActivating = true;
          })
        );
        while (get().sectionsToActivate.length > 0) {
          await get().handleSectionsTriggersActivation();
        }
        set(
          produce((draft) => {
            draft.areSectionsActivating = false;
          })
        );
      }
    },
    get,
    api
  );

  return result;
};

export const useStore = create(devtools(actionMiddleware(store)));
