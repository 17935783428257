import React from 'react'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from 'antd'
import './InputHideable.less'

const InputHideable = ({
  value,
  setValue
}) => {
  const onChangeInput = (e) => {
    setValue(e.target.value)
  }

  return (
    <div style={{
      minHeight: 20.85,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: "100%"
    }}
    >
      <Input
        value={value}
        className={`input-hideable`}
        suffix={
          <div style={{
            width: 16,
            height: 16
          }} />
        }
        allowClear
        onChange={onChangeInput}
        style={{
          paddingBottom: 0,
          paddingTop: 0,
          fontSize: 12,
        }}
      />
      <div style={{
        alignSelf: 'center',
        position: 'absolute',
        right: 10,
        color: 'black',
        transition: 'color 300ms ease-in-out, right 300ms ease-in-out',
        zIndex: 9999,
        padding: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }}
      >
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
        />
      </div>
    </div>
  )
}

export default InputHideable
