import React, { useCallback, useMemo, useRef, useState } from 'react'
import './ImageDecorator.less'
import Image from '../Image';
import { Resizable } from 're-resizable';
import { MASKS_TYPE, defaultImgAspectRatio, roundButtonWidth } from '../../constants/constants';
import { getRatioMove, reinitializeBoxWidthAndHeight, rotatedBoxDelta, } from '../../utils/boxes';
import { useEditor } from '../../contexts/EditorContext';
import ReactDOM from 'react-dom';
import FloatyImageMenu from './FloatyImageMenu';
import { useOutsideClickHandler } from '../../utils/browser';
import { useStore } from '../../store/store';

const ImageUpdater = ({ simulatedBox, entityKey, menuAncestorType, menuAncestorIds, editorState }) => {
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))

  const ref = useRef()
  const [width, setWidth] = useState(simulatedBox.width);
  const [isSelected, setIsSelected] = useState(false)

  const lockAspectRatio = useMemo(() => simulatedBox.ratio
    ? simulatedBox.ratio
    : defaultImgAspectRatio, [simulatedBox.ratio])

  useOutsideClickHandler(ref, () => {
    if (isSelected) {
      setIsSelected(false)
    }
  });

  const updateImage = useCallback((newImageBox) => {
    const contentState = editorState.getCurrentContent();
    contentState.replaceEntityData(entityKey, { box: newImageBox, menuAncestorType, menuAncestorIds })
    setIsSelected(false)
  }, [editorState, entityKey, menuAncestorType, menuAncestorIds])

  const handleResize = (args) => {
    const x = args[0].movementX, y = args[0].movementY;
    const resizeMove = rotatedBoxDelta({ rotation: 0, direction: args[1], x, y });
    const ratioMove = getRatioMove(resizeMove, lockAspectRatio);
    const { width: newWidth } = reinitializeBoxWidthAndHeight({
      box: {
        ...simulatedBox,
        width: Math.floor(width + ratioMove.width),
        height: 'auto',
      },
      columnWidth: 180,
      onResize: true,
      header,
      footer
    })
    setWidth(newWidth < roundButtonWidth ? roundButtonWidth : newWidth);
  };

  const handleResizeStop = () => {
    updateImage({ ...simulatedBox, width });
  };

  const getPositions = useCallback(() => {
    if (!ref.current) return { top: null, left: null }
    return ref.current.getBoundingClientRect()
  }, [])

  return (
    <>
      <div
        onClick={(e) => {
          setIsSelected(true)
          e.stopPropagation()
        }}
        ref={ref}
      >
        <Resizable
          enable={{
            right: true,
          }}
          lockAspectRatio={lockAspectRatio}
          size={{ width, height: 'auto' }}
          onResize={(...args) => { handleResize(args) }}
          onResizeStop={handleResizeStop}
          handleStyles={{
            right: { zIndex: 2000 },
          }}
          style={{
            display: 'inline-block'
          }}
          maxWidth={180}
          minWidth={roundButtonWidth}
        >
          <Image box={{ ...simulatedBox, width }} updateImage={updateImage} />
        </Resizable>
      </div>
      {
        isSelected &&
        ReactDOM.createPortal(
          <FloatyImageMenu
            positions={getPositions()}
            simulatedBox={simulatedBox}
            updateImage={updateImage}
          />,
          document.getElementById('MenuBarPortal')
        )
      }
    </>
  );
}

const ImageDecorator = (props) => {
  const { setEditorState, editorState, readOnly } = useEditor();
  const { box: simulatedBox, menuAncestorType, menuAncestorIds } = props.contentState.getEntity(props.entityKey).getData()

  return (
    <span contentEditable={false} className='image-decorator'>
      {setEditorState && !readOnly ?
        <ImageUpdater simulatedBox={simulatedBox}
          entityKey={props.entityKey}
          menuAncestorType={menuAncestorType}
          menuAncestorIds={menuAncestorIds}
          editorState={editorState}
        /> :
        <Image box={simulatedBox} fromViewer />
      }
    </span>
  );
};

export default ImageDecorator
