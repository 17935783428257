import { produce } from 'immer';

export const styleActions = (set, get) => ({
  loadFont: (font) => {
    const headID = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = 'https://fonts.googleapis.com/css?family=' + font;
    link.crossOrigin = 'anonymous';

    headID.appendChild(link);

    // const pages = document.getElementsByClassName('page');
    // [...pages].forEach(
    //   (page) => (page.style.fontFamily = `${font}, sans-serif`)
    // );
    set(
      null,
      false,
      'loadFont'
    );
  },
});
