import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Divider, message, Popconfirm, Switch, Tooltip } from 'antd';
// ANT Design
import React, { useCallback, useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { ItemTypes, ItemTypesForTraduction, MASKS_TYPE, VARIABLE_COLOR, variablesIcons, PRIMARY_COLOR, zoomList, dynamicZoom, getPrimaryDark } from '../../constants/constants';
import { useModalOpener } from '../../hooks';
import { useStore } from '../../store/store';
import { emptyUrl, updateUrl } from '../../utils/browser';
import { savePagePreview } from '../../utils/savePagePreview';
import { actionButtonBottomMargin } from '../../utils/styles';
import ConfigurationModal from './configurationModal/ConfigurationModal';
import NewTemplateModal from './NewTemplateModal';
import './RightSide.less';
import TemplateModal from './TemplateModal';
import OverlaySider from '../Layout/OverlaySider';
import { isADefaultBox, isADrawBox, isAOneRowBox, isAVariableBox } from '../../utils/boxes';
import NavbarSelector from '../Layout/NavbarSelector';

const menuItemStyle = {
  textAlign: 'center',
  margin: `${actionButtonBottomMargin}px 0`,
}

const iconFontSize = 25
const secondIconFontSizeReduction = 10
const size = 38
const GenerateSpace = () => {
  return (
    <div
      style={{
        flexBasis: "100%"
      }}
    />
  );
};

const ItemButtonCopied = ({ icon, bgcolor, disp, label, text }) => {
  return (
    <Tooltip
      placement='bottom'
      mouseLeaveDelay='0'
      title={text}
      mouseEnterDelay={0.5}
    >
      <li
        style={{
          display: disp,
          marginBottom: '8px',
          width: size,
          height: size,
          backgroundColor: bgcolor,
          borderStyle: 'none',
          borderRadius: '5px',
          fontSize: '9px',
          fontFamily: 'Montserrat',
          color: '#000000',
          textAlign: 'center',
        }}
      >
        <Button
          style={{
            borderStyle: 'none',
            backgroundColor: bgcolor,
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
          icon={icon}
        >{label}</Button>
      </li></Tooltip>
  );
};

const ItemButton = ({ type, text, icon, config, bgcolor, disp, label, color = '#000000' }) => {
  const { maskEditing, handleUnSelectBox, handleUnSelectContainer } = useStore(selector);
  const isInMask = maskEditing.length > 0
  const isInHeaderFooter = isInMask &&
    (maskEditing.includes(MASKS_TYPE.HEADER.id) || maskEditing.includes(MASKS_TYPE.FOOTER.id))
  const disabled = (isInMask && (isADrawBox(type) || isADefaultBox(type) || isAVariableBox(type))) || (isInHeaderFooter && isAOneRowBox(type))
  const [, drag] = useDrag({
    type: 'newBox',
    item: { type, ...config[type], copied: false },
    canDrag: !disabled
  });

  return (
    <Tooltip
      placement='bottom'
      mouseLeaveDelay='0'
      title={text}
      mouseEnterDelay={0.5}
    >
      <li
        ref={drag}
        onDragStart={() => {
          handleUnSelectBox();
          handleUnSelectContainer();
        }}
        style={{
          display: disp,
          marginLeft: '5px',
          marginBottom: '8px',
          width: size,
          height: size,
          backgroundColor: bgcolor,
          borderStyle: 'none',
          borderRadius: '5px',
          fontSize: '9px',
          fontFamily: 'Montserrat',
          color,
          textAlign: 'center',
        }}
      >
        <Button
          style={{
            borderStyle: 'none',
            backgroundColor: bgcolor,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: '100%',
            color,
          }}
          icon={icon}
          disabled={disabled}
        >{label}</Button>
      </li>
    </Tooltip>
  );
};

const selector = (state) => ({
  getTemplates: state.getTemplates,
  currentDocument: state.currentDocument,
  loadingData: state.loadingData,
  currentProposal: state.currentProposal,
  templates: state.templates,
  postTemplate: state.postTemplate,
  updateTemplate: state.updateTemplate,
  saveProposal: state.saveProposal,
  isAdmin: state?.user?.isAdmin,
  user: state?.user,
  createTemplateInStore: state.createTemplateInStore,
  gridConfiguration: state.gridConfiguration,
  copy: state.copy,
  handleUnSelectBox: state.handleUnSelectBox,
  handleUnSelectContainer: state.handleUnSelectContainer,
  setDisplayMargins: state.setDisplayMargins,
  displayMargins: state.displayMargins,
  PDFRenderState: state.PDFRenderState,
  handleProposalList: state.handleProposalList,
  handleMaskList: state.handleMaskList,
  maskEditing: state.maskEditing,
  fetchDatas: state.fetchDatas,
  setMaskEditing: state.setMaskEditing,
  saveAndGeneratePdf: state.saveAndGeneratePdf,
  landscape: state.landscape,
  setConfiguration: state.setConfiguration,
  configuration: state.configuration
});

const DynamicContainerIcon = ({ containerType }) => {
  if (containerType === "drawMode") {
    return <span className="fa-layers" style={{ width: '21px' }}>
      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: "white" }} />
      <FontAwesomeIcon icon={icon({ name: 'scribble', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, color: "white" }} />
    </span>
  }
  else if (containerType === "mask") {
    return <span className="fa-layers" style={{ width: '21px' }}>
      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: "white" }} />
      <FontAwesomeIcon icon={icon({ name: 'masks-theater', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, color: "white" }} />
    </span>
  }
  else {
    return <span className="fa-layers" style={{ width: '21px' }}>
      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: "white" }} />
      < FontAwesomeIcon icon={variablesIcons[ItemTypes.CONTAINER_COPIED]} style={{ fontSize: iconFontSize - 12, color: 'white' }} />
    </span>
  }
}

const dynamicTextIcon = ({ containerType }) => {
  if (containerType === "drawMode") return "drawing"
  if (containerType === "mask") return ItemTypes.MASK
  return ItemTypes.CONTAINER_COPIED
}
const WhatIsCopied = (type, containerType = null) => {
  const { gridConfiguration } = useStore(selector);
  const { t } = useTranslation();

  switch (type) {
    case 'text': {
      return (
        <ItemButtonCopied
          type={ItemTypes.TEXT}
          text={t('text')}
          icon={< FontAwesomeIcon icon={icon({ name: 'pen-fancy', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'image': {
      return (
        <ItemButtonCopied
          type={ItemTypes.IMG}
          text={t('image')}
          icon={< FontAwesomeIcon icon={icon({ name: 'image', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'shape': {
      return (
        <ItemButtonCopied
          type={ItemTypes.SHAPE}
          text={t('shape')}
          icon={< FontAwesomeIcon icon={icon({ name: 'star', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'line': {
      return (
        <ItemButtonCopied
          type={ItemTypes.LINE}
          text={t('line')}
          icon={< FontAwesomeIcon icon={icon({ name: 'dash', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'table': {
      return (
        <ItemButtonCopied
          type={ItemTypes.TABLE}
          text={t('table')}
          icon={< FontAwesomeIcon icon={icon({ name: 'table', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'title1': {
      return (
        <ItemButtonCopied
          type={ItemTypes.TITLE}
          text={t('title1')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'title2': {
      return (
        <ItemButtonCopied
          type={ItemTypes.TITLE_2}
          text={t('title2')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'title3': {
      return (
        <ItemButtonCopied
          type={ItemTypes.TITLE_3}
          text={t('title3')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'title4': {
      return (
        <ItemButtonCopied
          type={ItemTypes.TITLE_4}
          text={t('title4')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'summary': {
      return (
        <ItemButtonCopied
          type={ItemTypes.SUMMARY}
          text={t('summary')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: 'list-ol', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.LINE_BREAK: {
      return (
        <ItemButtonCopied
          type={ItemTypes.LINE_BREAK}
          text={t(ItemTypes.LINE_BREAK)}
          icon={< FontAwesomeIcon icon={icon({ name: 'turn-down-left', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.CHECKBOXS_VARIABLE: {
      return (
        <ItemButtonCopied
          type={ItemTypes.CHECKBOXS_VARIABLE}
          text={t(ItemTypes.CHECKBOXS_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.CHECKBOXS_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.SELECTOR_VARIABLE: {
      return (
        <ItemButtonCopied
          type={ItemTypes.SELECTOR_VARIABLE}
          text={t(ItemTypes.SELECTOR_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.SELECTOR_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.TEXT_VARIABLE: {
      return (
        <ItemButtonCopied
          type={ItemTypes.TEXT_VARIABLE}
          text={t(ItemTypes.TEXT_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.TEXT_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.IMG_VARIABLE: {
      return (
        <ItemButtonCopied
          type={ItemTypes.IMG_VARIABLE}
          text={t(ItemTypes.IMG_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.IMG_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.TABLE_VARIABLE: {
      return (
        <ItemButtonCopied
          type={ItemTypes.TABLE_VARIABLE}
          text={t(ItemTypes.TABLE_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.TABLE_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.SPACE: {
      return (
        <ItemButtonCopied
          type={ItemTypes.SPACE}
          text={t(ItemTypes.SPACE)}
          icon={<span className="fa-layers" style={{ width: '21px' }}>
            <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />
            <FontAwesomeIcon icon={icon({ name: 'arrow-up-right-and-arrow-down-left-from-center', style: 'regular' })} style={{ fontSize: iconFontSize - 14, transform: 'rotate(135deg)', color: 'white' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.CONTAINER_COPIED: {
      return (
        <ItemButtonCopied
          type={ItemTypes.CONTAINER_COPIED}
          text={t(dynamicTextIcon({ containerType }))}
          icon={DynamicContainerIcon({ containerType })}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    default:
      break;
  }
};

const CopyContent = (copy) => {
  if (copy.type === ItemTypes.CONTAINER_COPIED) {
    return {
      drawMode: copy.drawMode,
      copiedContainer: copy.copiedContainer,
      maskId: copy.maskId,
      boxes: copy.boxes.map((box) =>
      (
        { ...box, copied: true, colSize: copy.columnsDisposition.find((col) => col.id === box.columnId).size }
      )
      )
    };
  } else {
    return {
      ...copy,
      copied: true,
    };
  }
}


const CopyItem = ({ copy }) => {
  const [, drag] = useDrag({
    type: copy?.type === ItemTypes.CONTAINER_COPIED ? ItemTypes.CONTAINER_COPIED : 'newBox',
    item: CopyContent(copy),
  });

  let containerType = null
  if (copy?.drawMode) containerType = 'drawMode'
  if (copy?.maskId) containerType = 'mask'

  return (
    <div
      ref={drag}
      style={{
        width: size,
        height: size,
        marginLeft: '5px',
      }}
    >
      {WhatIsCopied(ItemTypesForTraduction[copy.type], containerType)}
    </div>
  );
};

const RightSide = () => {
  const { t } = useTranslation();
  const {
    currentProposal,
    currentDocument,
    loadingData,
    templates,
    updateTemplate,
    saveProposal,
    isAdmin,
    createTemplateInStore,
    postTemplate,
    displayMargins,
    gridConfiguration,
    copy,
    handleUnSelectBox,
    handleUnSelectContainer,
    setDisplayMargins,
    PDFRenderState,
    handleProposalList,
    fetchDatas,
    saveAndGeneratePdf,
    landscape,
    setConfiguration,
    configuration
  } = useStore(selector);

  const [handleTemplateOpen, setHandleTemplateOpen] = useModalOpener(false);
  const [configurationPanelVisible, setConfigurationPanelVisible] =
    useModalOpener(false);
  const [NewTemplateModalVisible, setNewTemplateModalVisible] = useModalOpener(false);
  const [saveDocumentLoading, setSaveDocumentLoading] = useState(false);

  useEffect(() => {
    if (!currentProposal?.id) {
      if (currentDocument.type === 'template' && !currentDocument.id) {
        setHandleTemplateOpen(true);
      }
    }
  }, [currentProposal, currentDocument, setHandleTemplateOpen])
  const handleUpdateZoom = (value) => {
    if (value < 24 || value > 500) return;
    setConfiguration({
      zoom: value
    })
  }

  const saveDocument = useCallback(async () => {
    message.success('Enregistrement du document en cours');
    setSaveDocumentLoading(true);
    if (isAdmin && currentDocument.type === 'template') {
      await updateTemplate();
      savePagePreview(currentDocument.id, currentDocument.type, landscape);
    } else {
      await saveProposal();
      savePagePreview(currentProposal.id, currentDocument.type, landscape);
    }
    setSaveDocumentLoading(false);
    setNewTemplateModalVisible(false);
    setHandleTemplateOpen(false);
  }, [isAdmin,
    currentDocument?.type,
    currentDocument?.id,
    setNewTemplateModalVisible,
    setHandleTemplateOpen,
    updateTemplate,
    landscape,
    saveProposal,
    currentProposal?.id]);

  const saveNewTemplate = useCallback(
    async ({ name, templatable, templatePattern, landscape, work, documentTypeIds }) => {
      createTemplateInStore();
      message.success('Création du document en cours');
      setSaveDocumentLoading(true);
      if (isAdmin && currentDocument.type === 'template') {
        await postTemplate({
          name,
          templatable,
          templatePattern,
          landscape,
          work,
          documentTypeIds
        });
      }
      setSaveDocumentLoading(false);
      setNewTemplateModalVisible(false);
      setHandleTemplateOpen(false);
    },
    [createTemplateInStore, isAdmin, currentDocument.type, setNewTemplateModalVisible, setHandleTemplateOpen, postTemplate]
  );

  const downLoadPdf = async (isArchive) => {
    message.success('Création du PDF en cours');
    handleUnSelectBox();
    handleUnSelectContainer();
    saveAndGeneratePdf(isArchive);
  };

  const handleCreateTemplate = (e) => {
    setNewTemplateModalVisible(true);
  };

  const GenerateDivider = ({ text }) => {
    return (
      <Divider
        style={{
          borderColor: '#ffffff',
          color: '#ffffff',
          width: '212px',
          fontFamily: 'Montserrat',
          fontSize: '11px',
          paddingLeft: '5px',
          paddingRight: '5px',
          marginTop: '8px',
          marginBottom: '16px',
        }}
        plain
      >
        {text}
      </Divider>
    );
  };

  const GeneratePDFButton = ({ isArchive }) => {
    const disabled = isArchive ? PDFRenderState === "visualize" : PDFRenderState === "archive"
    return (
      <Button
        className='style_btn_right_side'
        onClick={() => downLoadPdf(isArchive)}
        icon={isArchive ? < FontAwesomeIcon icon={icon({
          name: 'cloud-arrow-down', style: 'light'
        })} style={{ marginRight: "8px" }} /> : < FontAwesomeIcon icon={icon({
          name: 'eye', style: 'light'
        })} style={{ marginRight: "8px" }} />}
        type='primary'
        loading={isArchive ? PDFRenderState === "archive" : PDFRenderState === "visualize"}
        disabled={disabled}
        style={{
          ...(disabled ? { backgroundColor: PRIMARY_COLOR, opacity: 0.65, borderColor: PRIMARY_COLOR } : {})
        }}
      >
        <span style={{ width: 100 }}>{t(isArchive ? 'archive_pdf' : 'visualize_pdf')}</span>
      </Button>
    );
  };

  const salesmanChoseTemplate =
    !isAdmin &&
    (currentDocument.type === 'offer' ||
      currentDocument.type === 'opportunity');

  const canSave = isAdmin || salesmanChoseTemplate;

  const { getTemplates, setMaskEditing } = useStore(selector);

  const [zoomSelected, setZoomSelected] = useState(zoomList.find(z => z.value === dynamicZoom()) || zoomList[6]);

  return (
    <>
      {!loadingData && canSave && (
        <TemplateModal
          open={handleTemplateOpen}
          handleCancel={() => setHandleTemplateOpen(false)}
          handleOk={() => {
            setNewTemplateModalVisible(false);
            setHandleTemplateOpen(false);
          }}
          data={templates}
          isAdmin={isAdmin}
          newTemplateOnClick={handleCreateTemplate}
        />
      )}
      {isAdmin && !loadingData && NewTemplateModalVisible && (
        <NewTemplateModal
          type={isAdmin ? 'template' : 'proposition'}
          open={true}
          handleCancel={() => setNewTemplateModalVisible(false)}
          handleOk={saveNewTemplate}
        />
      )}
      {isAdmin && !loadingData && configurationPanelVisible && (
        <ConfigurationModal
          open={true}
          handleOk={() => setConfigurationPanelVisible(false)}
        />
      )}
      <OverlaySider
        side={'right'}
      >
        <div className='logo' />

        <div style={{ backgroundColor: getPrimaryDark(currentDocument.type === 'template') }} id='global-menu'>
          <div className='menu_wrapper' style={{
            height: 'calc(100vh - 54px)',
            overflow: 'hidden scroll'
          }}>
            <div className='menu_item-main_block'>
              <div style={{
                position: 'sticky',
                top: 0,
                backgroundColor: getPrimaryDark(currentDocument.type === 'template'),
                zIndex: 999,
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div>
                  {canSave && (
                    <div key='sub2' style={menuItemStyle}>
                      {currentDocument.type === 'template' ? (
                        <Button
                          className='style_btn_right_side'
                          icon={< FontAwesomeIcon icon={icon({ name: 'folder-open', style: 'light' })} style={{ marginRight: "8px" }} />}
                          onClick={() => {
                            setHandleTemplateOpen(true);
                            getTemplates();
                          }}
                          ghost
                        >
                          {t('documents')}
                        </Button>
                      ) : (
                        <Popconfirm
                          title={
                            'Votre progression non sauvegardée sera perdue. Continuer ?'
                          }
                          cancelText='Annuler'
                          okText='Continuer'
                          placement='bottom'
                          getPopupContainer={(trigger) => trigger.parentElement}
                          onConfirm={() => {
                            handleProposalList(true);
                            emptyUrl();
                            if (currentDocument.type === 'opportunity') {
                              updateUrl({ oid: currentDocument.id });
                            } else if (currentDocument.type === 'offer') {
                              updateUrl({ ofid: currentDocument.id });
                              fetchDatas();
                            }
                          }}
                        >
                          <Button
                            className='style_btn_right_side'
                            icon={< FontAwesomeIcon icon={icon({ name: 'folder-open', style: 'light' })} style={{ marginRight: "8px" }} />}
                            ghost
                          >
                            {t('documents')}
                          </Button>
                        </Popconfirm>
                      )}
                    </div>
                  )}
                  {canSave && (
                    <div key='9' style={menuItemStyle}>
                      <Button
                        className='style_btn_right_side'
                        onClick={saveDocument}
                        icon={< FontAwesomeIcon icon={icon({ name: 'floppy-disk', style: 'light' })} style={{ marginRight: "8px" }} />}
                        loading={saveDocumentLoading}
                        disabled={saveDocumentLoading}
                        ghost={!saveDocumentLoading}
                      >
                        {t('save')}
                      </Button>
                    </div>
                  )}
                  {!(currentDocument.type === 'template' && isAdmin) && !landscape && (
                    <div key='header-footer-editor' style={menuItemStyle}>
                      <Button
                        className='style_btn_right_side'
                        icon={< FontAwesomeIcon icon={icon({ name: 'memo-pad', style: 'regular' })} style={{ marginRight: "8px" }} />}
                        ghost
                        onClick={() => setMaskEditing([MASKS_TYPE.HEADER.id, MASKS_TYPE.FOOTER.id])}
                      >
                        {t('header-footer')}
                      </Button>
                    </div>
                  )}
                  <div key='grille' style={menuItemStyle}>
                    <Switch
                      checkedChildren={t('hide-margins')}
                      unCheckedChildren={t('show-margins')}
                      checked={displayMargins}
                      onClick={(e) => {
                        setDisplayMargins();
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', gap: 2 }}>
                    <Button
                      icon={<FontAwesomeIcon icon={icon({ name: 'magnifying-glass-minus', style: 'solid', family: "duotone" })} style={{ fontSize: 20 }} />}
                      onClick={() => {
                        const zoomValue = zoomList.find(z => z.index === zoomSelected?.index - 1)
                        if (zoomValue === undefined) return;
                        setZoomSelected(zoomValue);
                        handleUpdateZoom(zoomValue.value)
                      }}
                      size='medium'
                    />

                    <NavbarSelector
                      key="fontSizeSelector"
                      tooltipContent={t('zoom-size')}
                      onSelect={(e) => {
                        const zoomValue = zoomList.find(z => z.value === e)

                        setZoomSelected(zoomValue);
                        handleUpdateZoom(zoomValue.value)
                      }}
                      selectedValue={zoomSelected?.value}
                      options={zoomList}
                      searchable={false}
                      arrow={false}
                      customStyle={{
                        borderRadius: 4,
                        width: 75,
                        margin: 0,
                        height: 32,
                        textAlign: "center",
                      }}
                    />

                    <Button
                      icon={<FontAwesomeIcon icon={icon({ name: 'magnifying-glass-plus', style: 'solid', family: "duotone" })} flip={"horizontal"} style={{ fontSize: 20 }} />}
                      onClick={() => {
                        const zoomValue = zoomList.find(z => z.index === zoomSelected?.index + 1)
                        if (zoomValue === undefined) return;

                        setZoomSelected(zoomValue);
                        handleUpdateZoom(zoomValue.value)
                      }
                      }
                      size='medium'
                    />
                  </div>
                </div>
              </div>
              <GenerateDivider text='Élément copié' />

              {copy != null ? (
                <CopyItem copy={copy} />
              ) : (
                <div
                  style={{
                    marginLeft: '5px',
                    width: size,
                    height: size,
                    backgroundColor: getPrimaryDark(currentDocument.type === 'template'),
                    borderColor: getPrimaryDark(currentDocument.type === 'template'),
                    borderRadius: '5px',
                    fontSize: '9px',
                    fontFamily: 'Montserrat',
                    color: '#000000',
                    textAlign: 'center',
                  }}
                ></div>
              )}
              <GenerateDivider text='Mise en page' />
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <ItemButton
                  type={ItemTypes.SECTION}
                  text={t('section')}
                  icon={< FontAwesomeIcon icon={icon({ name: 'rectangle-history-circle-plus', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.LINE_BREAK}
                  text={t('line_break')}
                  icon={< FontAwesomeIcon icon={icon({ name: 'turn-down-left', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.SPACE}
                  text={t('space')}
                  icon={<span className="fa-layers" style={{ width: '21px' }}>
                    <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                    <FontAwesomeIcon icon={icon({ name: 'arrow-up-right-and-arrow-down-left-from-center', style: 'regular' })} style={{ fontSize: iconFontSize - 14, transform: 'rotate(135deg)' }} />
                  </span>}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.MASK}
                  text={t('mask')}
                  icon={<span className="fa-layers" style={{ width: '21px' }}>
                    <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                    <FontAwesomeIcon icon={icon({ name: 'masks-theater', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
                  </span>}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.DRAW}
                  text={t('drawing')}
                  icon={<span className="fa-layers" style={{ width: '21px' }}>
                    <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                    <FontAwesomeIcon icon={icon({ name: 'scribble', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
                  </span>}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <GenerateSpace />
                <ItemButton
                  type={ItemTypes.TITLE}
                  text={t('title1')}
                  icon={<span className="fa-layers" style={{ width: '21px' }}>
                    <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                    <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
                  </span>}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.TITLE_2}
                  text={t('title2')}
                  icon={<span className="fa-layers" style={{ width: '21px' }}>
                    <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                    <FontAwesomeIcon icon={icon({ name: '2', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
                  </span>}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.TITLE_3}
                  text={t('title3')}
                  icon={<span className="fa-layers" style={{ width: '21px' }}>
                    <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                    <FontAwesomeIcon icon={icon({ name: '3', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
                  </span>}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.TITLE_4}
                  text={t('title4')}
                  icon={<span className="fa-layers" style={{ width: '21px' }}>
                    <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                    <FontAwesomeIcon icon={icon({ name: '4', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
                  </span>}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.SUMMARY}
                  text={t('summary')}
                  icon={<span className="fa-layers" style={{ width: '21px' }}>
                    <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                    <FontAwesomeIcon icon={icon({ name: 'list-ol', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
                  </span>}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='block'
                />
                <GenerateDivider text='Édition' />
                <ItemButton
                  type={ItemTypes.TEXT}
                  text={t('text')}
                  icon={< FontAwesomeIcon icon={icon({ name: 'pen-fancy', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.TABLE}
                  text={t('table')}
                  icon={< FontAwesomeIcon icon={icon({ name: 'table', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                {/* <GenerateSpace /> */}
                <ItemButton
                  type={ItemTypes.IMG}
                  text={t('image')}
                  icon={< FontAwesomeIcon icon={icon({ name: 'image', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.SHAPE}
                  text={t('shape')}
                  icon={< FontAwesomeIcon icon={icon({ name: 'star', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.LINE}
                  text={t('line')}
                  icon={< FontAwesomeIcon icon={icon({ name: 'dash', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
                <ItemButton
                  type={ItemTypes.FILE_PDF}
                  text={"PDF"}
                  icon={< FontAwesomeIcon icon={icon({ name: 'file-pdf', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
              </div>
              <GenerateDivider text='Variables' />

              {currentDocument.type === 'template' &&
                <><ItemButton
                  type={ItemTypes.TEXT_VARIABLE}
                  text={t('text_variable')}
                  icon={< FontAwesomeIcon
                    icon={variablesIcons[ItemTypes.TEXT_VARIABLE]}
                    style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor={VARIABLE_COLOR}
                  disp='inline-block'
                  color="white"
                />
                  <ItemButton
                    type={ItemTypes.SECTION_VARIABLE}
                    text={t('section_variable')}
                    icon={< FontAwesomeIcon
                      icon={variablesIcons[ItemTypes.SECTION_VARIABLE]}
                      style={{ fontSize: iconFontSize }} />}
                    config={gridConfiguration}
                    bgcolor={VARIABLE_COLOR}
                    disp='inline-block'
                    color="white"
                  />
                  <ItemButton
                    type={ItemTypes.SELECTOR_VARIABLE}
                    text={t('selector_variable')}
                    icon={< FontAwesomeIcon
                      icon={variablesIcons[ItemTypes.SELECTOR_VARIABLE]}
                      style={{ fontSize: iconFontSize }} />}
                    config={gridConfiguration}
                    bgcolor={VARIABLE_COLOR}
                    disp='inline-block'
                    color="white"
                  />
                  <ItemButton
                    type={ItemTypes.CHECKBOXS_VARIABLE}
                    text={t('checkboxs_variable')}
                    icon={< FontAwesomeIcon
                      icon={variablesIcons[ItemTypes.CHECKBOXS_VARIABLE]}
                      style={{ fontSize: iconFontSize }} />}
                    config={gridConfiguration}
                    bgcolor={VARIABLE_COLOR}
                    disp='inline-block'
                    color="white"
                  />
                </>}
            </div>
            <div className='menu_item-generate_pdf'>
              <div key='8' id='generate-pdf-button' style={menuItemStyle}>
                <GeneratePDFButton />
              </div>
              {(currentDocument.type === 'opportunity' ||
                currentDocument.type === 'offer') && <div style={menuItemStyle} id='generate-pdf-button'>
                  <GeneratePDFButton isArchive />
                </div>}
            </div>
          </div>
        </div>
      </OverlaySider >
    </>
  );
};

export default React.memo(RightSide);
