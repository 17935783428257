import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import NavbarSelector from '../Layout/NavbarSelector';

const selector = ({ configuration }) => ({
  configuration,
});

const FontFamilyControls = (props) => {
  const { configuration } = useStore(selector);
  var currentStyle = props?.editorState?.getCurrentInlineStyle();

  let selectedFontFamily = props?.fontFamily || configuration.fontFamily;
  configuration?.fontFamilyList.forEach((type) => {
    if (currentStyle?.includes(`FONT_FAMILY_${type.key}`)) {
      selectedFontFamily = type.familyName;
    }
  });

  const { t } = useTranslation();

  const fontOptions = configuration?.fontFamilyList.map((type) => {
    return {
      value: type.familyName,
      label: type.familyName,
      style: { fontFamily: type.familyName },
    }
  });

  return (
    <NavbarSelector
      key="fontSelector"
      tooltipContent={t('font-family')}
      onSelect={props.applyFontFamily}
      selectedValue={selectedFontFamily}
      options={fontOptions}
      customStyle={{ fontFamily: selectedFontFamily }}
      className="font-family-selector"
    />
  );
};

export default FontFamilyControls;
