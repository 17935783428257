import React, { useMemo } from 'react'
import { useStore } from '../../../store/store';
import { Select } from 'antd';
import { Editor } from 'draft-js';
import { ItemTypes } from '../../../constants/constants';
import { useQueryDocumentSections } from '../../../hooks';
const { Option } = Select

const TriggerValueSelect = ({ box, value, record, trigger }) => {
  const updateTrigger = useStore(({ updateTrigger }) => updateTrigger)
  const documentType = useStore(({ currentDocument }) => currentDocument.type)
  const documentId = useStore(({ currentDocument }) => currentDocument.id)
  const { data: sectionList } = useQueryDocumentSections({ documentType, documentId })

  const selectableValues = useMemo(() => {
    if (record.triggerType === ItemTypes.CHECKBOXS_VARIABLE || record.triggerType === ItemTypes.QUESTION) {
      return trigger.checkboxs
    }
    if (record.triggerType === ItemTypes.SELECTOR_VARIABLE) {
      return trigger.options
    }
    if (record.triggerType === ItemTypes.SECTION_VARIABLE) {
      return sectionList?.map((s) => ({ ...s, label: s.name }))
    }
  }, [record.triggerType, trigger, sectionList])

  const multi = useMemo(() => {
    return trigger.maxNumberOfAnwser > 1
  }, [trigger])

  const formattedValue = useMemo(() => {
    return !value && multi ? [] : value
  }, [multi, value])

  const updateValue = (newValue) => {
    updateTrigger(record.id, box.id, { value: newValue ?? null })
  }



  return (
    <div style={{
      display: 'flex',
      width: "100%"
    }}>
      <Select
        style={{
          width: "100%"
        }}
        popupClassName="trigger-select-name"
        bordered={false}
        showSearch={false}
        optionLabelProp="label"
        value={formattedValue}
        showArrow={!formattedValue || formattedValue.length < 1}
        onChange={updateValue}
        mode={multi ? 'multiple' : undefined}
        disabled={record.from_linked_section}
      >
        {selectableValues
          ?.map(({ label, id }) =>
            <Option key={id} value={id} label={typeof label === "string" ? label : <Editor
              readOnly
              editorState={label} />}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
              }}>
                {typeof label === "string" ? label : <Editor
                  readOnly
                  editorState={label} />}
              </div>
            </Option>
          )
        }
      </Select>
    </div>
  )
}

export default TriggerValueSelect
