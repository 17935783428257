import { useStore } from '../../../store/store';
import { handleTransformIndicator } from '../../common/TitleConfiguration';

const selector = ({ configuration }) => ({
  configuration,
});

export const ItemLabel = ({ label }) => {
  return <div style={{ marginLeft: '2%', marginTop: '2%' }}>{label}</div>;
};

export const handleTitleIndex = (title) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { configuration } = useStore(selector);
  return title[5] === '1'
    ? configuration.hideTitleIndex
      ? null
      : handleTransformIndicator(configuration['title1'].typeTitle) +
      configuration.separatorTitle
    : title[5] === '2'
    ? configuration.hideTitleIndex
      ? handleTransformIndicator(configuration['title2'].typeTitle) +
        configuration.separatorTitle
      : handleTransformIndicator(configuration['title1'].typeTitle) +
        configuration.separatorTitle +
        handleTransformIndicator(configuration['title2'].typeTitle) +
        configuration.separatorTitle
    : title[5] === '3'
    ? configuration.hideTitleIndex
      ? handleTransformIndicator(configuration['title2'].typeTitle) +
        configuration.separatorTitle +
        handleTransformIndicator(configuration['title3'].typeTitle) +
        configuration.separatorTitle
      : handleTransformIndicator(configuration['title1'].typeTitle) +
        configuration.separatorTitle +
        handleTransformIndicator(configuration['title2'].typeTitle) +
        configuration.separatorTitle +
        handleTransformIndicator(configuration['title3'].typeTitle) +
        configuration.separatorTitle
    : title[5] === '4'
    ? configuration.hideTitleIndex
      ? handleTransformIndicator(configuration['title2'].typeTitle) +
        configuration.separatorTitle +
        handleTransformIndicator(configuration['title3'].typeTitle) +
        configuration.separatorTitle +
        handleTransformIndicator(configuration['title4'].typeTitle) +
        configuration.separatorTitle
      : handleTransformIndicator(configuration['title1'].typeTitle) +
        configuration.separatorTitle +
        handleTransformIndicator(configuration['title2'].typeTitle) +
        configuration.separatorTitle +
        handleTransformIndicator(configuration['title3'].typeTitle) +
        configuration.separatorTitle +
        handleTransformIndicator(configuration['title4'].typeTitle) +
        configuration.separatorTitle
    : null;
};
