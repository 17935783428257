import { message } from 'antd';
import { produce } from 'immer';

export const pdfActions = (set, get) => ({
  handlePdfReponse: (data) => {
    set(
      produce((draft) => {
        if (data?.pdf_url) {
          if (draft.PDFRenderState === 'archive')
            message.success('Le PDF a bien été enregistré');
          else message.success('Le PDF a bien été généré');
          const newWindow = window.open(
            data.pdf_url,
            '_blank',
            'noopener,noreferrer'
          );
          if (newWindow) {
            newWindow.focus();
            newWindow.opener = null;
          }
        } else if (data?.pdf_error) {
          message.error('Une erreur est survenu lors de la génération du PDF');
        }
        draft.PDFRenderState = false;
      }),
      false,
      `handlePdfReponse has ${data?.pdf_url ? 'succeed' : 'failed'}`
    );
  },
});
