import { Cascader } from 'antd';
import { useStore } from '../store/store';
import './CascaderCustom.css';

const CascaderCustom = ({
  tablesToDisplay,
  functionToExec,
  disableCascader = false,
  placeholderToDisplay = 'no placeholder',
  placement = 'bottomLeft',
  sytleCustom,
  selectedBox,
}) => {
  const cascaderFilter = (inputValue, path) => {
    path.some((option) => {
      return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    });
  };
  const { setEventManagerEnabled, addVariablePathToBox, selectedBoxId } =
    useStore(
      ({ setEventManagerEnabled, addVariablePathToBox, selectedBoxId }) => ({
        setEventManagerEnabled,
        addVariablePathToBox,
      })
    );
  return (
    <Cascader
      disabled={disableCascader}
      placement={placement}
      options={tablesToDisplay}
      value={selectedBox ? selectedBox.variablePath : null}
      onChange={(e, selectedOptions) => {
        functionToExec(e, selectedOptions);
        setEventManagerEnabled(true);
        if (selectedBoxId) addVariablePathToBox(selectedBox?.id, e);
        document.activeElement.blur();
      }}
      placeholder={placeholderToDisplay}
      onFocus={() => {
        setEventManagerEnabled(false);
      }}
      onBlur={() => {
        setEventManagerEnabled(true);
      }}
      showSearch={{
        cascaderFilter,
      }}
      style={{
        borderRadius: '5px',
        ...sytleCustom
      }}
    // dropdownRender={(menus) => {
    //   return <div>{menus}</div>;
    // }}
    />
  );
};
export default CascaderCustom;
