import React, { useCallback, useEffect, useState } from 'react'
import MenuLine from './MenuLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons';
import { useStore } from '../../store/store';
import { isVariableCustom } from '../../utils/variables';
import { getAnchorOffset, injectVariableInEditorState } from '../RichEditor/utils';
import { Input, Popover, Switch } from 'antd';
import AddVariableForm from './AddVariableForm';
import { mutateInsertVariableInOrderArray } from '../../utils/variableOrderArray';

const createVariableKey = "createVariable"

const delimiter = "@"

const selector = ({ variables, currentDocument, updateVariable }) => {
  return ({
    variables,
    isTemplate: currentDocument.type === 'template',
    isOfferProposal: currentDocument.type === 'offer',
    updateVariable
  })
}

const SelectionVariableTranslator = ({
  options,
  selectedOptionKey,
  setSelectedOptionKey,
  setEditorState,
  startOffset,
  endOffset,
  blockText,
  setOptions
}) => {
  const {
    variables,
    isTemplate,
    isOfferProposal,
    updateVariable
  } = useStore(selector)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('');
  const [showCustom, setShowCustom] = useState(false);


  const handleSwitchChange = (checked) => {
    setShowCustom(checked);
  };

  const formatVariablesAsOptions = useCallback(() => {
    if (!variables || endOffset !== startOffset) {
      return [];
    }

    let startVariableName;
    for (
      startVariableName = startOffset - 1;
      startVariableName > 0 && blockText[startVariableName] !== delimiter;
      startVariableName -= 1);
    if (blockText[startVariableName] !== delimiter ||
      (startVariableName !== 0 && blockText[startVariableName - 1].match(/^[0-9a-z]+$/i))) {
      return []
    }

    if (startOffset - startVariableName === 1 && isTemplate) {
      return [
        { key: "createVariable", name: "Créer une variable" },
        ...Object.entries(variables).filter(([_, { isImg }]) => !isImg).map(([key, { name }]) => ({ key, name }))
      ]
    }

    const variableSearchInput = blockText.slice(startVariableName + 1, startOffset)
    return Object.entries(variables).filter(([_, variable]) => {
      return !variable.isImg && variable.name.toLowerCase().indexOf(variableSearchInput.toLowerCase()) > -1
    }).map(([key, { name }]) => ({ key, name }))
  }, [blockText, endOffset, isTemplate, startOffset, variables])

  useEffect(() => {
    setOptions(formatVariablesAsOptions())
  }, [formatVariablesAsOptions, setOptions])

  const constructOptionName = (variableKey, name) => {
    let baseName = "";
    if (variableKey === createVariableKey) {
      baseName = <FontAwesomeIcon
        icon={faPenToSquare}
        style={{ marginRight: 6 }}
      />
    }
    if (isOfferProposal && !isVariableCustom(variableKey) && variableKey !== createVariableKey) {
      if (variableKey.split(".").length - 1 === 2) {
        baseName = `${variableKey.substring(0, variableKey.lastIndexOf('.'))} / `
      }
      else { baseName = 'Offre / ' }
    }
    return <>{baseName}{name}</>
  }

  const handleUpdateVariable = useCallback((variableKey) => {
    updateVariable(variableKey, (variable, draft) => {
      if (variable.notUsed) {
        delete variable.notUsed
        mutateInsertVariableInOrderArray(draft, variableKey)
      }
    })
  }, [updateVariable])

  const injectVariableInText = useCallback((
    variableKey,
    variablesInStore) => {
    setIsPopoverOpen(false);
    setEditorState((editorState) => {
      return injectVariableInEditorState({
        editorState,
        variableKey,
        variablesInStore,
        isTemplate,
        startOffset,
        anchorOffset: getAnchorOffset({ editorState, startOffset, delimiter }),
        blockKey: editorState.getSelection().getStartKey()
        ,
      })
    })
  }, [isTemplate, setEditorState, startOffset])

  const onSelect = useCallback((event, variableKey) => {
    event.stopPropagation()
    event.preventDefault()
    if (variableKey === createVariableKey) {
      setIsPopoverOpen(true)
    } else {
      injectVariableInText(variableKey, variables)
      handleUpdateVariable(variableKey)
    }
  }, [injectVariableInText, handleUpdateVariable, variables])

  const handleSelectAction = useCallback((event) => {
    if (!['Enter'].includes(event.key) || isPopoverOpen || !options || options?.length < 1) return;

    onSelect(event, selectedOptionKey)
  }, [onSelect, isPopoverOpen, options, selectedOptionKey])

  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  };

  useEffect(() => {
    window.addEventListener('keydown', handleSelectAction, true);
    return () => {
      window.removeEventListener('keydown', handleSelectAction, true);
    };
  }, [handleSelectAction]);

  const renderLine = (key, name, customButtonStyle = {}, customClassName = '') =>
    <MenuLine
      key={key}
      optionKey={key}
      optionName={constructOptionName(key, name)}
      selectedOptionKey={selectedOptionKey}
      onSelect={onSelect}
      setSelectedOptionKey={setSelectedOptionKey}
      customButtonStyle={customButtonStyle}
      customClassName={customClassName}
    />

  const filteredOptions = options.filter(({ key, name }) => {
    if (key === "createVariable") return true;

    const isCustom = key.startsWith("custom.");

    const normalizedSearchTerm = searchTerm.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const normalizedName = name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    if (showCustom) {
      return isCustom && normalizedName.includes(normalizedSearchTerm);
    }

    return normalizedName.includes(normalizedSearchTerm);
  });



  return (
    <div style={{ width: 500 }}>
      <div style={{ display: "flex", flexDirection: "row", padding: 10 }}>
        <Input.Search
          placeholder="Rechercher une variable"
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ marginBottom: 8 }}
        />
        {/* <Switch style={{ width: 160, margin: 5 }} checked={showCustom} onChange={handleSwitchChange} checkedChildren="Personnalisées" unCheckedChildren="Variables" /> */}
      </div>
      {filteredOptions
        .sort((a, b) => {
          if (a.key === "createVariable") return -1;
          if (b.key === "createVariable") return 1;

          const isCustomA = a.key.startsWith("custom.");
          const isCustomB = b.key.startsWith("custom.");

          if (isCustomA && !isCustomB) return 1;
          if (!isCustomA && isCustomB) return -1;

          return a.name.localeCompare(b.name);
        })
        .map(({ key, name }) =>
          key === createVariableKey ? (
            <Popover
              key={key}
              open={isPopoverOpen}
              destroyTooltipOnHide
              zIndex={9999}
              trigger="click"
              title="Créer une nouvelle variable"
              placement="bottom"
              arrow
              align={{
                offset: [0, -5],
              }}
              overlayStyle={{
                width: 400,
              }}
              content={<AddVariableForm setIsPopoverOpen={setIsPopoverOpen} filteredOptions={filteredOptions} injectVariableInText={injectVariableInText} />}
            >
              {renderLine(
                key,
                name,
                { fontWeight: key === createVariableKey ? 'bold' : 'normal' },
                key === createVariableKey ? 'create' : ''
              )}
            </Popover>
          ) : (
            renderLine(key, name)
          )
        )}
    </div>
  );
}

export default SelectionVariableTranslator;
