import React from 'react'
import { variableStyle } from '../../../utils/styles'
import FormGroupInfos from './FormGroupInfos'
import FormGroupArray from './FormGroupArray'

const LeftPart = ({ box, validateFormAndRetrieveValues, form, setCreateItemType }) => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      <FormGroupInfos box={box} validateFormAndRetrieveValues={validateFormAndRetrieveValues} form={form} setCreateItemType={setCreateItemType} />
    </div>
  )
}

const RightPart = ({ box, variablesGroupedByContainer, setCreateItemType }) => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      {box.id !== 'create-group' && <FormGroupArray box={box} variablesGroupedByContainer={variablesGroupedByContainer} setCreateItemType={setCreateItemType} />}
    </div>
  )
}

const FormGroup = ({ box, validateFormAndRetrieveValues, form, variablesGroupedByContainer, formPart, setCreateItemType }) => {
  return (
    <>
      {formPart === 'left' && <LeftPart box={box} validateFormAndRetrieveValues={validateFormAndRetrieveValues} form={form} setCreateItemType={setCreateItemType} />}
      {formPart === 'right' && <RightPart box={box} variablesGroupedByContainer={variablesGroupedByContainer} setCreateItemType={setCreateItemType} />}
    </>
  )
}

export default FormGroup
