import {
  Arrow,
  Circle,
  Ellipse,
  Rect,
  RegularPolygon,
  Ring,
  Star,
} from 'react-konva';
import {
  casesInGrid,
  gridHeight,
  gridWidth,
} from './../../constants/gridConfig';
import { ItemTypes } from '../../constants/constants';

export const NO_HEIGHT_TYPES = [
  'img',
  'text',
  'title1',
  'title2',
  'title3',
  'title4',
  'summary',
  'table',
  ItemTypes.CHECKBOXS_VARIABLE,
  ItemTypes.QUESTION,
  ItemTypes.SELECTOR_VARIABLE,
  ItemTypes.TEXT_VARIABLE,
  ItemTypes.IMG_VARIABLE,
  ItemTypes.TABLE_VARIABLE,
];
export const LOCK_PROPORTIONS_TYPES = ['img', ItemTypes.IMG_VARIABLE];

export const NO_ROTATION_SHAPES = ['round', 'ring'];

export const starInnerRadius = (boxWidth, boxBorderSize) => {
  const rStar = starRadius(boxWidth, boxBorderSize);
  return (
    (rStar * Math.sin((18 * Math.PI) / 180)) / Math.cos((36 * Math.PI) / 180)
  );
};

export const shapeRatios = (shapeId) => {
  const ratios = {
    star: Math.cos((18 * Math.PI) / 180),
    triangle: Math.cos((30 * Math.PI) / 180),
    hexagone: 1 / Math.cos((30 * Math.PI) / 180),
  };
  return ratios[shapeId] ?? 1;
};

export const LOCK_PROPORTIONS_SHAPES = [
  'square',
  'round',
  'star',
  'ring',
  'triangle',
  'hexagone',
];

export const goldRatio = (1 + Math.sqrt(5)) / 2;

export const shapeRadius = (shapeId, boxWidth) => {
  return boxWidth / (2 * shapeRatios(shapeId));
};

export const starRadius = (boxWidth, boxBorderSize) => {
  const borderAddedWidth = boxBorderSize / Math.tan((18 * Math.PI) / 180);
  const innerBoxHalfWidth = (boxWidth - borderAddedWidth) / 2; // - borderAddedWidth
  return innerBoxHalfWidth / Math.cos((18 * Math.PI) / 180);
};

export const outerBorderRadiusExtension = (boxBorderSize) => {
  return boxBorderSize / Math.sin((18 * Math.PI) / 180) / 2;
};

export const innerBorderRadiusExtension = (borderWidth) => {
  return borderWidth / Math.cos((54 * Math.PI) / 180);
};

export const starYDelta = (boxBorderSize) => {
  return outerBorderRadiusExtension(boxBorderSize);
};

export const getShapeComponent = (box, landscape) => {
  switch (box.content.shape) {
    case 'square':
      return (
        <Rect
          key={box.id}
          id={box.id}
          x={box.borders.size / 2}
          y={box.borders.size / 2}
          width={box.width - box.borders.size}
          height={box.width - box.borders.size}
          stroke={box.borders.color.style}
          strokeWidth={box.borders.size}
          fill={box.content.color}
          fillAfterStrokeEnabled={true}
        />
      );
    case 'round':
      return (
        <Circle
          key={box.id}
          id={box.id}
          x={box.width / 2 - 0.2}
          y={box.width / 2 - 0.2}
          radius={box.width / 2 - box.borders.size / 2 - 0.2}
          stroke={box.borders.color.style}
          strokeWidth={box.borders.size}
          fill={box.content.color}
          fillAfterStrokeEnabled={true}
        />
      );
    case 'rectangle':
      return (
        <Rect
          key={box.id}
          id={box.id}
          x={box.borders.size / 2}
          y={box.borders.size / 2}
          width={box.width - box.borders.size}
          height={box.height - box.borders.size}
          stroke={box.borders.color.style}
          strokeWidth={box.borders.size}
          fill={box.content.color}
          fillAfterStrokeEnabled={true}
        />
      );
    case 'ellipse':
      return (
        <Ellipse
          key={box.id}
          id={box.id}
          x={box.width / 2 - 0.2}
          y={box.height / 2 - 0.2}
          radiusX={box.width / 2 - box.borders.size / 2 - 0.2}
          radiusY={box.height / 2 - box.borders.size / 2 - 0.2}
          stroke={box.borders.color.style}
          strokeWidth={box.borders.size}
          fill={box.content.color}
          fillAfterStrokeEnabled={true}
        />
      );
    case 'star':
      return (
        <Star
          key={box.id}
          id={box.id}
          x={box.width / 2}
          y={
            starRadius(box.width, box.borders.size) +
            outerBorderRadiusExtension(box.borders.size)
          }
          numPoints={5}
          innerRadius={starInnerRadius(box.width, box.borders.size)}
          // innerRadius={shapeRadius('star', box.width) - outerBorderExtension(box.borders.size)}
          // innerRadius={box.width / 6}
          outerRadius={starRadius(box.width, box.borders.size)}
          // outerRadius={shapeRadius('star', box.width)}
          stroke={box.borders.color.style}
          strokeWidth={box.borders.size}
          fillAfterStrokeEnabled={true}
          fill={box.content.color}
        />
      );
    case 'triangle':
      return (
        <RegularPolygon
          key={box.id}
          id={box.id}
          x={box.width / 2}
          y={shapeRadius('triangle', box.width)}
          sides={3}
          radius={shapeRadius('triangle', box.width)}
          stroke={box.borders.color.style}
          // strokeWidth={box.borders.size}
          fill={box.content.color}
        />
      );
    case 'hexagone':
      const h = box.height - box.borders.size;
      return (
        <RegularPolygon
          key={box.id}
          id={box.id}
          x={box.width / 2}
          y={box.height / 2}
          sides={6}
          radius={h / 2}
          stroke={box.borders.color.style}
          strokeWidth={box.borders.size}
          fill={box.content.color}
          fillAfterStrokeEnabled={true}
        />
      );
    case 'ring':
      return (
        <Ring
          key={box.id}
          id={box.id}
          x={box.width / 2}
          y={box.height / 2}
          innerRadius={box.width / 2 - box.borders.size}
          outerRadius={box.width * 0.4}
          stroke={box.borders.color.style}
          strokeWidth={box.borders.size}
          fill={box.content.color}
        />
      );
    // case 'diamond':
    //   return (
    //     <RegularPolygon
    //       key={box.id}
    //       id={box.id}
    //       x={box.width / 2}
    //       y={box.height / 2}
    //       sides={4}
    //       radius={box.width / 2 - box.borders.size}
    //       stroke={box.borders.color.style}
    //       strokeWidth={box.borders.size}
    //       fill={box.content.color}
    //     />
    //   );
    case 'arrow':
      const pointerLength =
        box.arrows.strokeLength ?? shapeRadius('triangle', box.arrows.stroke);
      return (
        <Arrow
          key={box.id}
          id={box.id}
          pointerAtBeginning={box.arrows.left}
          pointerAtEnding={box.arrows.right}
          points={[
            box.arrows.left ? pointerLength + 1.5 : 0,
            box.height / 2,
            box.arrows.right ? box.width - pointerLength - 2 : box.width,
            box.height / 2,
          ]}
          width={box.width}
          height={box.height}
          pointerLength={
            box.arrows.strokeLength ??
            shapeRadius('triangle', box.arrows.stroke)
          }
          pointerWidth={box.arrows.stroke}
          stroke={box.content.color}
          strokeWidth={box.stroke}
          fill={box.content.color}
        />
      );
    default:
      return (
        <Rect
          key={box.id}
          id={box.id}
          x={box.borders.size / 2}
          y={box.borders.size / 2}
          width={box.width - box.borders.size}
          height={box.width - box.borders.size}
          stroke={box.borders.color.style}
          strokeWidth={box.borders.size}
          fill={box.content.color}
          fillAfterStrokeEnabled={true}
        />
      );
  }
};
