import { produce } from 'immer';

export const variableOrderArrayActions = (set, get) => ({
  reorderVariables: (variables) => {
    set(
      produce((draft) => {
        draft.variableOrderArray = variables.map((v) => ({
          id: v.id,
          containerId: v.containerId,
        }));
      }),
      false,
      `Reorder variables`
    );
  },
});
