import React, { useEffect, useRef } from 'react'
import logo from "../../images/symbol.svg"
import { Tooltip } from 'antd';
const MenuLine = ({
  customClassName = '',
  optionKey,
  optionName,
  selectedOptionKey,
  onSelect,
  setSelectedOptionKey,
  customButtonStyle
}) => {
  const lineRef = useRef();

  useEffect(() => {
    if (selectedOptionKey === optionKey) {
      lineRef.current.focus()
      lineRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      })
    }
  }, [selectedOptionKey, optionKey]);

  const handleOnClick = (event) => {
    onSelect(event, optionKey)
  }

  const renderCustomLogo = () => {
    // Check if variable is a custom variable
    if (optionKey.slice(0, 7) !== "custom." && optionKey !== "createVariable") {
      return <Tooltip zIndex={9999} title="En provenance de l'application" ><img src={logo} alt="custom" style={{ width: 20, height: 20, marginLeft: 7 }} /> </Tooltip>
    } else {
      return <div style={{ width: 30 }} />
    }
  }

  return (
    <li style={{
      display: "flex",
      alignItems: "center",
      listStyleType: "none",
      borderRadius: 8,
      position: 'relative',
      ...(optionKey === selectedOptionKey ? {
        backgroundColor: 'rgba(0, 0, 0, 0.06)'
      } : {})
    }}
      className={`variable-selection-line-wrapper ${customClassName}`}
      ref={lineRef}
      onMouseMove={() => {
        setSelectedOptionKey(optionKey)
      }}
      onMouseDown={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      {renderCustomLogo()}
      <button
        className='variable-selection-line'
        onClick={handleOnClick}
        style={{
          width: '100%',
          border: 'none',
          backgroundColor: 'transparent',
          display: 'flex',
          height: '100%',
          padding: 8,
          cursor: 'pointer',
          alignItems: 'center',
          ...customButtonStyle
        }}
      >
        {optionName}
      </button>
    </li>
  )
}

export default MenuLine;
