import { Form, } from 'antd'
import { Editor } from 'draft-js';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactDOM from 'react-dom';
import { useStyleMap } from '../../../hooks';
import { getAncestorFromType, getBlockStyle } from '../../RichEditor/utils';
import { transformTextToEditorState } from '../../../utils/dataSync';
import { useStore } from '../../../store/store';
import { navbarHeight } from '../../../constants/gridConfig';
import EditorWrapper from '../../common/EditorWrapper';
import ContextualMenu from '../../ContextualMenu/ContextualMenu';
import { MENU_PARENT } from '../../../constants/constants';
import { EditorProvider } from '../../../contexts/EditorContext';
import FloatyTextStyling from '../../Table/FloatyTextStyling';

const FormBoxRemark = ({ box, onBlur, form }) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const remark = Form.useWatch('remark')
  const styleMap = useStyleMap()
  const handleUnselectBox = useStore((state) => state.handleUnSelectBox)
  const editorRef = useRef()
  const isDisabled = box.from_linked_section

  const setEditorState = useCallback((fn) => {
    if (!form || isDisabled) return;
    const newEditorState = typeof fn === 'function' ? fn(form.getFieldValue("remark")) : fn
    form.setFieldValue("remark", newEditorState)
    if (typeof onBlur === 'function')
      onBlur()
  }, [form, onBlur, isDisabled])

  useLayoutEffect(() => {
    var root = document.querySelector(':root');
    root.style.setProperty('--remark-max-height', `${(window.innerHeight - navbarHeight) * 40 / 100 - 32 - 80 - 24 - 40}px`);
  }, [])

  const menuAncestorIds = useMemo(() => [box.id], [box.id])

  return (
    <EditorProvider setEditorState={setEditorState} editorState={remark}>
      <EditorWrapper boxId={box.id} editorState={remark}>
        <Form.Item
          className={`variable-editor__input remark ${focused ? 'focused' : ''} ${isDisabled ? 'disabled' : ''}`}
          label={t('remarks')}
          name="remark"
          initialValue={transformTextToEditorState(box.remark)}
          onBlur={onBlur}
          valuePropName='editorState'
          labelCol={{ span: 24 }}
          id={box.id}
        >
          <Editor
            readOnly={isDisabled}
            ref={editorRef}
            stripPastedStyles={true}
            onFocus={() => { handleUnselectBox(); setFocused(true) }}
            onBlur={(e) => {
              if (!e.relatedTarget ||
                (e.relatedTarget.className !== 'text-styling-component' &&
                  e.relatedTarget.className !== 'ant-select-selection-search-input')) {
                setFocused(false)
              }
            }}
            customStyleMap={styleMap}
            blockStyleFn={getBlockStyle}
            className='RichEditor-editor'
            style={{
              overflow: 'visible',
            }}
          />
        </Form.Item>
      </EditorWrapper >
      {focused && ReactDOM.createPortal(
        <ContextualMenu
          editorState={remark}
          setEditorState={setEditorState}
          withoutDelimiters={["@"]}
          menuAncestorType={getAncestorFromType(box.type, MENU_PARENT.remark)}
          menuAncestorIds={menuAncestorIds}
        />,
        document.getElementById('SelectionVariablePortal')
      )}
      {focused && ReactDOM.createPortal(
        <FloatyTextStyling
          editorState={remark}
          setEditorState={setEditorState}
        />,

        document.getElementById('SelectionVariablePortal')
      )}
    </EditorProvider>
  )
}

export default FormBoxRemark
