import React, { useMemo } from 'react'
import { useQueryDocumentTypes } from '../../hooks'
import { Select } from 'antd'

const DocumentTypesSelector = ({ ...selectOptions }) => {
  const { data: documentTypes } = useQueryDocumentTypes()

  const options = useMemo(() => documentTypes.map((dT) => ({
    label: dT.name,
    value: dT.id,
  })), [documentTypes])

  return <Select
    {...selectOptions}
    style={{ width: "100%" }}
    mode='multiple'
    placeholder="Choisissez des tags..."
    options={options}
  >
  </Select>
}

export default DocumentTypesSelector
