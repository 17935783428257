import React, { useEffect, useState } from 'react';
import './LeftSide.less';
import OverlaySider from '../Layout/OverlaySider';
import { navbarHeight } from '../../constants/gridConfig';
import SummarySide from './SummarySide';
import { useStore } from '../../store/store';
import { pageBackground, } from '../../constants/constants';
import VariableHandler from '../VariableHandler/VariableHandler';
import { useOverlay } from '../../contexts/OverlayContext';
import { getVariablesGroupedByContainer } from '../../utils/variables';
import LeftSideButton from './LeftSideButton';
import { useWindowDimensions } from '../../hooks';
import { Switch, Tabs } from 'antd';

const LeftSide = () => {
  const variablesGroupedByContainer = useStore(({
    variableGroups,
    variables,
    boxes,
    masks,
    containers,
    columns
  }) => getVariablesGroupedByContainer({
    variableGroups,
    variables,
    boxes: [
      ...boxes,
      ...masks.map((m) => m.boxes).flat()
    ],
    containers,
    columns,
  }))
  const { width } = useWindowDimensions()
  const [columnToShow, setColumnToShow] = useState('triggers')
  const [hidePreview, setHidePreview] = useState(false)
  const isTemplate = useStore(({ currentDocument }) => currentDocument?.type === 'template')


  const { overlayProps } = useOverlay();

  const shownStatus = overlayProps.left.shownStatus

  useEffect(() => {
    if (shownStatus === 'variables') setColumnToShow('triggers')
  }, [shownStatus])

  const onlyButton = shownStatus === 'variables'
  const items = [
    {
      key: 'triggers',
      label: 'Déclencheurs',
    },
    {
      key: 'preview',
      label: 'Aperçu du formulaire',
    },
  ];
  return (
    <>
      <OverlaySider
        side='left'
        zIndex={18}
        onlyButton={onlyButton}
        width={onlyButton ? "100%" : undefined}
      >
        <div style={{
          width: "100%",
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: onlyButton ? 16 : undefined
        }}>
          <div
            className='left-side__summary'
            style={{
              height: onlyButton ? undefined : `calc(100vh - ${navbarHeight}px)`,
              overflowY: 'scroll',
              overflowX: 'hidden',
              width: onlyButton ? 220 : "100%",
            }}
            data-role={pageBackground}
          >
            <LeftSideButton variablesGroupedByContainer={variablesGroupedByContainer} />
            {shownStatus === "summary" &&
              <div className='left-side__wrapper'>
                <SummarySide />
              </div>
            }
          </div>
          {onlyButton && isTemplate && width < 1860 && <div>
            <Tabs
              onChange={(key) => setColumnToShow(key)}
              style={{
                color: 'white'
              }} defaultActiveKey="triggers" items={items} />
          </div>}
          {onlyButton && isTemplate && width >= 1860 && <div style={{
            color: 'white',
            display: 'flex',
            gap: 8,
            alignItems: 'center'
          }}>
            Cacher l'aperçu du formulaire :
            <Switch onChange={(checked) => setHidePreview(checked)} />
          </div>}
        </div>
      </OverlaySider >
      {shownStatus === "variables" &&
        <VariableHandler hidePreview={hidePreview} columnToShow={columnToShow} variablesGroupedByContainer={variablesGroupedByContainer} />
      }
    </>
  );
};

export default React.memo(LeftSide);
