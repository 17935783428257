import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import ModalImage from 'react-modal-image';
import TemplateActions from '../RightSide/TemplateActions';

// ANT Design
import { FileOutlined } from '@ant-design/icons';
import { Table, Tabs, Tooltip, Input } from 'antd';
import { useQueryDocumentTypes } from '../../hooks';
const { Search } = Input;

const selector = (state) => {
  return {
    applyTemplate: state.applyTemplate,
    deleteTemplate: state.deleteTemplate,
    currentDocument: state.currentDocument,
    currentProposal: state.currentProposal,
    proposal: state.currentProposal,
    applyTemplateAndSaveProposal: state.applyTemplateAndSaveProposal,
    user: state.user,
    templates: state.templates,
    sections: state.sections,
  };
};

const TemplateNameColumn = ({ record, onTemplateClick }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div>{t(record.name)}</div>
    </div>
  );
};

export const TemplateList = ({
  visible,
  handleOk,
  handleCancel,
  forbidClose,
  newTemplateOnClick,
  isAdmin,
  onTemplateClick,
}) => {
  const { deleteTemplate, currentDocument, templates, sections } =
    useStore(selector);

  const { t } = useTranslation();
  const [setCurrentTab] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [nameSearch, setNameSearch] = useState('');
  const { data: documentTypes } = useQueryDocumentTypes()

  const cols = (documentType = 'template') => {
    const columns = [
      {
        title: 'Nom',
        key: 'name',
        align: 'start',
        className: 'nameColumn',
        width: '60%',
        onHeaderCell: () => ({
          style: {
            backgroundColor: '#0B2239',
            color: 'white',
          },
        }),
        onCell: (record) => {
          return {
            style: {
              wordWrap: 'anywhere',
              wordBreak: 'normal',
              backgroundColor:
                selectedTemplate === record.id ? '#BAD6F2' : 'white',
            },
            onClick: (e) => {
              onTemplateClick(record.id, record.name);
              setSelectedTemplate(record.id);
            },
          };
        },
        filteredValue: [nameSearch],
        onFilter: (value, record) => {
          return record.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              value
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            );
        },
        render: (record) => <TemplateNameColumn record={record} />,
      },
    ];
    columns.push({
      title: 'Format',
      key: 'format',
      align: 'center',
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#0B2239',
          color: 'white',
        },
      }),
      render: (record) => (
        <p style={{ textAlign: 'center', margin: 0 }}>
          {record.landscape ? (
            <Tooltip title='Format paysage'>
              <FileOutlined
                style={{
                  transform: 'rotate(-90deg)',
                  fontSize: 18,
                  margin: 0,
                  marginRight: 8,
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title='Format portrait'>
              <FileOutlined
                style={{
                  transform: 'scaleX(-1)',
                  fontSize: 18,
                  margin: 0,
                  marginRight: 8,
                }}
              />
            </Tooltip>
          )}
        </p>
      ),
    });
    if (documentType === 'template') {
      columns.push({
        title: 'Actions',
        key: 'action',
        align: 'center',
        onHeaderCell: () => ({
          style: {
            backgroundColor: '#0B2239',
            color: 'white',
            borderLeft: '1px solid white',
          },
        }),
        render: (text, record) => (
          <TemplateActions record={record} deleteTemplate={deleteTemplate} />
        ),
      });
      columns.push({
        title: 'Type',
        key: 'templatable',
        align: 'center',
        onHeaderCell: () => ({
          style: {
            backgroundColor: '#0B2239',
            color: 'white',
            borderLeft: '1px solid white',
          },
        }),
        // <FileOutlined
        //   style={{
        //     transform: 'rotate(-90deg)',
        //     fontSize: 18,
        //     margin: 0,
        //     marginRight: 8,
        //   }}
        // />
        render: (record) => (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {t(record.templatable)}
          </p>
        ),
      });
    }
    columns.push({
      title: 'Métier',
      key: 'work',
      align: 'center',
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#0B2239',
          color: 'white',
          borderLeft: '1px solid white',
        },
      }),
      render: (record) => (
        <p style={{ textAlign: 'center', margin: 0 }}>{t(record.work)}</p>
      ),
    });
    columns.push({
      title: 'Tags',
      key: 'tags',
      align: 'center',
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#0B2239',
          color: 'white',
          borderLeft: '1px solid white',
        },
      }),
      render: (record) => (
        <p style={{ textAlign: 'center', margin: 0 }}>
          {documentTypes?.filter((dT) => record.document_type_ids.includes(dT.id))
            .map((dT) => dT.name).join(", ")}
        </p>
      ),
    });
    // columns.push({
    //   title: 'Aperçu',
    //   key: 'preview',
    //   align: 'center',
    //   onHeaderCell: () => ({
    //     style: {
    //       backgroundColor: '#0B2239',
    //       color: 'white',
    //       borderLeft: '1px solid white',
    //     },
    //   }),
    //   render: (record) => (
    //     <p style={{ textAlign: 'center', margin: 0 }}>
    //       {record.preview ? (
    //         <center>
    //           <div style={{ width: '40%' }}>
    //             <ModalImage
    //               small={record.preview}
    //               large={record.preview}
    //               alt='Aperçu'
    //             />
    //           </div>
    //         </center>
    //       ) : (
    //         'Aperçu non disponible'
    //       )}
    //     </p>
    //   ),
    //   // onHeaderCell: () => ({
    //   //   style: {
    //   //     backgroundColor: '#0B2239',
    //   //     color: 'white',
    //   //     borderLeft: '1px solid white',
    //   //   },
    //   // }),
    // });
    return columns;
  };

  const columns = cols(currentDocument.type).map((c) => {
    if (!c.editable) return c;

    return {
      ...c,
      onCell: (record) => ({
        record,
        editable: c.editable,
        dataIndex: c.dataIndex,
        title: c.title,
      }),
    };
  });
  const handleTabChange = (e) => {
    if (e === 'templates') setCurrentTab(false);
    else if (e === 'sections') setCurrentTab(true);
  };

  return (
    <div>
      <Tabs
        defaultActiveKey='1'
        centered
        onTabClick={(e) => {
          handleTabChange(e);
        }}
      >
        <Tabs.TabItem tab='Modèles' key='templates'>
          <div
            style={{
              display: 'flex',
              width: '60%',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '1%',
            }}
          >
            <div style={{ width: '40%' }}>Recherche par nom:</div>
            <Search
              key='nameSearch'
              placeholder='Rechercher un modèle par nom'
              value={nameSearch}
              style={{
                width: '50%',
                marginBottom: '2%',
                marginTop: '2%',
                marginLeft: '2%',
              }}
              onChange={(e) => {
                setNameSearch(e.target.value);
              }}
            />
          </div>
          <Table pagination={false} columns={columns} dataSource={templates} />
        </Tabs.TabItem>
        {currentDocument.type === 'template' ? (
          <Tabs.TabItem tab='Sections' key='sections'>
            <Table pagination={false} columns={columns} dataSource={sections} />
          </Tabs.TabItem>
        ) : null}
      </Tabs>
    </div>
  );
};

export default TemplateList;
