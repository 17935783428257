import React, { useMemo, useRef, useState } from 'react'
import SimulatedBox from '../../common/SimulatedBox'
import { useOutsideClickHandler } from '../../../utils/browser'
import FormSectionBox from './FormSectionBox'
import { ItemTypes } from '../../../constants/constants'
import { Form, Switch } from 'antd'
import { useStore } from '../../../store/store'

const DefaultValueBox = ({ box, onSelect, isShowing }) => {
  const [isSelected, setIsSelected] = useState(false)
  const updateBox = useStore(({ updateBox }) => updateBox)

  const ref = useRef()
  useOutsideClickHandler(ref, () => {
    setIsSelected(false)
  });

  const formattedBox = useMemo(() => ({
    ...box,
    isBoxSimulated: true,
    withMenu: true
  }), [box])

  return (
    <>
      {!isShowing && <div
        className='variable-editor__middle-title'
      >
        <span>Valeur par défaut</span>
      </div>}
      <div style={{
        width: "100%",
        backgroundColor: !isShowing && box.from_linked_section ? '#b2b2b2 ' : 'white',
        marginBottom: (isShowing ? 0 : 24),
        padding: 8,
        borderRadius: 8,
        position: 'relative',
      }}
        ref={ref}
      >
        {box.from_linked_section && <div style={{
          cursor: 'not-allowed',
          position: 'absolute',
          inset: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999
        }} />}
        {
          ((!isShowing && !box.from_linked_section) || !!box?.hideField) &&
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            marginBottom: 8
          }}>
            Masquer
            <Switch
              disabled={isShowing}
              checked={box?.hideField}
              onChange={() => {
                updateBox(box.id, (b) => {
                  b.hideField = !b.hideField
                  if (b.type === ItemTypes.SECTION_VARIABLE && b.hideField) {
                    b.sectionId = null
                  }
                })
              }}
            />
          </div>}
        {!box.hideField &&
          box.type !== ItemTypes.SECTION_VARIABLE &&
          box.type !== ItemTypes.FILE_PDF_VARIABLE &&
          box.type !== ItemTypes.TABLE_VARIABLE &&
          <SimulatedBox box={formattedBox} isSelected={isSelected} setIsSelected={isShowing || box.from_linked_section ? () => null : setIsSelected} />}
        {box.type === ItemTypes.SECTION_VARIABLE && !isShowing &&
          !box?.hideField &&
          <Form.Item
            name="sectionId"
            initialValue={box.sectionId}
            noStyle
          >
            <FormSectionBox onSelect={onSelect} />
          </Form.Item>
        }
        {box.type === ItemTypes.SECTION_VARIABLE && isShowing && !box?.hideField &&
          <FormSectionBox onSelect={onSelect} disabled value={box.sectionId} />
        }
      </div>
    </>
  )
}

export default DefaultValueBox
