import { Form, Select, } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { dispostionButtons } from '../../constants/constants';

const { Option } = Select;

const VariableDisposition = ({ box, onBlur }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t('disposition')}
      name="disposition"
      initialValue={box.disposition ?? 2}
      labelCol={{ span: 24 }}
      rules={[{ required: true }]}
    >
      <Select onChange={onBlur}>
        {Object.keys(dispostionButtons).map((buttonName) => {
          return (
            <Option
              key={dispostionButtons[buttonName].disposition}
              value={dispostionButtons[buttonName].disposition}
            >
              <div style={{
                display: "flex",
                alignItems: "center",
                gap: 8
              }}>
                {dispostionButtons[buttonName].icon}
                <span>{dispostionButtons[buttonName].disposition} {dispostionButtons[buttonName].disposition === 1 ? t('colon') : t('colons')}</span>
              </div>
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  )
}

export default VariableDisposition
