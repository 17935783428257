import React from 'react'
import Cell from './Cell'

const TableBody = ({
  table,
  columns,
  isSelected,
  boxId,
  handleClickInTable,
  tableStyle,
  theadHeight,
  data,
  convertAlign,
  computeMaxColumnWidth,
  setColumnSizeVars,
  rotation,
  drawMode,
  box
}) => {
  const renderCell = (cell, rowIdx) => {
    const colIdx = columns.findIndex((col) => col.key === cell.column.id)
    const col = columns[colIdx]
    const nextColKey = colIdx !== columns.length - 1 ? columns[colIdx + 1].key : undefined
    const row = data[rowIdx]
    return (
      <Cell
        key={cell.id}
        box={box}
        handleClickInTable={handleClickInTable}
        type={col.type ?? 'text'}
        cellContent={row[col.key]}
        tableIsSelected={isSelected}
        boxId={boxId}
        columnKey={col.key}
        nextColKey={nextColKey}
        rowKey={row.key}
        theadHeight={theadHeight}
        tableStyle={tableStyle}
        align={convertAlign(col.textAlign ?? 'start')}
        isEven={rowIdx % 2 === 0}
        computeMaxColumnWidth={computeMaxColumnWidth}
        setColumnSizeVars={setColumnSizeVars}
        rotation={rotation}
        isLastColumn={colIdx === columns.length - 1}
        drawMode={drawMode}
      />
    )
  }

  return (
    <tbody>
      {table.getRowModel().rows.map((row, idx) => (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => renderCell(cell, idx))}
        </tr>
      ))}
    </tbody>
  )
}

const MemoizedTableBody = React.memo(
  TableBody,
  (prev, next) => {
    return prev.data === next.data &&
      prev.columns.length === next.columns.length &&
      prev.tableStyle === next.tableStyle &&
      prev.theadHeight === next.theadHeight
  }
)

export default MemoizedTableBody
