import { CaretDownFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import { useModalOpener } from '../../hooks';
import { useOutsideClickHandler } from '../../utils/browser';
import { navbarButtonStyle } from '../../utils/styles';
import ColorButton from '../TextStylingComponent/ColorButton';

export const NavbarListButton = ({
  title,
  disabled,
  icon,
  onListElementClick,
  listElements,
  selectedElement,
  children
}) => {

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [displayList, setDisplayList] = useModalOpener(false);
  const listRef = useRef(null);
  useOutsideClickHandler(listRef, () => {
    if (displayList) {
      setDisplayList(false);
      setTooltipVisible(false);
    }
  });

  const buttonDisplay = (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      height: '100%',
      opacity: disabled ? 0.3 : 1,
    }}>
      {icon}
      {selectedElement && selectedElement.style && (
        <div style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '5px',
          backgroundColor: selectedElement.style
        }}></div>
      )}
    </div>
  )

  return (
    <Tooltip
      open={tooltipVisible}
      placement='bottom'
      mouseLeaveDelay='0'
      title={title}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
        ref={listRef}
      >
        <div>
          <Button
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            style={navbarButtonStyle}
            disabled={disabled}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              setDisplayList(!displayList)
              setTooltipVisible(false)
            }}
          >
            {buttonDisplay}
          </Button>
        </div>
        {displayList && <CaretDownFilled style={{ color: 'white', position: 'absolute', top: '42px', alignSelf: 'center' }} />}
        {displayList && (
          <div
            style={{
              zIndex: 15,
              display: 'grid',
              position: 'absolute',
              top: '52px',
              marginTop: '2px',
            }}
          >
            {children}
            {!children && listElements && listElements.map((element, index, arr) => (
              <ColorButton
                key={element.label}
                active={
                  element.label === selectedElement?.label
                }
                label={element.label}
                onToggle={(elementValue) => onListElementClick(elementValue)}
                colorStyle={element.style}
                positionStyle={{
                  gridColumn: Math.ceil((index + 1) / 5),
                  gridRow: (index % 5) + 1,
                }}
                size='32px'
              />
            ))}
          </div>
        )}
      </div>
    </Tooltip>
  );
};
