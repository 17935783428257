import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { variablesIcons, } from "../../../constants/constants";
import { useStore } from "../../../store/store";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";

const FormBoxEditorName = ({ variablesGroupedByContainerSelected }) => {
  const handleBoxVariableSelectedId = useStore(({ handleBoxVariableSelectedId }) => handleBoxVariableSelectedId)

  const renderBoxInfos = (b, { isSelected = true, onClick }) =>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      color: isSelected ? 'inherit' : 'gray',
      cursor: 'pointer',
      position: 'relative',
      whiteSpace: 'nowrap',
      overflow: "hidden",
      textOverflow: "ellipsis",
    }}
      onClick={onClick}
      className={`form-box-editor-name ${isSelected ? "" : "border-icon-name"}`}
    >
      <FontAwesomeIcon icon={variablesIcons[b.type]} style={{
        fontSize: 16
      }} />
      {b.name}
    </div>

  const onVariableGroupClick = () => {
    handleBoxVariableSelectedId(variablesGroupedByContainerSelected.variableGroupBox.id)
  }

  if (!variablesGroupedByContainerSelected?.variableGroupBox) return null

  return (
    <div style={{
      color: 'gray',
      display: 'flex',
      fontSize: 20,
      fontWeight: 500,
      marginBottom: 16,
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: 8
      }}>
        <FontAwesomeIcon icon={faAngleLeft} />
        {renderBoxInfos(variablesGroupedByContainerSelected.variableGroupBox,
          { isSelected: false, onClick: onVariableGroupClick })}
      </div>
    </div>
  )
}

export default FormBoxEditorName
