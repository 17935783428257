import CascaderCustom from '../../utils/CascaderCustom';
import { variableIsAnImage } from '../RightSide/variablesUtils';
import { message } from 'antd';
import { useStore } from '../../store/store';
import { useTranslation } from 'react-i18next';

const selector = ({ defaultVariablesApi, currentDocument }) => ({
  defaultVariablesApi,
  currentDocument,
});
const ImageCascader = ({ onLoadImage, selectedBox }) => {
  const { t } = useTranslation();
  const { defaultVariablesApi, currentDocument } = useStore(selector);
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Image sélectionnée',
    });
  };
  const cascaderFilter = (inputValue, path) => {
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };
  const formatingData = (variablesApi) => {
    if (!variablesApi) return;
    const {
      variable_groups: variableGroups,
      custom_text_variables: customTextVariables,
      ...data
    } = variablesApi;
    if (currentDocument.type === 'opportunity') {
      return formattingOpportunity(data);
    } else if (currentDocument.type === 'template') {
      let templateData;
      if (typeof data['offer_variables'] === 'undefined') {
        templateData = Object.keys(data)
          .map((group) => {
            return Object.values(data[group])
              .filter((variable) => variableIsAnImage(variable))
              .map((name) => {
                return {
                  key: `${group}.${name}-variable`,
                  label: t(`${group}.${name}`),
                  value: [group, name],
                };
              });
          })
          .flat();
      } else {
        templateData = Object.keys(data['offer_variables'])
          .map((group) => {
            return Object.values(data['offer_variables'][group])
              .filter((variable) => variableIsAnImage(variable))
              .map((name) => {
                return {
                  key: `${group}.${name}-variable`,
                  label: t(`${group}.${name}`),
                  value: [group, name],
                };
              });
          })
          .flat();
      }
      return templateData;
    } else
      return [
        {
          key: 'offerVariables',
          value: 'offerVariables',
          label: "Variables de l'offre",
          children: offerVariables(data.offer_variables),
        },
        {
          key: 'opportunityVariables',
          value: 'opportunityVariables',
          label: 'Variables des opportunités',
          children: opportunitiesVariables(data.opportunities_variables),
        },
      ];
  };
  const offerVariables = (offerVariables) => {
    if (!offerVariables) return [];
    let offerData = Object.keys(offerVariables)
      .map((variableGroup) => {
        return Object.keys(offerVariables[variableGroup])
          .filter((variable) => variableIsAnImage(variable))
          .map((variableName) => {
            return {
              key: `${variableGroup}.${variableName}-variable`,
              label: t(`${variableGroup}.${variableName}`),
              value: [offerVariables, variableGroup, variableName],
            };
          });
      })
      .flat();
    return offerData;
  };
  const formattingOpportunity = (opportunity) => {
    if (!opportunity) return [];
    return Object.keys(opportunity)
      .map((variablegroup) => {
        return Object.keys(opportunity[variablegroup])
          .filter((variable) => variableIsAnImage(variable))
          .map((variableName) => {
            return {
              key: `${variablegroup}.${variableName}`,
              label: t(`${variablegroup}.${variableName}`),
              value: [opportunity, variablegroup, variableName],
            };
          });
      })
      .flat();
  };
  const opportunitiesVariables = (opportunities) => {
    if (!opportunities) return [];
    return opportunities.map((opportunity, idx) => {
      let opportunitydata = opportunity[Object.keys(opportunity).toString()];
      if (!opportunitydata) return [];
      return {
        key: Object.keys(opportunity).toString(),
        label: Object.keys(opportunity).toString(),
        value: Object.keys(opportunity).toString().replace(/ /g, '_'),
        children: Object.keys(opportunitydata)
          .map((variablegroup) => {
            return Object.keys(opportunitydata[variablegroup])
              .filter((variable) => variableIsAnImage(variable))
              .map((variableName) => {
                return {
                  key: `${idx}.${variablegroup}.${variableName}`,
                  label: t(`${variablegroup}.${variableName}`),
                  value: [opportunitydata, variablegroup, variableName],
                };
              });
          })
          .flat(),
      };
    });
  };
  const displayData = formatingData(defaultVariablesApi);
  const sendingData = (e, selectedOptions) => {
    let selectedVariable;
    let adminValue;
    if (currentDocument.type === 'opportunity') {
      selectedVariable = e[0][e[1]][e[2]]?.toString();
      adminValue = `${[e[1]]}.${[e[2]]}`;
      if (!selectedVariable || selectedVariable === '') {
        selectedVariable = `#{${adminValue}}`;
      }
    } else if (currentDocument.type === 'template') {
      selectedVariable = e[0][e[1]]?.toString();
      adminValue = `${[e[0]]}.${[e[1]]}`;
      if (!selectedVariable || selectedVariable === '') {
        selectedVariable = `#{${adminValue}}`;
      }
    } else {
      let tab = e[e.length - 1];
      selectedVariable = tab[0][tab[1]][tab[2]]?.toString();
      if (e[0] === "offerVariables") {
        adminValue = `${[tab[1]]}.${[tab[2]]}`;
      } else {
        adminValue = `${selectedOptions[2].key.split(".")[0]}.${[tab[1]]}.${[tab[2]]}`;
      }
      if (!selectedVariable || selectedVariable === '') {
        selectedVariable = `#{${[tab[1]]}.${[tab[2]]}}`;
      }
    }
    onLoadImage(selectedVariable, adminValue);
  };
  return (
    <>
      {contextHolder}
      <CascaderCustom
        tablesToDisplay={displayData}
        functionToExec={(e, selectedOptions) => {
          sendingData(e, selectedOptions);
          success();
        }}
        placement='bottomLeft'
        placeholderToDisplay='Variables Images'
        showSearch={cascaderFilter}
        selectedBox={selectedBox}

      />
    </>
  );
};
export default ImageCascader;
