import React, { useCallback } from 'react'
import FormBoxName from './FormBoxName'
import { variableStyle } from '../../../utils/styles'
import { useStore } from '../../../store/store'
import { useQueryDocumentSections } from '../../../hooks'
import { ContentState, EditorState } from 'draft-js'

import FormBoxRemark from './FormBoxRemark'
import { convertContentToRawText, decorator } from '../../RichEditor/utils'
import TriggerArray from './TriggerArray'
import { isAVariableBox } from '../../../utils/boxes'
import { getIncrementedName } from '../../../utils/general'
import i18n from '../../../i18n'
import { ItemTypes } from '../../../constants/constants'
import DefaultValueBox from './DefaultValueBox'

const selector =
  ({
    currentDocument,
    currentProposal,
    boxes
  }) => {
    return {
      boxes,
      documentType: currentDocument.type,
      documentId: currentDocument.type === 'template' ? currentDocument.id : currentProposal.id,
    }
  };

const LeftPart = ({ box, onBlur, form }) => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      <FormBoxName box={box} onBlur={onBlur} form={form} />
      <FormBoxRemark box={box} onBlur={onBlur} form={form} />
    </div>
  )
}

const RightPart = ({ box, onBlur, form, variablesGroupedByContainer }) => {
  const {
    documentType,
    documentId,
    boxes
  } = useStore(selector);
  const { data: sectionList } = useQueryDocumentSections({ documentType, documentId })

  const onSelect = useCallback((e) => {
    const section = sectionList.find((s) => s.id === e)
    if (section) {
      form.setFieldValue("name", EditorState.createWithContent(
        ContentState.createFromText(section.name),
        decorator
      ))
    } else {
      const variablesBoxesNames = boxes
        .filter(
          (box) =>
            isAVariableBox(box.type) && !box.from_linked_section
        )
        .map((b) => convertContentToRawText(b.name));
      const newName = getIncrementedName(
        i18n.t(ItemTypes.SECTION_VARIABLE),
        variablesBoxesNames
      );
      form.setFieldValue("name", EditorState.createWithContent(
        ContentState.createFromText(newName),
        decorator
      ))
    }
    onBlur();
  }, [boxes, form, onBlur, sectionList])

  return (
    <div className='variable-editor__middle_section' style={variableStyle["middle"]}>
      <DefaultValueBox box={box} onSelect={onSelect} />
      <TriggerArray box={box} variablesGroupedByContainer={variablesGroupedByContainer} />
    </div >
  )
}

const FormSection = ({ box, onBlur, form, formPart, variablesGroupedByContainer }) => {

  return (
    <>
      {formPart === 'left' && <LeftPart box={box} onBlur={onBlur} form={form} />}
      {formPart === 'right' && <RightPart box={box} onBlur={onBlur} form={form} variablesGroupedByContainer={variablesGroupedByContainer} />}
    </>
  )
}

export default FormSection
