import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { pageWidth } from '../../constants/gridConfig'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Tooltip } from 'antd'
import { ItemTypes } from '../../constants/constants'
import { useTranslation } from 'react-i18next'

const LineBreak = () => {
  const { t } = useTranslation();

  return (
    <div style={{
      height: '30px',
      backgroundColor: '#F5F5F5',
      color: "gray",
      width: pageWidth,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

    }}>
      <div> - - - - - - - - - - - - - - - - - - - - - - - - - - - - - </div>
      <div style={{ width: 10 }} />
      <Tooltip title={t(ItemTypes.LINE_BREAK)}>< FontAwesomeIcon icon={icon({ name: 'turn-down-left', style: 'light' })} style={{ fontSize: 25 }} /></Tooltip>
      <div style={{ width: 10 }} />
      <div>- - - - - - - - - - - - - - - - - - - - - - - - - - - - - -</div>
    </div>
  )
}

export default LineBreak
