import { useStore } from '../../store/store';
import { Select } from 'antd';

const selector = ({ configuration, setConfiguration, summaryConfiguration, setSummaryConfiguration }) => ({
  configuration,
  setConfiguration,
  summaryConfiguration,
  setSummaryConfiguration,
});

const TitleFontSelector = (props) => {
  const { configuration, setConfiguration, summaryConfiguration, setSummaryConfiguration } = useStore(selector);
  return (
    <Select
      showSearch
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      value={props.currentTab !== 'summary' && !!props.currentTab ?
        configuration[props.selectorType].fontFamily !== '' ? configuration[props.selectorType].fontFamily : configuration.fontFamily
        : props.currentTab === 'summary' ?
          summaryConfiguration[props.selectorType].fontFamily !== '' ? summaryConfiguration[props.selectorType].fontFamily
            : summaryConfiguration.fontFamily !== '' ? summaryConfiguration.fontFamily
              : configuration[props.selectorType].fontFamily !== '' ? configuration[props.selectorType].fontFamily
                : configuration.fontFamily
          : 'no type passed'
      }
      style={{ width: '180px' }}
      optionFilterProp='value'
      filterOption={(input, option) =>
        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(e) => {
        if (props.currentTab === 'summary') {
          setSummaryConfiguration({
            ...summaryConfiguration,
            [props.selectorType]: {
              ...summaryConfiguration[props.selectorType],
              fontFamily: e.toString()
            }
          })
        }
        else if (props.currentTab === 'title') {
          setConfiguration({
            ...configuration,
            [props.selectorType]: {
              ...configuration[props.selectorType],
              fontFamily: e.toString()
            }
          })
          if (summaryConfiguration[props.selectorType].fontFamily === '') {
            setSummaryConfiguration({
              ...summaryConfiguration,
              [props.selectorType]: {
                ...summaryConfiguration[props.selectorType],
                fontFamily: e.toString()
              }
            })
          }
        } else if (!!props.currentTab) {
          setConfiguration({
            ...configuration,
            [props.selectorType]: {
              ...configuration[props.selectorType],
              fontFamily: e.toString()
            }
          })
        }
      }}
    >
      {configuration.fontFamilyList.map((font) => (
        <Select.Option key={font.key} value={font.familyName}>
          <div style={{ fontFamily: font.familyName }} >{font.familyName}</div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default TitleFontSelector;
