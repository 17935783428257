import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TableWithDrag from '../../common/TableWithDrag'
import InputHideable from '../../common/InputHideable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ItemTypes, tableColor, tableHeaderSecondaryBgColor, variableHandlingType, variablesIcons } from '../../../constants/constants'
import { Checkbox } from 'antd'
import { useStore } from '../../../store/store'
import { useTranslation } from 'react-i18next'
import { constructVariableIds, mutateHandleLinkedVariable } from '../../../utils/variableGroups'
import { useUpdateVariableGroup } from '../../../hooks'
import { searchCaseDiatricsInsensitive } from '../../../utils/general'
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons'

const sortAndFormatVariablesText = ({ variablesGroupedByContainer, variableGroupId, t, }) => {
  const variablesGroupedVariables = variablesGroupedByContainer.filter((vb) => vb.type === variableHandlingType.VARIABLE);

  const variablesText = variablesGroupedVariables.map((vInfos) => {
    if (vInfos.variableGroupBox) {
      if (vInfos.variableGroupBox.id === variableGroupId) {
        return vInfos.variablesBoxes.map((vb, idx) => ({
          ...vb,
          linked: true,
          position: idx + 1,
          disabled: vInfos.variablesBoxes.length === 1
        }))
      }
      return []
    }

    return vInfos.variablesBoxes.map((vb) => ({
      ...vb,
      canDrag: false,
      canDrop: false,
      dragIconTitle: t('unmovable-not-linked')
    }))
  }).flat();

  variablesText.sort((vA, vB) => {
    if (vA.linked && !vB.linked) {
      return -1
    }
    if (!vA.linked && vB.linked) {
      return 1
    }
    return 0;
  })
  return variablesText
}

const FormGroupArray = ({ box, variablesGroupedByContainer, setCreateItemType }) => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState('')
  const [dataSource, setDataSource] = useState(sortAndFormatVariablesText({
    variablesGroupedByContainer,
    variableGroupId: box.id,
    t
  }));
  const handleBoxVariableSelectedId = useStore(({ handleBoxVariableSelectedId }) => handleBoxVariableSelectedId)
  const variableGroups = useStore(({ variableGroups }) => variableGroups)

  useEffect(() => {

    const newData = sortAndFormatVariablesText({
      variablesGroupedByContainer,
      variableGroupId: box.id,
      t
    })
    if (searchValue) {
      setDataSource(
        newData
          .filter((record) => searchCaseDiatricsInsensitive(searchValue, record.name))
          .map((record) => ({
            ...record,
            canDrag: false,
            dragIconTitle: t('unmovable-search-active')
          })))
    } else {
      setDataSource(sortAndFormatVariablesText({
        variablesGroupedByContainer,
        variableGroupId: box.id,
        t
      }))
    }
  }, [box.id, searchValue, variablesGroupedByContainer, t])

  const updateVariableGroup = useStore(({ updateVariableGroup }) => updateVariableGroup)
  const {
    mutate: updateVariableGroupMutation,
  } = useUpdateVariableGroup();

  const handleOnCheck = useCallback((variableId) => {
    const variableGroup = variableGroups.find(({ id }) => id === box.id)
    const variableIds = constructVariableIds({ variableGroup, variableId })
    updateVariableGroupMutation({ id: box.id, variableIds }, {
      onSuccess: (updatedVariableGroup) => {
        updateVariableGroup(box.id,
          (variableGroup, draft) => {
            mutateHandleLinkedVariable({
              draft,
              variableGroup,
              variableIds: updatedVariableGroup.variable_ids,
              variableId
            })
          })
      }
    })

  }, [box.id, updateVariableGroup, updateVariableGroupMutation, variableGroups])

  const onItemClick = useCallback(() => {
    handleBoxVariableSelectedId()
    setCreateItemType({ type: ItemTypes.CUSTOM_TEXT_VARIABLE, "linked-group": box.id })
  }, [handleBoxVariableSelectedId, setCreateItemType, box.id])

  const columns = useMemo(() => {
    return [
      {
        title: <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 16
        }}>
          {t('name')}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            flex: 1
          }}>
            <InputHideable
              setValue={setSearchValue}
              value={searchValue}
            />
            <FontAwesomeIcon
              onClick={onItemClick}
              style={{
                height: 16,
                color: 'white',
                cursor: 'pointer'
              }} icon={faCirclePlus} />
          </div>
        </div>,
        dataIndex: 'name',
        render: (name, record) => <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 6,
        }}>
          <FontAwesomeIcon icon={variablesIcons[record.type]} />
          {name}
        </div>
        ,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
        onCell: (record) => ({
          onClick: () => {
            if (!record.notUsed) handleBoxVariableSelectedId(record.id)
          },
          style: !record.notUsed ? {
            cursor: 'pointer'
          } : {}
        })
      },
      {
        title: t('linked'),
        dataIndex: 'linked',
        key: 'linked',
        width: 50,
        render: (linked, record) => <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Checkbox checked={linked} disabled={record.disabled} onChange={() => handleOnCheck(record.id)} />
        </div>
        ,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
      }
    ]
  }, [t, searchValue, onItemClick, handleBoxVariableSelectedId, handleOnCheck]);

  const handleOnDrop = useCallback(() => {
    const variableIds = dataSource.filter((r) => r.linked).map(({ id }) => id)
    updateVariableGroupMutation({ id: box.id, variableIds }, {
      onSuccess: (updatedVariableGroup) => {
        updateVariableGroup(box.id,
          (variableGroup) => {
            variableGroup.variableIds = updatedVariableGroup.variable_ids

          })
      }
    })

  }, [box.id, dataSource, updateVariableGroup, updateVariableGroupMutation])

  return (
    <TableWithDrag
      columns={columns}
      dataSource={dataSource}
      setDataSource={setDataSource}
      backgroundColor={tableHeaderSecondaryBgColor}
      color={tableColor}
      onDrop={handleOnDrop}
      scroll={{ y: 'calc(100vh - 54px - 16px - 60px - 37.85px)' }}
    />
  )
}

export default FormGroupArray
