import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/pro-duotone-svg-icons';

const ButtonRefresh = ({ handleOnClickRefresh }) => {
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip
      open={isHovered}
      placement='bottom'
      mouseLeaveDelay='0'
      title={t('update')}
    >
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          width: 'fit-content'
        }}
      >
        <Button shape="circle"
          size={10}
          onClick={handleOnClickRefresh}
          style={{
            width: 25,
            height: 25,
            minWidth: 25,
            minHeight: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          icon={
            <FontAwesomeIcon icon={faArrowsRotate} />
          }
        />
      </div>
    </Tooltip>
  )
}

export default ButtonRefresh
