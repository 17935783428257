import { TableOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import NavbarSelector from '../Layout/NavbarSelector';
import { isATableBox } from '../../utils/boxes';

const selector = ({ selectedBoxId, boxes, masks, updateBox }) => ({
  selectedBox: [...boxes, ...masks.map((m) => m.boxes).flat()].find(b => b.id === selectedBoxId),
  selectedBoxId,
  updateBox,
})

const SelectTableRows = () => {
  const { selectedBoxId, selectedBox: { type, content: { rowsSelectionStart, rowsSelectionEnd, data } }, updateBox } = useStore(selector);
  const { t } = useTranslation();
  const max = data?.length;
  let rowIndexes = Array.from({ length: max }).map((currentElement, i) => ({
    value: i + 1,
    label: i + 1
  }));

  // For selector values are indexes starting at 0, and displayed labels are indexes + 1 (to start at 1)
  const selectRowStart = (selectedValue) => {
    let currentEndValue = rowsSelectionEnd || (data?.length ?? 1);
    if (selectedValue > currentEndValue) return null;
    updateBox(selectedBoxId, (selectedBox) => {
      if (selectedValue === 1) {
        delete selectedBox.content.rowsSelectionStart
      } else {
        selectedBox.content.rowsSelectionStart = selectedValue;
      }
    });
  }

  const selectRowEnd = (selectedValue) => {
    let currentStartValue = rowsSelectionStart || 1;
    if (selectedValue < currentStartValue) return null;
    updateBox(selectedBoxId, (selectedBox) => {
      if (selectedValue >= selectedBox.content.data.length) {
        delete selectedBox.content.rowsSelectionEnd
      } else {
        selectedBox.content.rowsSelectionEnd = selectedValue;
      }
    });
  }


  return (
    <div style={{ display: 'flex' }}>
      <NavbarSelector
        key="tableStartLineIndex"
        tooltipContent={t('start-line')}
        disabled={!isATableBox(type)}
        suffixIcon={<TableOutlined />}
        onSelect={selectRowStart}
        selectedValue={rowsSelectionStart || 1}
        options={rowIndexes}
        width={80}
      />
      <NavbarSelector
        key="tableEndLineIndex"
        tooltipContent={t('end-line')}
        disabled={!isATableBox(type)}
        suffixIcon={<TableOutlined />}
        onSelect={selectRowEnd}
        selectedValue={rowsSelectionEnd || (data?.length ?? 1)}
        options={rowIndexes}
        width={80}
      />
    </div>
  );
};

export default SelectTableRows;
