import React, { useMemo } from 'react';
import LoaderGIF from './images/Smart4Loader.gif';
import './loader.css';

export default function Loader({ style }) {
  const usedStyle = useMemo(() => {
    if (style) return style;
    return {
      position: 'sticky',
      height: 'calc(100vh - 60px)',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  }, [style]);

  return (
    <div style={usedStyle}>
      <img
        className='Loader'
        src={LoaderGIF}
        alt={LoaderGIF}
        style={{ width: '30vw' }}
      />
    </div>
  );
}
