import React, { useCallback } from 'react'
import FloatyMenu from '../../common/FloatyMenu'
import { ItemTypes, MASKS_TYPE } from '../../../constants/constants';
import { useStore } from '../../../store/store';
import { newQuestionBox, } from '../../../utils/boxes';

const options = [
  { id: 1, label: 'Question' },
  { id: 2, label: 'Variable texte' },
  { id: 3, label: 'Groupe de variables' },
];

const FormCreateItemButton = ({ menuIsOpen, setMenuIsOpen, getPositions, setCreateItemType }) => {
  const handleBoxVariableSelectedId = useStore(({ handleBoxVariableSelectedId }) => handleBoxVariableSelectedId)
  const gridConfiguration = useStore(({ gridConfiguration }) => gridConfiguration)
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const configuration = useStore(({ configuration }) => configuration)
  const landscape = useStore(({ landscape }) => landscape)

  const addBox = useStore(({ addBox }) => addBox)


  const onItemClick = useCallback((e, { id }) => {
    setMenuIsOpen(false)
    handleBoxVariableSelectedId()
    if (id === 1) {
      const newBox = newQuestionBox({
        gridConfiguration,
        landscape,
        configuration,
        header,
        footer,
      })
      addBox(newBox, (box, draft) => {
        draft.boxVariableSelectedId = box.id
      })
    } else if (id === 2) {
      setCreateItemType({ type: ItemTypes.CUSTOM_TEXT_VARIABLE })
    } else {
      setCreateItemType({ type: ItemTypes.VARIABLE_GROUP })
    }
  }, [setMenuIsOpen, handleBoxVariableSelectedId, gridConfiguration, landscape, configuration, header, footer, addBox, setCreateItemType])

  const { top, left } = getPositions()

  return (
    <FloatyMenu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} onItemClick={onItemClick} left={left + 8} top={top} options={options} />
  )
}

export default FormCreateItemButton
