import React from 'react';
import ProposalApp from './proposalApp';
import PdfApp from './pdfApp';

const AppSelector = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const isPdfGen = !!searchParams.get('generate_pdf');

  return isPdfGen ? <PdfApp /> : <ProposalApp />;
};

export default AppSelector;
