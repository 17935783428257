import qs from 'query-string';
import jwt_decode from 'jwt-decode';

import { useEffect } from 'react';

export const isAnImageUrl = (str) => {
  if (!str || (typeof str !== 'string' && typeof str !== 'number')) {
    return false;
  }
  const strToTest = typeof str === 'number' ? str.toString() : str;
  return strToTest?.toLowerCase()?.match?.(/\.(jpeg|jpg|gif|png)$/);
};

export const useOutsideClickHandler = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, callback]);
};

export const searchUrlChangedEvent = 'newSearchUrl';

export const cleanStrDiacritics = (str) =>
  str.normalize('NFD').replace(/\p{Diacritic}/gu, '');

export const getUrlParams = (name) => {
  const urlParams = new URLSearchParams(window.location.search);

  if (!urlParams) return null;

  const param = urlParams.get(name);
  return param;
};

const paramsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    if (key !== 'jwt') {
      result[key] = Number(value);
    }
  }
  return result;
};

export const getUrlParamsObject = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (!urlParams) return {};
  return paramsToObject(urlParams);
};

export const getDocumentFromParams = (params = {}) => {
  const { oid, ofid, tid } = params;
  const documentType = oid ? 'opportunity' : ofid ? 'offer' : 'template';
  return {
    id: oid ?? ofid ?? tid,
    type: documentType,
  };
};

export const updateUrl = (params) => {
  const search = qs.parse(window.location.search);

  Object.keys(params).forEach((cur) => {
    if (params[cur] === null) {
      delete search[cur];
      delete params[cur];
    }
  });

  const url = qs.stringifyUrl({
    url: window.location.origin,
    query: { ...search, ...params },
  });
  window.history.pushState({}, null, url);
  let searchUrlChangeEvent = new Event(searchUrlChangedEvent);
  window.dispatchEvent(searchUrlChangeEvent);
};

export const emptyUrl = () => {
  const url = qs.stringifyUrl({
    url: window.location.origin,
    query: null,
  });
  window.history.pushState({}, null, url);
};

export const getJwt = () => {
  const jwt = getUrlParams('jwt') ?? window.localStorage.getItem('jwt');

  if (!jwt) return;

  window.localStorage.setItem('jwt', jwt);
  if (getUrlParams('jwt')) updateUrl({ jwt: null });
  const user = jwt_decode(jwt);

  if (user.role === 'setup' && getUrlParamsObject().oid) {
    updateUrl({ oid: null });
  }

  return {
    ...user,
    isAdmin:
      user.role === 'admin' ||
      user.role === 'setup' ||
      user.role === 'superadmin',
  };
};

export const isAnUrl = (str) => {
  const matchPattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
  return matchPattern.test(str);
};
