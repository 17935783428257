import React from 'react'
import FormBoxName from './FormBoxName'
import { variableStyle } from '../../../utils/styles'
import FormBoxRemark from './FormBoxRemark'

const LeftPart = ({ box, onBlur, form }) => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      <FormBoxName box={box} onBlur={onBlur} form={form} />
      <FormBoxRemark box={box} onBlur={onBlur} form={form} />
    </div>
  )
}

const RightPart = () => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
    </div>
  )
}

const FormImage = ({ box, onBlur, form, formPart }) => {
  return (
    <>
      {formPart === 'left' && <LeftPart box={box} onBlur={onBlur} form={form} />}
      {formPart === 'right' && <RightPart />}
    </>
  )
}

export default FormImage
