// Axios
import { instance } from './axios.instance';

export const documentBaseUrl = ({ id, type }) => {
  const idPart = id ? `${id}/` : '';
  if (type.toLowerCase() === 'offer') {
    return `/proposals/offers/` + idPart;
  } else if (type.toLowerCase() === 'opportunity') {
    return `/proposals/opportunities/` + idPart;
  } else if (type.toLowerCase() === 'preview') {
    return `/proposals/templates/${idPart}/previews`;
  } else if (type.toLowerCase() === 'proposalpreview') {
    return `/proposal/${id}/preview`;
  } else {
    return `/proposals/templates/` + idPart;
  }
};

export const getVariables = async ({ id, type }) => {
  const url = documentBaseUrl({ id, type }) + 'variables';
  try {
    const res = await instance.get(url);
    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`GET proposal variables status code ${res.status}`);
  } catch (error) {}
};

export const getTable = async ({ id, type }, tableName = null) => {
  const tableToFetch = tableName ? `/${tableName.toLowerCase()}` : '';
  const url = documentBaseUrl({ id, type }) + 'tables' + tableToFetch;
  try {
    const res = await instance.get(url);
    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`GET tables status code ${res.status}`);
  } catch (error) {
    console.error(error);
  }
};

export const getTables = async (documentType, id) => {
  const url = documentBaseUrl({ id: id, type: documentType }) + 'tables';
  try {
    const res = await instance.get(url);
    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`GET tables status code ${res.status}`);
  } catch (error) {
    console.error(error);
  }
};

export const postImage = async (id, type, { img, filename }) => {
  let url = '';
  if (type === 'template') url = `/proposals/templates/${id}/images`;
  else url = `/proposal/${id}/image`;
  try {
    const res = await instance.post(url, {
      filename,
      content: img,
    });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const postPreview = async (id, type, { filename, content }) => {
  let previewType;
  if (type === 'opportunity' || type === 'offer') {
    previewType = 'proposalPreview';
  } else {
    previewType = 'preview';
  }
  let url = documentBaseUrl({ id: id, type: previewType });
  try {
    const res = await instance.post(url, {
      filename,
      content: /base64,(.+)/.exec(content[0].src)[1],
    });
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

export const getImages = async (currentDocument) => {
  const url = documentBaseUrl(currentDocument) + 'images';

  try {
    const res = await instance.get(url);
    return res.data;
  } catch (e) {}
};
