import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translations files
import translationEN from './language/en/translation.json';
import translationFR from './language/fr/translation.json';

// The translations
const resources = {
	en: {
		translation: translationEN,
	},
	fr: {
		translation: translationFR,
	},
};

i18n
	.use(initReactI18next) // Passes i18n down to react-i18next
	.init({
		resources,
		lng: 'fr',

		keySeparator: false, // We do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // React already safes from xss
		},
	});

export default i18n;
