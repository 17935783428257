/* global twemoji */
import React, { useMemo } from 'react'
import './DraftEmoji.less'
import { useStore } from '../../store/store';

const getFontSizeFromInlineStyles = (inlineStyles) => {
  let fontSize = null;

  inlineStyles.forEach((style) => {
    const match = style.match(/FONT_SIZE_(\d+)/);
    if (match) {
      fontSize = parseInt(match[1], 10);
    }
  });

  return fontSize;
}
const DraftEmoji = (props) => {
  const { blockKey, contentState, start, end } = props;
  const fontSize = useStore(({ configuration }) => configuration.fontSize)
  const fromPdf = useStore((state) => state.fromPdf);


  const block = contentState.getBlockForKey(blockKey);

  const blockFontSize = useMemo(() => {

    for (let i = start; i < end; i++) {
      const inlineStyle = block.getInlineStyleAt(i);
      return getFontSizeFromInlineStyles(inlineStyle) ?? fontSize
    }
  }, [block, end, fontSize, start])

  const emojiUrl = useMemo(() => {
    const twemojiElem = twemoji.parse(props.decoratedText, {
      folder: 'svg',
      ext: '.svg',
      base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/'
    })
    // Create a temporary DOM element to extract the URL
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = twemojiElem;

    // Find the image element and extract the `src` attribute
    const twemojiImg = tempDiv.querySelector('img'); // Find the first img element
    const emojiUrl = twemojiImg ? twemojiImg.src : null;

    return `url(${emojiUrl})`
  }, [props.decoratedText])

  return (
    <>
      {!fromPdf && <span
        style={{
          backgroundImage: emojiUrl,
          backgroundSize: "1em 1em",
          fontSize: blockFontSize,
          backgroundPosition: "center center",
          backgroundRepeat: 'no-repeat',
          "-webkit-text-fill-color": 'transparent'
        }}
      >
        {props.children}
      </span>}
      {fromPdf && <span
        style={{
          fontSize: blockFontSize,
        }}
        dangerouslySetInnerHTML={{
          __html: twemoji.parse(props.decoratedText, {
            folder: 'svg',
            ext: '.svg',
            base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/'
          })
        }}
      />}
    </>
  );
};

export default DraftEmoji
