import {
  DashOutlined,
  MinusOutlined,
  SmallDashOutlined,
} from '@ant-design/icons';
// ANT Design
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
// Common
import Line from './Line';
import './Line.css';
import { NavbarInputNumber } from '../Layout/NavbarInputNumber';
import { NavbarButton } from '../Layout/NavbarButton';

// Store selector
const selector =
  (boxId) =>
    ({ removeBox, updateBox, configuration, selectedBoxId }) => ({
      removeBox,
      updateBox,
      colors: configuration.colors,
      isSelected: boxId === selectedBoxId,
    });

export default React.memo(({ box }) => {
  const { isSelected, updateBox } = useStore(selector(box.id));

  useEffect(() => {
    updateBox(box.id, (box) => {
      if (box.height > 20 && box.width <= 20) {
        const { width: w, height: h } = box;
        box.width = h;
        box.height = w;
        box.left = box.left + (w - h) / 2;
        box.top = box.top + (h - w) / 2;
        if (box.rotation) {
          box.rotation += 90;
        } else {
          box.rotation = 90;
        }
      }
      if (box.content && box.content.height) {
        box.lineHeight = box.content.height;
        box.color = box.content.color;
        box.style = box.content.style;
        box.side = box.content.side;
        delete box.content;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        style={{
          width: box.width,
          height: box.height,
          overflow: 'hidden',
        }}>
        <Line
          color={box.color}
          lineHeight={box.lineHeight || 10}
          style={box.style}
        />
      </div>
      {isSelected
        ? ReactDOM.createPortal(
          <LineComponent selectedBox={box} />,
          document.getElementById('ComponentPortal')
        )
        : null}
    </>
  );
});

const LineComponent = ({ selectedBox }) => {
  const { t } = useTranslation();
  const { updateBox } = useStore(selector(selectedBox?.id));

  const lineButtons = selectedBox?.type === 'line';

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <NavbarButton
        tooltipContent={t('solid')}
        onClick={() => {
          updateBox(selectedBox.id, (selectedBox) => {
            selectedBox.style = 'solid';
          });
        }}
        disabled={!lineButtons}
        icon={<MinusOutlined />}
      />
      <NavbarButton
        tooltipContent={t('dashed')}
        onClick={() => {
          updateBox(selectedBox.id, (selectedBox) => {
            selectedBox.style = 'dashed';
          });
        }}
        disabled={!lineButtons}
        icon={<DashOutlined />}
      />
      <NavbarButton
        tooltipContent={t('dotted')}
        onClick={() => {
          updateBox(selectedBox.id, (selectedBox) => {
            selectedBox.style = 'dotted';
          });
        }}
        disabled={!lineButtons}
        icon={<SmallDashOutlined />}
      />
      <NavbarInputNumber
        min={1}
        max={20}
        disabled={!lineButtons}
        tooltipContent={t('line-height')}
        value={selectedBox?.lineHeight}
        suffix='px'
        onChange={(v) =>
          updateBox(selectedBox.id, (selectedBox) => {
            selectedBox.lineHeight = v;
            selectedBox.height = v;
          })
        }
      />
    </div>
  );
};
