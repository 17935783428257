import { ContentState, EditorState } from 'draft-js';
import { defaultItemValues, ItemTypes, uuid } from '../constants/constants';
import { mutateFormatSectionBox } from './sections';
import { mutateInjectVariablesInDocument } from './variables';
import {
  convertContentToRawText,
  decorator,
} from '../components/RichEditor/utils';
import { isAVariableBox } from './boxes';
import i18n from '../i18n';
import { getIncrementedName, transformDiatricsInsensitive } from './general';
import { isEqual, omit } from 'lodash';

export const newTrigger = ({
  triggerType = null,
  triggerId = null,
  value = null,
  result = {},
}) => {
  const id = uuid();
  return {
    id,
    value,
    result,
    triggerType,
    triggerId,
  };
};

export const mutateAddTrigger = ({
  trigger,
  draft,
  parentId,
  index = null,
}) => {
  if (!draft.triggers[parentId]) {
    draft.triggers[parentId] = [];
  }
  if (index !== null) {
    draft.triggers[parentId].splice(index + 1, 0, trigger);
  } else {
    draft.triggers[parentId].push(trigger);
  }
};

export const mutateInitializeResult = ({ parent, trigger }) => {
  if (parent.type === ItemTypes.TEXT_VARIABLE)
    trigger.result = {
      content: defaultItemValues[ItemTypes.TEXT_VARIABLE].content,
    };
  if (
    parent.type === ItemTypes.CHECKBOXS_VARIABLE ||
    parent.type === ItemTypes.QUESTION
  )
    trigger.result = {
      checkboxs: parent.checkboxs.map((c) => ({
        id: c.id,
        checked: false,
      })),
    };
  if (parent.type === ItemTypes.SELECTOR_VARIABLE)
    trigger.result = {
      options: parent.options.map((c) => ({
        id: c.id,
        selected: false,
      })),
    };
};

export const mutateUpdateTrigger = ({
  draft,
  parentId,
  id,
  type,
  triggerId,
  value,
  resultCallback,
  triggerIdFromSection,
}) => {
  const trigger = draft.triggers[parentId].find(({ id: tId }) => tId === id);
  if (type) {
    trigger.value = null;
    trigger.triggerId = null;
    trigger.triggerIdFromSection = null;
    trigger.triggerType = type;
  }
  if (triggerId) {
    trigger.value = null;
    trigger.triggerId = triggerId;
    trigger.triggerIdFromSection = triggerIdFromSection;
  }
  if (value) {
    trigger.value = value;
  }
  if (resultCallback) {
    resultCallback(trigger.result, trigger);
    if (trigger.result.checkboxs?.length > 0) {
      trigger.result.checkboxs = trigger.result.checkboxs.map(
        ({ id, checked }) => ({
          id,
          checked,
        })
      );
    }
    if (trigger.result.options?.length > 0) {
      trigger.result.options = trigger.result.options.map(
        ({ id, selected }) => ({
          id,
          selected,
        })
      );
    }
  }
};

export const mutateInjectTriggersSection = ({
  sectionContent,
  draft,
  boxesToAdd,
  linked_section,
  dateTimestamp,
  fromSectionId,
}) => {
  const sectionTriggers = sectionContent.triggers;
  Object.keys(sectionTriggers).forEach((key) => {
    const parentBox = boxesToAdd.find((b) => b.boxSectionId === key);
    if (parentBox) {
      sectionTriggers[key].forEach((trigger) => {
        mutateFormatSectionBox({ box: trigger, type: parentBox.type, draft });
        const triggerBox = boxesToAdd.find(
          (b) => b.boxSectionId === trigger.triggerId
        );
        if (triggerBox) {
          trigger.triggerId = triggerBox.id;
        }
        if (linked_section) {
          trigger.from_linked_section = true;
          trigger.fromSectionId = fromSectionId;
          trigger.dateTimestamp = dateTimestamp;
        }
        mutateAddTrigger({ trigger, draft, parentId: parentBox.id });
      });
    } else {
      sectionTriggers[key].forEach((trigger) => {
        const triggerBox = boxesToAdd.find(
          (b) => b.boxSectionId === trigger.triggerId
        );
        if (triggerBox) {
          trigger.triggerId = triggerBox.id;
        }
        if (linked_section) {
          trigger.from_linked_section = true;
          trigger.fromSectionId = fromSectionId;
          trigger.dateTimestamp = dateTimestamp;
        }
        mutateAddTrigger({ trigger, draft, parentId: key });
      });
    }
  });
};
export const getTriggerParent = ({ trigger, draft }) => {
  if (
    trigger.triggerType === 'optional-zone' ||
    trigger.triggerType === 'zone-name'
  )
    return { type: trigger.triggerType };
  if (!trigger.triggerType || !trigger.triggerId) return false;
  if (trigger.triggerType === ItemTypes.CUSTOM_TEXT_VARIABLE)
    return draft.variables[trigger.triggerId];
  return draft.boxes.find((b) => b.id === trigger.triggerId);
};

export const getParent = ({ key, draft }) => {
  const parentBox = draft.boxes.find((b) => b.id === key);
  if (parentBox) return parentBox;
  return draft.variables[key];
};

const checkActivationOnSelectionTrigger = ({
  trigger,
  triggerParent,
  arrayKey,
  selectionKey,
}) => {
  const value = Array.isArray(trigger.value) ? trigger.value : [trigger.value];
  const triggerParentValue = triggerParent[arrayKey]
    .filter((item) => item[selectionKey])
    .map(({ id }) => id);
  if (value.every((v) => triggerParentValue.includes(v))) {
    return true;
  }
  return false;
};

const triggerIsActivated = ({
  triggerParent,
  trigger,
  hasOpportunityOption,
  zoneNames,
}) => {
  if (triggerParent?.type === 'optional-zone') {
    return hasOpportunityOption;
  }
  if (triggerParent?.type === 'zone-name') {
    return zoneNames.some(
      (name) =>
        transformDiatricsInsensitive(name.replace(/\s/g, '')) ===
        transformDiatricsInsensitive(trigger.value.replace(/\s/g, ''))
    );
  }

  if (!triggerParent || !trigger.value) return false;

  if (
    trigger.triggerType === ItemTypes.CHECKBOXS_VARIABLE ||
    trigger.triggerType === ItemTypes.QUESTION
  ) {
    return checkActivationOnSelectionTrigger({
      trigger,
      triggerParent,
      arrayKey: 'checkboxs',
      selectionKey: 'checked',
    });
  }

  if (trigger.triggerType === ItemTypes.SELECTOR_VARIABLE) {
    return checkActivationOnSelectionTrigger({
      trigger,
      triggerParent,
      arrayKey: 'options',
      selectionKey: 'selected',
    });
  }

  if (trigger.triggerType === ItemTypes.CUSTOM_TEXT_VARIABLE) {
    return triggerParent.value === trigger.value;
  }

  if (trigger.triggerType === ItemTypes.SECTION_VARIABLE) {
    return triggerParent.sectionId === trigger.value;
  }
  return false;
};

const mutateTriggerResultOnSelectionParent = ({
  parent,
  trigger,
  hasAppliedFullResult,
  arrayKey,
  selectionKey,
}) => {
  for (const item of trigger.result[arrayKey]) {
    const parentItem = parent[arrayKey].find(({ id: cId }) => cId === item.id);
    if (item[selectionKey] && parentItem && !hasAppliedFullResult) {
      parentItem[selectionKey] = true;
      if (
        parent[arrayKey].filter((c) => c[selectionKey]).length ===
        parent.maxNumberOfAnwser
      ) {
        return true;
      }
    }
  }
  return false;
};

const mutateTriggerResultOnParent = ({
  trigger,
  parent,
  hasAppliedFullResult,
  draft,
  key,
}) => {
  let newHasAppliedFullResult = hasAppliedFullResult;
  const oldParent = { ...parent };
  delete parent.hideField;
  Object.entries(trigger.result).forEach(([resultKey, value]) => {
    if (resultKey === 'checkboxs') {
      newHasAppliedFullResult = mutateTriggerResultOnSelectionParent({
        parent,
        trigger,
        hasAppliedFullResult,
        arrayKey: resultKey,
        selectionKey: 'checked',
      });
    } else if (resultKey === 'options') {
      newHasAppliedFullResult = mutateTriggerResultOnSelectionParent({
        parent,
        trigger,
        hasAppliedFullResult,
        arrayKey: resultKey,
        selectionKey: 'selected',
      });
    } else parent[resultKey] = value;
  });

  if (
    parent.type !== ItemTypes.CHECKBOXS_VARIABLE &&
    parent.type !== ItemTypes.QUESTION &&
    parent.type !== ItemTypes.SELECTOR_VARIABLE
  ) {
    newHasAppliedFullResult = true;
  }

  if (
    parent.type === ItemTypes.SECTION_VARIABLE &&
    oldParent.sectionId !== parent.sectionId
  ) {
    const newSection = draft.sections.find(
      (s) => s.id === trigger.result.sectionId
    );
    if (newSection) {
      parent.name = EditorState.createWithContent(
        ContentState.createFromText(newSection.name),
        decorator
      );
    } else {
      const variablesBoxesNames = draft.boxes
        .filter((box) => isAVariableBox(box.type))
        .map((b) => convertContentToRawText(b.name));
      const newName = getIncrementedName(
        i18n.t(parent.type),
        variablesBoxesNames
      );
      parent.name = EditorState.createWithContent(
        ContentState.createFromText(newName),
        decorator
      );
    }
    parent.answer = parent.questions.find(({ label }) => label === 'Oui').id;

    draft.sectionsToActivate.push({
      id: parent.id,
      sectionId: parent.sectionId,
      noId: parent.questions.find(({ label }) => label === 'Non').id,
    });
  }

  // If no type is set, it means that the parent is a custom text variable
  if (!parent.type) {
    mutateInjectVariablesInDocument({
      variables: draft.variables,
      variable: draft.variables[key],
      variableKey: key,
      draft,
    });
  }
  return newHasAppliedFullResult;
};

const mutateHideParent = ({ trigger, parent }) => {
  parent.hideField = true;
};

const mutateReinitializeParent = ({ parent }) => {
  if (
    parent.type === ItemTypes.CHECKBOXS_VARIABLE ||
    parent.type === ItemTypes.QUESTION
  ) {
    parent.checkboxs = parent.checkboxs.map((c) => ({
      ...c,
      checked: false,
    }));
  }
  if (parent.type === ItemTypes.SELECTOR_VARIABLE) {
    parent.options = parent.options.map((c) => ({
      ...c,
      selected: false,
    }));
  }
};

export const mutateExecuteParentTriggers = ({
  draft,
  key,
  searchRecursive = false,
  parentSearched = [],
}) => {
  let parent = getParent({ key, draft });
  let parentHasActivatedTrigger = false;
  let hasAppliedFullResult = false;
  if (!parent) return;
  const oldParent = { ...parent };
  const triggerArray = draft.triggers[key];
  if (!triggerArray) return;
  for (const trigger of triggerArray) {
    const triggerParent = getTriggerParent({ trigger, draft });
    if (
      !triggerParent ||
      (triggerParent.hideField &&
        triggerParent.type !== ItemTypes.SECTION_VARIABLE) ||
      (triggerParent.type !== 'optional-zone' && !trigger.value)
    )
      continue;
    if (
      triggerIsActivated({
        triggerParent,
        trigger,
        hasOpportunityOption: draft.hasOpportunityOption,
        zoneNames: draft.zoneNames,
      })
    ) {
      if (!parentHasActivatedTrigger) {
        mutateReinitializeParent({ parent });
        parentHasActivatedTrigger = true;
        parent.isActivatedByTrigger = true;
      }
      if (!trigger.result.hideField) {
        hasAppliedFullResult = mutateTriggerResultOnParent({
          trigger,
          parent,
          hasAppliedFullResult,
          draft,
          key,
        });
      } else {
        mutateHideParent({ trigger, parent });
        hasAppliedFullResult = true;
      }
    }
    if (hasAppliedFullResult) break;
  }
  if (parentHasActivatedTrigger && searchRecursive) {
    mutateSearchAndLaunchTrigger({
      triggerId: key,
      draft,
      parentSearched: key ? parentSearched : [...parentSearched, key],
    });
  }
  if (
    !parentHasActivatedTrigger &&
    parent.defaultValue &&
    parent.isActivatedByTrigger
  ) {
    delete parent.isActivatedByTrigger;
    parentHasActivatedTrigger = true;
    mutateReinitializeParent({ parent });
    mutateTriggerResultOnParent({
      trigger: { result: parent.defaultValue },
      parent,
      hasAppliedFullResult,
      draft,
      key,
    });
    if (searchRecursive) {
      mutateSearchAndLaunchTrigger({
        triggerId: key,
        draft,
        parentSearched: key ? parentSearched : [...parentSearched, key],
      });
    }
  }
  if (parentHasActivatedTrigger) {
    if (
      !isEqual(
        omit(oldParent, ['isActivatedByTrigger']),
        omit(parent, ['isActivatedByTrigger'])
      ) &&
      !draft.itemsChangedByTriggers.some(({ key: iKey }) => key === iKey)
    ) {
      draft.itemsChangedByTriggers.push({ key });
    }
  }
};

export const mutateSearchAndLaunchTrigger = ({
  triggerId,
  draft,
  parentSearched = [triggerId],
}) => {
  const triggersParentKeys = [];
  Object.entries(draft.triggers).forEach(([key, triggerArray]) => {
    if (triggerArray.some(({ triggerId: id }) => id === triggerId)) {
      triggersParentKeys.push(key);
    }
  });
  for (const key of triggersParentKeys) {
    if (parentSearched.includes(key)) {
      break;
    }
    mutateExecuteParentTriggers({
      draft,
      key,
      searchRecursive: true,
      parentSearched,
    });
  }
};

export const mutateRemoveTriggersWithTriggerId = ({ triggerId, draft }) => {
  Object.entries(draft.triggers).forEach(([key, triggerArray]) => {
    const triggerIdx = triggerArray.findIndex(
      ({ triggerId: id }) => id === triggerId
    );
    if (triggerIdx > -1) {
      draft.triggers[key].splice(triggerIdx, 1);
    }
  });
  delete draft.triggers[triggerId];
};

export const mutateRemoveDeletedSectionTriggers = ({ triggers, sections }) => {
  Object.entries(triggers).forEach(([key, triggerArray]) => {
    const idToRemove = [];
    triggerArray.forEach((trigger) => {
      if (trigger.triggerType === ItemTypes.SECTION_VARIABLE) {
        if (
          trigger.value &&
          !sections.some((section) => section.id === trigger.value)
        )
          idToRemove.push(trigger.id);
      }
      triggers[key] = triggers[key].filter(
        ({ id }) => !idToRemove.includes(id)
      );
    });
  });
  return triggers;
};
