import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { useStore } from '../../store/store';

const selector = () => (state) => ({
  user: state?.user,
});

export const UserInfosComponent = () => {
  const { user } = useStore(selector());

  return (
    <Avatar
      style={{
        height: '36px',
        width: '36px',
      }}
      size='default'
      icon={<UserOutlined />}
      src={user.photo}
    />
  );
};
