import { FormatPainterOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { selectorMaxWidth, selectorMinWidth } from '../../constants/gridConfig';
import { useStore } from '../../store/store';
const { Option } = Select;

const ColorSelector = ({
  forcedValue,
  onChange,
  defineOpen = false,
  disableTransparent = false,
  className,
  colorPlaceHolder,
  disabled,
  style,
}) => {
  const { t } = useTranslation();
  const { configuration } = useStore(({ configuration }) => ({
    configuration,
  }));
  const displayedColors = disableTransparent
    ? configuration.colors.filter((color) => color.label !== 'transparent')
    : configuration.colors;
  return (
    <Select
      style={{
        ...style,
        maxWidth: selectorMaxWidth,
        minWidth: selectorMinWidth,
      }}
      value={forcedValue}
      placeholder={forcedValue ? null : t('color')}
      suffixIcon={<FormatPainterOutlined />}
      disabled={disabled}
      onChange={onChange}
      defaultOpen={defineOpen}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      {displayedColors.map((color) => (
        <Option key={color.label} value={color.label}>
          <div
            style={{
              height: '100%',
              width: '100%',
              background:
                color.label === 'transparent'
                  ? 'linear-gradient(to top left, #FFF calc(50% - 2px), #F00, #FFF calc(50% + 2px))'
                  : color.style,
            }}
          />
        </Option>
      ))}
    </Select>
  );
};

export default ColorSelector;
