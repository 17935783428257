import React, { useCallback } from 'react'
import { useStore } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { colId, rowId } from '../../constants/constants';
import { DEFAULT_COLUMN_WIDTH, MIN_COLUMN_WIDTH } from '.';
import { Modal } from 'antd';
import { fetchTableData } from '../../utils/table';
import ButtonTableNavbar from './PopMenu';
import SelectTableRows from './SelectTableRows';
import { NavbarButton } from '../Layout/NavbarButton';
import { CaretDownOutlined, InsertRowBelowOutlined, InsertRowRightOutlined } from '@ant-design/icons';
import { iconButtonStyle } from '../../utils/styles';
import NavbarSelector from '../Layout/NavbarSelector';
import TableCascader from './TableCascader';
import VariableSwitcher from '../common/VariableSwitcher';

const selector = (box) => (state) => {
  return {
    currentDocument: state.currentDocument,
    updateBox: state.updateBox,
    isAdmin: state.user?.isAdmin,
    fetchTable: state.fetchTable,
    tableStyles: state.configuration.tableStyles,
  };
};

const { confirm } = Modal;

const NavbarTable = ({ selectedBox, menuPosition, setMenuPosition, tableStyle }) => {
  const boxId = selectedBox?.id;
  const {
    currentDocument,
    updateBox,
    fetchTable,
    isAdmin,
    tableStyles,
  } = useStore(selector(selectedBox));
  const { t } = useTranslation();

  const handleNavbarClick = ({ action, value }, key) => {
    switch (action) {
      case 'remove':
        updateBox(boxId, (box) => {
          const targetedElement = box.content[menuPosition.type].find((c) => key === c.key);
          if (targetedElement) {
            if (menuPosition.type === 'columns' && box.content.columns.length > 1) {
              const columnKey = targetedElement.key;
              box.content.data.forEach((row) => delete row[columnKey]);
              const removedColumnWidth = targetedElement.width * -1;
              if (box.content[menuPosition.type].length > 1) {
                const index = box.content[menuPosition.type].findIndex((c) => c.key === key);
                box.content[menuPosition.type].splice(index, 1);
              }
              box.content.columns.forEach((col) => {
                const toAdd = (-removedColumnWidth) / box.content.columns.length;
                const newColumnWidth = col.width + toAdd;
                col.width = newColumnWidth;
              });
            } else if (box.content[menuPosition.type].length > 1) {
              const index = box.content[menuPosition.type].findIndex((c) => c.key === key);
              box.content[menuPosition.type].splice(index, 1);
            }
          }
        });
        setMenuPosition({ type: null, key: null });
        break;
      case 'alignment':
        updateBox(boxId, (box) => {
          const targetedElement = box.content[menuPosition.type].find((c) => c.key === key);
          if (targetedElement) {
            targetedElement.textAlign = value;
          }
        });
        break;
      case 'type':
        updateBox(boxId, (box) => {
          const targetedElement = box.content[menuPosition.type].find((c) => c.key === key);
          if (targetedElement) {
            targetedElement.type = value;
          }
        });
        break;
      default:
        break;
    }
  };

  const handleAddRow = useCallback(() => {
    const nextRow = Object.fromEntries(selectedBox.content.columns.map((col) => [col.key, '']));
    nextRow.key = rowId();
    updateBox(boxId, (selectedBox) => {
      selectedBox.content.data.push(nextRow);
    });
  }, [boxId, selectedBox, updateBox]);

  const handleAddCol = () => {
    const colName =
      'Col ' + String.fromCharCode(65 + selectedBox.content.columns.length);

    updateBox(boxId, (box) => {
      if (box.content.columns.length !== 0) {
        const newColumnWidth = DEFAULT_COLUMN_WIDTH;
        const maxSubstractable = box.content.columns.reduce((acc, col) => acc + col.width - MIN_COLUMN_WIDTH, 0);
        const canAddColumn = maxSubstractable > newColumnWidth;
        if (canAddColumn) {
          const boxCurrentWidth = box.content.columns.reduce((acc, col) => acc + col.width, 0);
          box.content.columns.forEach((col) => {
            const colProportion = (col.width - MIN_COLUMN_WIDTH) / (boxCurrentWidth - (box.content.columns.length * MIN_COLUMN_WIDTH));
            const toRemove = newColumnWidth * colProportion;
            const newWidth = col.width - toRemove;
            if (newWidth >= MIN_COLUMN_WIDTH) {
              col.width = newWidth;
            } else {
              console.error('Not enough space to add column')
            }
          });
          box.content.columns.push({
            key: colId(),
            title: colName,
            width: newColumnWidth,
          });
        } else {
          confirm({
            title: t('table.cantAddColumn'),
            okText: 'Ok',
          });
        }
      }
    });
  };

  const handleChangeTable = (tableName, proposal) => {
    if (isAdmin && currentDocument.type === 'template') {
      updateBox(selectedBox.id, (boxToUpdate) => {
        boxToUpdate.tableName = tableName;
        boxToUpdate.content.columns = [];
        boxToUpdate.content.data = [];
        boxToUpdate.proposal = proposal;
      });
    } else {
      fetchTableData({
        fetchStoreFunc: fetchTable,
        updateBoxFunc: updateBox,
        box: {
          ...selectedBox,
          tableName,
          proposal
        }
      });
    }
  };


  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <VariableSwitcher boxId={boxId} />
      <ButtonTableNavbar
        onClick={handleNavbarClick}
        type={menuPosition.type}
        itemKey={menuPosition.key}
      />
      <SelectTableRows />
      <NavbarButton
        tooltipContent={t('add-a-line')}
        onClick={handleAddRow}
        disabled={selectedBox?.content?.columns.length === 0}
        icon={<InsertRowBelowOutlined style={iconButtonStyle()} />}
      />
      <NavbarButton
        tooltipContent={t('add-a-column')}
        onClick={handleAddCol}
        disabled={selectedBox?.content?.columns.length === 0}
        icon={<InsertRowRightOutlined style={iconButtonStyle()} />}
      />
      <NavbarSelector
        tooltipContent={t('table-style')}
        suffixIcon={<CaretDownOutlined />}
        width={120}
        onSelect={(e) => {
          updateBox(boxId, (box) => {
            box.tableStyleId = tableStyles.find((ts) => ts.name === e).id;
          });
        }}
        selectedValue={tableStyle.name}
        options={tableStyles.map(style => ({ value: style.name, label: style.name }))}
      />
      <TableCascader
        onChange={handleChangeTable}
        selectedBoxType={selectedBox?.type}
        selectedBox={selectedBox}
      />
    </div>
  );
};

export default NavbarTable
