import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Form } from 'antd'
import { antdFormError, ItemTypes, uuid } from '../../constants/constants'
import { useStore } from '../../store/store'
import { getIncrementedName } from '../../utils/general'
import LabelRow from './LabelRow'
import { useTranslation } from 'react-i18next'
import { convertContentToRawText, decorator } from '../RichEditor/utils'
import { ContentState, EditorState } from 'draft-js'

const selector = (updateBoxFn) => ({
  updateBox,
}) => {
  return {
    updateBox: updateBoxFn ?? updateBox
  }
}

const LabelsArray = ({ box, onBlur, form, arrayKey, selectionKey, updateBoxFn }) => {
  const { updateBox } = useStore(selector(updateBoxFn))
  const [hasAnEmptyLabel, setHasAnEmptyLabel] = useState(false);
  const labelArrayRef = useRef()
  const oldNumberOfLabel = useRef(box[arrayKey].length);
  const oldNumberOfRowIds = useRef(box[arrayKey].length);
  const { t } = useTranslation();
  const [rowIds, setRowIds] = useState(box[arrayKey].map((c) => c.id))
  const isDisabled = box.from_linked_section

  const moveRow = useCallback(
    ({ draggedId, overId }) => {
      setRowIds((oldRowIds) => {
        const newRowIds = oldRowIds.filter((id) => id !== draggedId);
        const newRowId = box[arrayKey].map((c) => c.id).find((id) => id === draggedId);
        const overRowIdx = overId
          ? oldRowIds.findIndex((id) => id === overId)
          : oldRowIds.length;
        newRowIds.splice(overRowIdx, 0, newRowId);
        return newRowIds
      });
    },
    [box, arrayKey]
  );

  const scrollToBottom = () => {
    labelArrayRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const checkEmptyLabel = useCallback(async () => {
    let hasNewEmptyLabel = false
    rowIds?.forEach((_, idx) => {
      const editorState = form.getFieldValue([arrayKey, idx, "label"])
      if (!hasNewEmptyLabel && editorState && !editorState.getCurrentContent().hasText())
        hasNewEmptyLabel = true
    })
    setHasAnEmptyLabel(hasNewEmptyLabel)
  }, [arrayKey, form, rowIds])

  useEffect(() => {
    checkEmptyLabel();
    if (oldNumberOfLabel.current !== box[arrayKey].length) {
      setRowIds(box[arrayKey].map((c) => c.id))
    }
    oldNumberOfLabel.current = box[arrayKey].length
  }, [box, arrayKey, checkEmptyLabel])

  useEffect(() => {
    if (oldNumberOfRowIds.current < rowIds.length) {
      scrollToBottom()
    }
    oldNumberOfRowIds.current = rowIds.length
  }, [rowIds])

  const onDrop = useCallback(() => {
    updateBox(box.id, (box) => {
      box[arrayKey].sort((a, b) => rowIds.findIndex((id) => a.id === id) - rowIds.findIndex((id) => b.id === id))
    })
  }, [arrayKey, box.id, rowIds, updateBox])

  const addLabel = () => {
    updateBox(box.id, (box) => {
      const label = getIncrementedName(
        'Label',
        box[arrayKey].map((b) => convertContentToRawText(b.label))
      );
      box[arrayKey] = [
        ...box[arrayKey],
        {
          [selectionKey]: false, label: EditorState.createWithContent(
            ContentState.createFromText(label),
            decorator
          ), id: uuid()
        }
      ]
    })
  }

  const customOnBlur = useCallback(() => {
    if (!hasAnEmptyLabel) {
      onBlur()
    }
  }, [hasAnEmptyLabel, onBlur])

  const getLabelsName = () => {
    if (box.type === ItemTypes.QUESTION) return "Réponses"
    if (box.type === ItemTypes.CHECKBOXS_VARIABLE) return "Cases à cocher"
    return "Choix"
  }

  const getLabelName = () => {
    if (box.type === ItemTypes.QUESTION) return "une réponse"
    if (box.type === ItemTypes.CHECKBOXS_VARIABLE) return "un élément"
    return "un choix"
  }

  return (
    <>
      <div className="label-array" style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
      }}>
        <Form.Item
          label={getLabelsName()}
          colon={false}
          style={{
            marginBottom: 0,
          }}
        />
        {!isDisabled && <Button style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: 8,
        }}
          onClick={addLabel}
        >
          {t('add')} {getLabelName()}
          <FontAwesomeIcon icon={icon({ name: 'plus', style: 'light' })} />
        </Button>}
      </div>
      <div style={{
        overflow: 'scroll'
      }}>
        {rowIds.map((id, idx) => {
          const item = box[arrayKey].find((c) => c.id === id)
          if (!item) return null
          return <LabelRow
            key={item.id}
            item={item}
            index={idx}
            onBlur={customOnBlur}
            checkEmptyLabel={checkEmptyLabel}
            box={box}
            isLast={idx === box[arrayKey].length}
            form={form}
            moveRow={moveRow}
            onDrop={onDrop}
            arrayKey={arrayKey}
            updateBox={updateBox}
          />
        })
        }
        <div ref={labelArrayRef} />
      </div>
      {hasAnEmptyLabel &&
        <div style={{
          color: antdFormError
        }}>
          {t('labels-cant-be-empty')}
        </div>
      }
    </>
  )
}

export default LabelsArray
