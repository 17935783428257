import { produce } from 'immer';
import { mutateAddColumns, mutateUpdateColumn } from '../utils/columns';
import {
  computeContainerHeight,
  mutateDropInContainer,
  mutateUpdateContainer,
} from '../utils/containers';
import { mutateAddBoxes, mutateRemoveBox } from '../utils/boxes';
import { ItemTypes } from '../constants/constants';

export const columnsActions = (set, get) => ({
  /* COLUMNS */
  addColumns: (newColumns = []) => {
    set(
      produce((draft) => {
        mutateAddColumns(draft, newColumns);
      }),
      false,
      `add ${newColumns.length} new Columns`
    );
  },
  dropBoxInColumn: ({
    id,
    drawMode = false,
    updateContainerHeight = false,
    newBox,
    maskId = null,
    boxIdToReplace,
  }) => {
    set(
      produce((draft) => {
        const column = draft.columns.find((c) => c.id === id);
        const newBoxes = newBox.type === ItemTypes.DRAW ? [] : [newBox];

        if (updateContainerHeight) {
          const container = draft.containers.find((c) =>
            c.columnsIds.includes(id)
          );
          const containerHeight = computeContainerHeight({
            drawMode,
            landscape: draft.landscape,
            boxType: newBox.type,
          });
          mutateUpdateContainer(draft, {
            ...container,
            height: containerHeight,
          });
        }

        mutateUpdateColumn(draft, { ...column, drawMode });

        if (boxIdToReplace) {
          mutateRemoveBox(draft, boxIdToReplace);
        }

        mutateAddBoxes({ draft, newBoxes, drawMode, maskId });
        if (!maskId) {
          const container = draft.containers.find((c) =>
            c.columnsIds.includes(id)
          );
          mutateDropInContainer({ draft, container, box: newBox });
        }
      }),
      false,
      `Drop box in column ${id}`
    );
  },
});
