import * as htmlToImage from 'html-to-image';
import { postPreview } from '../requests/documentDatas';
import { pageHeight, pageWidth } from '../constants/gridConfig';

export const savePagePreview = async (id, type, landscape) => {
  const dataUrl = await htmlToImage.toPng(
    document.getElementsByClassName('page')[0],
    { height: landscape ? pageWidth : pageHeight }
  );
  var img = new Image();
  img.src = dataUrl;
  postPreview(id, type, {
    filename: `preview${id}`,
    content: [img],
  });
};
