import React, { useCallback, useEffect, useState } from 'react';
import { MASKS_TYPE, applyFontsOnThePage, containerBackground, contentHeight, defaultZoom, dynamicZoom, pageBackground } from '../../constants/constants';
import {
  contentPadding, masksDefaultHeight, navbarHeight, pageHeight,
} from '../../constants/gridConfig';
import { useStore } from '../../store/store';
import './Middle.css';
import { pageWidth } from '../../constants/gridConfig';
import { ProposalList } from '../MultipleDocuments/ProposalList';
import InfinitePage from './InfinitePage';
import Container from '../Container/Container';
import ColumnWrapper from '../Column/ColumnWrapper';
import Loader from '../../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const selector = (state) => ({
  moveBoxInfinite: state.moveBoxInfinite,
  boxesId: state.boxes.map((b) => b.id),
  configuration: state.configuration,
  handleUnSelectBox: state.handleUnSelectBox,
  sliderHeight: state.slider.from.value,
  fontSize: state.configuration.fontSize,
  fontFamily: state.configuration.fontFamily,
  fontFamilyList: state.configuration.fontFamilyList,
  maskEditing: state.maskEditing,
  maskList: state.maskList,
  proposalList: state.proposalList,
  masks: state.masks.filter((mask) => state.maskEditing.includes(mask.id)),
  landscape: state.landscape,
  currentDocument: state.currentDocument,
  handleUnSelectContainer: state.handleUnSelectContainer,
  currentProposal: state.currentProposal
});


const Middle = () => {
  const {
    fontSize,
    fontFamily,
    fontFamilyList,
    configuration,
    handleUnSelectBox,
    maskEditing,
    proposalList,
    masks,
    landscape,
    currentDocument,
    handleUnSelectContainer,
    currentProposal
  } = useStore(selector);
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const [SPLoader, setSPLoader] = useState(false);

  const handleLoader = useCallback(() => {
    setSPLoader(true);
    setTimeout(() => {
      setSPLoader(false);
    }, 1500);
  }, [])

  useEffect(() => {
    if ((currentDocument?.type === "template" && currentDocument?.id) || currentProposal?.id)
      handleLoader()
  }, [currentDocument?.id, currentDocument?.type, currentProposal?.id, handleLoader]);

  useEffect(() => {
    if (fontFamilyList.length > 0) { applyFontsOnThePage(fontFamilyList) }
  }, [fontFamilyList]);

  const renderMaskEditing = () => {
    const maskType = masks.find((m) => m.id === MASKS_TYPE.HEADER.id)?.editableMask;
    const maskTypeEdiatble = typeof maskType !== 'boolean' ? true : maskType;
    const renderMask = masks.map((mask) => (
      <div key={mask.id} style={{ backgroundColor: 'white' }}>
        <Container id={mask.id} />
      </div>
    ));
    const isHeaderFooterMask = maskEditing.includes(MASKS_TYPE.FOOTER.id) || maskEditing.includes(MASKS_TYPE.HEADER.id)
    const defautlMaskHeight = (landscape ? pageWidth : pageHeight) + 8
    const maskContentHeight = isHeaderFooterMask ? contentHeight : defautlMaskHeight;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: 'center',
          marginLeft: 0,
          width: "100%",
          zoom: `${configuration.zoom || dynamicZoom() || defaultZoom}%`
        }}
        data-role={pageBackground}>
        <div
          style={{
            width: (landscape ? pageHeight : pageWidth),
            height: maskContentHeight,
            paddingBottom: 8,
            color: configuration.baseFontColor?.style,
            position: 'relative',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'column'
          }
          }
          onClick={(event) => {
            if (!(event.ctrlKey || event.metaKey) && event.target.dataset.role === 'pageBackground') {
              handleUnSelectBox();
              if (event.target.dataset.parent !== containerBackground) {
                handleUnSelectContainer()
              }
            }
          }}
          data-role='pageBackground'
          className='page'
        >
          {isHeaderFooterMask ?
            <>
              <div style={{
                position: 'absolute',
                borderLeft: "#00000021 dotted 3px",
                borderRight: "#00000021 dotted 3px",
                width: '100%',
                top: (header.height ?? masksDefaultHeight.header) + 3,
                height: contentHeight - 8 -
                  (header.height ?? masksDefaultHeight.header)
                  - (footer.height ?? masksDefaultHeight.footer)
                  - 6
              }} />
              <div style={{
                position: "absolute",
                top: (header.height ?? masksDefaultHeight.header),
                left: "50%",
                transform: "translateX(-50%)"
              }}>{MASKS_TYPE.HEADER.name.toUpperCase()}
                {!maskTypeEdiatble && (<div style={{
                  marginTop: 200,
                  display: "flex",
                  flexDirection: "column"
                }}>
                  <FontAwesomeIcon
                    icon={icon({ name: 'lock-keyhole', style: 'light' })}
                    style={{ margin: 0, fontSize: 70 }} />
                  <div>Masque bloqué</div>
                </div>)}
              </div>
              <div style={{
                position: "absolute",
                bottom: (footer.height ?? masksDefaultHeight.footer) + 8,
                left: "50%",
                transform: "translateX(-50%)"
              }}>{MASKS_TYPE.FOOTER.name.toUpperCase()}</div>
              {renderMask}
            </> : renderMask}
        </div>
      </div>
    );
  }

  const renderContent = () => {
    switch (true) {
      case SPLoader:
        return <Loader />
      case maskEditing.length > 0:
        return renderMaskEditing();
      case (!proposalList || currentDocument.type === 'template'):
        return <InfinitePage />
      case proposalList:
        return <ProposalList />
      default:
        return null;
    }
  }


  return (
    <div
      id='pdf'
      data-role={pageBackground}
      style={{
        fontSize: `${fontSize}px`,
        overflowY: 'hidden',
        fontFamily: fontFamily,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: `${contentPadding}px`,
        overflow: 'scroll',
        marginTop: navbarHeight,
        position: 'relative'
      }}
    >
      {renderContent()}
    </div>
  );
};

export default React.memo(Middle);
