import { useTranslation } from 'react-i18next';
import { pageWidth } from '../../constants/gridConfig'
import { useStore } from '../../store/store';
import NavbarSelector from '../Layout/NavbarSelector';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { SPACE_BOX_SIZE } from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Button, Tooltip } from 'antd';

const selector =
  (boxId) =>
    ({
      configuration,
      selectedBoxId,
      updateBox
    }) => ({
      sizeSpaceContainer: configuration.sizeSpaceContainer,
      isSelected: selectedBoxId === boxId,
      updateBox
    });
const SpaceBox = ({ box }) => {
  const {
    sizeSpaceContainer,
    isSelected
  } = useStore(selector(box.id));
  const sizeSpaceBox = sizeSpaceContainer.find((space) => space.id === box.content.sizeName)?.value;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        className='space-box'
        style={{
          height: `${sizeSpaceBox}px`,
          color: "gray",
          width: pageWidth - 80,
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: isHovered || isSelected ? '#F5F5F5' : 'transparent', // Background change on hover

        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='space-box-icon'
          style={{
            display: isHovered || isSelected ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
          <Tooltip title="Espace">
            <Button
              style={{ width: sizeSpaceBox * 0.9, height: sizeSpaceBox * 0.9, border: 'none', padding: 0, color: "black" }}
              disabled
              icon={
                <span className="fa-layers" style={{ width: `${sizeSpaceBox * 0.9}px` }}>
                  <FontAwesomeIcon
                    icon={icon({ name: 'square-dashed', style: 'light' })}
                    style={{ fontSize: `${sizeSpaceBox * 0.8}px` }}
                  />
                  <FontAwesomeIcon
                    icon={icon({ name: 'arrow-up-right-and-arrow-down-left-from-center', style: 'regular' })}
                    style={{
                      fontSize: `${sizeSpaceBox * 0.37}px`,
                      transform: 'rotate(135deg)',
                    }}
                  />
                </span>
              }
            />
          </Tooltip>
        </div>

      </div>
      {isSelected
        ? ReactDOM.createPortal(
          <SpaceComponenent selectedBox={box} />,
          document.getElementById('ComponentPortal')
        )
        : null}
    </>
  )
}

export const SpaceComponenent = ({ selectedBox }) => {
  const boxId = selectedBox?.id;

  const { t } = useTranslation();
  const { updateBox } = useStore(selector(boxId));

  const selectSpaceSize = (spaceId) => {
    updateBox(boxId, (box) => {
      box.content.sizeName = spaceId;
    });
  };

  return (
    <div className='navbar__formshape-properties-container'>
      <div className='navbar__fstop-properties-container'>
        <NavbarSelector
          key="spaceSizeSelector"
          tooltipContent={"Taille"}
          onSelect={selectSpaceSize}
          selectedValue={selectedBox.content.sizeName}
          options={SPACE_BOX_SIZE.map(({ id, name }) => ({ label: t(name), value: id }))}
        />

      </div>
    </div>
  );
}

export default React.memo(SpaceBox);
