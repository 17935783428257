import React, { useState, useCallback } from 'react'
import { borderColumnColor, borderColumnErrorColor, borderColumnWidth, columnsDispositions, containerBackground, ItemTypes, pageBackground, roundButtonWidth } from '../../constants/constants'
import AdderColumns from '../Container/AdderColumns'
import { useStore } from '../../store/store'
import { useDrop } from 'react-dnd'
import { isAOneRowBox } from '../../utils/boxes'

const getWidthAndPosition = ({ position, columnIndex, willBoxBeReplaced, containerColumnsLength }) => {
  if (position === "left") {
    if (columnIndex === 0 ||
      willBoxBeReplaced) {
      return borderColumnWidth
    } else {
      return 2
    }
  } else {
    if (columnIndex + 1 === containerColumnsLength ||
      willBoxBeReplaced) {
      return borderColumnWidth
    } else {
      return 2
    }
  }
}

const borderCommonStyle = ({
  isVisible,
  position,
  willBoxBeReplaced,
  boxCanDropInColumn,
  isDragging,
  containerColumnsLength,
  columnIndex,
}) => {
  const style = {
    position: 'absolute',
    height: "100%",
    backgroundColor: boxCanDropInColumn || !isDragging ? borderColumnColor : borderColumnErrorColor,
    top: 0,
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 200ms ease',
    display: "flex"
  }

  if (position === "left") {
    if (columnIndex === 0 ||
      willBoxBeReplaced) {
      style.width = borderColumnWidth
    } else {
      style.width = 2
    }
  } else {
    if (columnIndex + 1 === containerColumnsLength ||
      willBoxBeReplaced) {
      style.width = borderColumnWidth
    } else {
      style.width = 2
    }
    style.right = 0
  }

  return style
}

const selector = (columnId) => ({
  containers,
  updateColumnsInContainer,
  selectedBoxId,
  boxes,
  boxVariableSelectedId,
  currentProposal,
  selectedContainer
}) => {
  const container = containers.find((c) => c.columnsIds.includes(columnId));
  return {
    container,
    updateColumnsInContainer,
    columnIndex: container.columnsIds.findIndex((id) => id === columnId),
    isBoxSelected: !!selectedBoxId && boxes.some((b) => selectedBoxId === b.id && container.columnsIds.includes(columnId)),
    boxVariableSelectedId,
    currentProposal,
    box: boxes.find((b) => b.columnId === columnId),
    isContainerSelected: selectedContainer && selectedContainer.id === container.id
  }
};

const BorderColumn = ({
  position,
  willBoxBeReplaced,
  containerColumnsLength,
  columnId,
  dropCommonProps,
  isContainerHovered,
  boxCanDropInColumn,
  hasAOneRowBox
}) => {
  const {
    columnIndex,
    container,
    updateColumnsInContainer,
    isBoxSelected,
    boxVariableSelectedId,
    currentProposal,
    isContainerSelected,
    box
  } = useStore(selector(columnId));
  const [isHovered, setIsHovered] = useState(false)

  const onBorderDrop = (box) => {
    const columnsDisposition = columnsDispositions.find((cd) => {
      return cd.key === container.columnsIds.length
    });
    const columnsSizes = columnsDisposition.dispositions.find((d) => {
      return d.sizes.every((size) => size === d.sizes[0])
    }).sizes
    updateColumnsInContainer({
      columnsSizes,
      index: position === 'right' ? columnIndex : null,
      containerId: container.id,
      newBoxes: [box]
    });
  }

  const showColumn = position === 'right' || columnIndex === 0

  const canAddColumn = !isBoxSelected &&
    container.columnsIds.length < 4 &&
    showColumn &&
    // If dropped box take full size
    !hasAOneRowBox

  const boxCanDropBetweenColumn = (_, monitor) => {
    const box = monitor.getItem();
    // If dragged box need full size
    if (isAOneRowBox(box.type)) {
      return false;
    }
    if (!boxCanDropInColumn) return false;
    if (!canAddColumn) return false;
    return true
  }

  const [{ boxIsOver, canDrop, isDragging }, dropColumnBorder] = useDrop({
    ...dropCommonProps,
    canDrop: boxCanDropBetweenColumn,
    drop: onBorderDrop,
  });

  const isVisible = !!(isContainerHovered || isDragging || willBoxBeReplaced || isContainerSelected)

  const boderPosition = position === 'left' ? {
    left: -getWidthAndPosition({ position, columnIndex, willBoxBeReplaced, containerColumnsLength })
  } : { right: -getWidthAndPosition({ position, columnIndex, willBoxBeReplaced, containerColumnsLength }) }

  const transform = () => {
    if (columnIndex === 0 && position === 'left') return 2;
    if (columnIndex + 1 === containerColumnsLength && position === 'right') return 30 - 2
    return 30
  }

  const zIndexBoder = () => {
    if (!isHovered && !isDragging) return 0
    return position === "right" ? 3 : 2
  }

  const canAddColumnOnClick = canAddColumn && !isDragging
  const canDisplayBorderColumn = useCallback(() => {
    if (currentProposal.id === null && container?.from_linked_section) return false;
    if (box?.type === ItemTypes.SPACE) return false
    if (currentProposal.id === null) return true
    if (currentProposal.id !== null && !container.hasOwnProperty('editable')) return true
    if (currentProposal.id !== null && container?.editable) return true
    else return false
  }, [isDragging, isBoxSelected, canAddColumn, box])

  return (
    <div
      ref={dropColumnBorder}
      data-role={pageBackground}
      data-parent={containerBackground}
      style={{
        position: 'absolute',
        height: "100%",
        zIndex: zIndexBoder(),
        top: 0,
        display: "flex",
        width: roundButtonWidth,
        ...boderPosition
      }}
    >
      <div
        data-role={pageBackground}
        data-parent={containerBackground}
        style={{
          ...borderCommonStyle({
            isVisible,
            position,
            willBoxBeReplaced,
            boxCanDropInColumn,
            isDragging,
            containerColumnsLength,
            columnIndex,
          }),
        }} />
      {(canAddColumnOnClick || canDrop) && !boxVariableSelectedId &&
        <div style={{
          transform: `translateX(calc(-50% + ${transform()}px))`,
        }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          {canDisplayBorderColumn() && <AdderColumns
            columnId={columnId}
            columnIndex={position === 'right' ? columnIndex : null}
            boxIsOver={boxIsOver}
            canDrop={canDrop}
            defaultFontSizeIcon={15}
          />}

        </div>
      }
    </div>
  )
}

export default BorderColumn;
