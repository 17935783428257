import React from "react";
import "./Line.css"

const Line = ({ color, lineHeight, style }) => {
  return (
    <div
      className="line"
      style={{ borderTop: `${lineHeight}px ${style} ${color}` }}
    />
  );
};

export default Line;
