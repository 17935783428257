import React, { useRef, useState } from 'react'
import { useOutsideClickHandler } from '../../utils/browser';
const FloatyMenu = ({
  menuIsOpen,
  setMenuIsOpen,
  left,
  top,
  options,
  onItemClick
}) => {
  const ref = useRef();
  const [hoveredIdx, setHoveredIdx] = useState()
  useOutsideClickHandler(ref, () => {
    if (menuIsOpen) {
      setMenuIsOpen(false)
      setHoveredIdx()
    }
  });

  if (!menuIsOpen) return null;

  return (
    <div
      ref={ref}
      style={{
        position: 'fixed',
        zIndex: 99999,
        boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px - 4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
        left,
        top
      }}
    >
      {
        options.map(({ label, id }, idx) =>
          <div key={id}
            onMouseOver={() => setHoveredIdx(idx)}
            onMouseLeave={() => setHoveredIdx()}
            onClick={(e) => onItemClick && onItemClick(e, { label, id })}
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 4,
              paddingBottom: 4,
              cursor: 'pointer',
              backgroundColor: hoveredIdx === idx ? "#e0dfdf" : "#ffffff",
              borderBottom: idx !== options.length - 1 ? '0.5px black solid' : undefined,
              borderBottomRightRadius: idx === options.length - 1 ? 8 : undefined,
              borderBottomLeftRadius: idx === options.length - 1 ? 8 : undefined,
              borderTopRightRadius: idx === 0 ? 8 : undefined,
              borderTopLeftRadius: idx === 0 ? 8 : undefined,

            }}>
            {label}
          </div>
        )
      }
    </div >
  )
}

export default FloatyMenu
