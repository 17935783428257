import { EditorState, convertToRaw } from 'draft-js';

export const newVariableGroup = ({
  id,
  name,
  disposition = 2,
  remark = JSON.stringify(
    convertToRaw(EditorState.createEmpty().getCurrentContent())
  ),
}) => {
  return {
    id,
    name,
    remark,
    disposition,
    variableIds: [],
  };
};

export const mutateAddVariableGroup = ({
  draft,
  name,
  remark,
  disposition,
  id,
}) => {
  const variableGroup = newVariableGroup({ name, remark, disposition, id });
  draft.variableGroups.push(variableGroup);
  return variableGroup;
};

export const mutateDeleteVariableGroup = ({ draft, id }) => {
  const vbIndex = draft.variableGroups.findIndex((vb) => vb.id === id);
  draft.variableGroups = draft.variableGroups.filter(
    (_, idx) => idx !== vbIndex
  );
};

export const mutateHandleLinkedVariable = ({
  draft,
  variableGroup,
  variableIds,
  variableId,
}) => {
  variableGroup.variableIds = variableIds;
  variableIds.forEach((id) => {
    if (
      draft.variables[id] &&
      !draft.variables[id].notUsed &&
      !draft.variableOrderArray.some(({ id }) => id === variableGroup.id)
    ) {
      const afterIdx = draft.variableOrderArray.findIndex(
        ({ containerId }) => containerId === variableId
      );
      if (afterIdx !== -1) {
        draft.variableOrderArray = [
          ...draft.variableOrderArray.slice(0, afterIdx),
          { id: variableGroup.id, containerId: variableGroup.id },
          ...draft.variableOrderArray.slice(afterIdx),
        ];
      } else {
        draft.variableOrderArray.unshift({
          id: variableGroup.id,
          containerId: variableGroup.id,
        });
      }
    }
  });
  if (
    !variableIds.includes(variableId) &&
    !draft.variables[variableId].notUsed &&
    !draft.variableOrderArray.some(({ id }) => id === variableId)
  ) {
    draft.variableOrderArray.unshift({
      id: variableId,
      containerId: variableId,
    });
  }
  draft.variableOrderArray = draft.variableOrderArray.filter(({ id }) => {
    if (variableIds.includes(id)) return false;
    const vG = draft.variableGroups.find((vG) => id === vG.id);
    if (
      vG &&
      !vG.variableIds.some(
        (vId) => draft.variables[vId] && !draft.variables[vId].notUsed
      )
    )
      return false;
    return true;
  });
};

export const constructVariableIds = ({ variableGroup, variableId }) => {
  if (variableGroup.variableIds.includes(variableId)) {
    return variableGroup.variableIds.filter((id) => id !== variableId);
  } else {
    return [...variableGroup.variableIds, variableId];
  }
};

export const variableGroupToRaw = (variableGroup) => ({
  ...variableGroup,
  remark: JSON.stringify(
    convertToRaw(variableGroup.remark.getCurrentContent())
  ),
});

export const rawVariableGroupToStoreVariableGroup = (rawVG) => ({
  ...rawVG,
  id: typeof rawVG.id === 'string' ? rawVG.id : rawVG.id.toString(),
  variableIds: rawVG.variable_ids,
});
