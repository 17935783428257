import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom';
import { handleBeforeInput } from '../RichEditor/utils';
import { Editor, convertToRaw } from 'draft-js';
import { transformTextToEditorState } from '../../utils/dataSync';
import { useStore } from '../../store/store';
import { debounce } from 'lodash';
import { convertPadding, getHeaderStyle } from '../../utils/table';
import ColumnResizer from './ColumnResizer';
import FloatyTextStyling from './FloatyTextStyling';
import { useStyleMap } from '../../hooks';
import EditorWrapper from '../common/EditorWrapper';
import ContextualMenu from '../ContextualMenu/ContextualMenu';
import { MENU_ANCESTORS } from '../../constants/constants';
import { EditorProvider } from '../../contexts/EditorContext';

const Header = ({
  tableIsSelected,
  title,
  boxId,
  box,
  handleClickInTable,
  updateTheadHeight,
  columnKey,
  tableStyle,
  align,
  isLastColumn,
  nextColKey,
  rotation,
  setColumnSizeVars,
  computeMaxColumnWidth,
  drawMode
}) => {
  const styleMap = useStyleMap()
  const setEventManagerEnabled = useStore(({ setEventManagerEnabled }) => setEventManagerEnabled)
  const updateBox = useStore(({ updateBox }) => updateBox)
  const configuration = useStore(({ configuration }) => configuration)
  const selectedBoxId = useStore(({ selectedBoxId }) => selectedBoxId)

  const [editorState, setEditorState] = useState(transformTextToEditorState(title));
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    if (!isSelected) {
      setEditorState(transformTextToEditorState(title))
    }
  }, [title, isSelected]);

  const inputRef = useRef()

  const style = getHeaderStyle({ tableStyle, configuration, columnKey })

  const focus = useCallback(() => {
    setIsSelected(true)
    setEventManagerEnabled(false);
    inputRef.current.focus()
  }, [setEventManagerEnabled])

  const handleFocus = (e) => {
    if (tableIsSelected && !isSelected) {
      handleClickInTable({ columnKey, type: 'columns' })(e)
      setIsSelected(true)
      e.preventDefault();
      focus()
    }
  }

  useEffect(() => {
    if (box.selectedItem?.columnKey === columnKey && box.selectedItem?.type === 'columns' && selectedBoxId === boxId) {
      focus()
    }
  }, [box.selectedItem?.columnKey, box.selectedItem?.type, columnKey, focus, boxId, selectedBoxId, updateBox, box.selectedItem]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const save = useCallback(debounce((newEditorState) => {
    updateTheadHeight();
    updateBox(boxId, (box) => {
      const column = box.content.columns.find((col) => col.key === columnKey)
      if (column)
        column.title = JSON.stringify(convertToRaw(newEditorState.getCurrentContent()));
    });
  }, 100), [updateTheadHeight]);


  useEffect(() => {
    if (tableIsSelected && isSelected) {
      save(editorState);
    }
  }, [editorState, save, tableIsSelected, isSelected]);

  const { padding } = convertPadding(tableStyle.size);

  useEffect(() => {
    function handleClickOutside(event) {
      if (isSelected && ref.current && !ref.current.contains(event.target) &&
        !(event.relatedTarget && (event.relatedTarget.className === "variable-selection-line" || event.relatedTarget.closest(".font-family-selector")))
      ) {
        save(editorState)
        setIsSelected(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editorState, save, isSelected]);

  const ref = useRef()
  const menuAncestorIds = useMemo(() => [boxId, columnKey], [boxId, columnKey])

  return (
    <th
      style={style}
      onClick={(e) => {
        handleClickInTable({ columnKey, type: 'columns' })(e);
        handleFocus(e)
      }}
      ref={ref}
    >
      <div style={{
        margin: 0,
        backgroundColor: 'transparent',
        zIndex: tableIsSelected ? 1000 : 'auto',
        border: 'none',
        color: style.color,
        padding,
        fontSize: style?.fontSize,
        fontFamily: style?.fontFamily,
        fontWeight: 'normal',
        minHeight: 32,
        lineHeight: 1.5714285714285714,
        overflow: "hidden",
      }}
      >
        <EditorProvider setEditorState={setEditorState} editorState={editorState} readOnly={!isSelected}>
          <EditorWrapper
            boxId={`${boxId}-${columnKey}}`}
            editorState={editorState}
          >
            <Editor
              stripPastedStyles={true}
              handleBeforeInput={(chars, newEditorState) => handleBeforeInput(
                {
                  chars,
                  editorState: newEditorState,
                  setEditorState
                }
              )}
              editorState={editorState}
              ref={inputRef}
              onChange={!boxId ? null : ((e) => setEditorState(e))}
              textAlignment={align}
              readOnly={!isSelected}
              customStyleMap={styleMap}
              onBlur={() => {
                save(editorState);
                updateBox(boxId, (box) => {
                  box.selectedItem = undefined
                }
                );
              }}
            />
          </EditorWrapper>
        </EditorProvider>
      </div>
      {tableIsSelected &&
        ReactDOM.createPortal(
          <ContextualMenu
            editorState={editorState}
            setEditorState={setEditorState}
            menuAncestorType={MENU_ANCESTORS.BOX.header}
            menuAncestorIds={menuAncestorIds}
          />,
          document.getElementById('SelectionVariablePortal')
        )}
      {tableIsSelected && isSelected &&
        ReactDOM.createPortal(
          <FloatyTextStyling
            editorState={editorState}
            setEditorState={setEditorState}
            defaultFontSize={style?.fontSize}
            defaultFontFamily={style?.fontFamily}
            defaultColor={tableStyle.customHeaderTextColor}
          />,
          document.getElementById('SelectionVariablePortal')
        )}
      <ColumnResizer
        boxId={boxId}
        rotation={rotation}
        setColumnSizeVars={setColumnSizeVars}
        columnKey={columnKey}
        nextColKey={nextColKey}
        computeMaxColumnWidth={computeMaxColumnWidth}
        resizeEnabled={!isLastColumn && (!drawMode || tableIsSelected)}
      />
    </th>
  )
}

export default Header
