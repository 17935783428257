// export const handleImgUrl = (boxId, updateBox) => (e) => {
//   updateBox(boxId, (box) => {
//     box.content.src = e.target.value;
//   });
// };

// export const onChangeColor =
//   (boxId, updateBox, configuration) => (selectedColor) => {
//     updateBox(boxId, (box) => {
//       box.backgroundColor = {
//         label: selectedColor,
//         style: configuration.colors.find(
//           (color) => color.label === selectedColor
//         ).style,
//       };
//     });
//   };

// export const onChangeBorderColor =
//   (boxId, updateBox, configuration) => (selectedColor) => {
//     updateBox(boxId, (box) => {
//       box.border.color = {
//         label: selectedColor,
//         style: configuration.colors.find(
//           (color) => color.label === selectedColor
//         ).style,
//       };
//     });
//   };

// export const handleBorderChangement = (boxId, updateBox, type, value) => {
//   updateBox(boxId, (box) => {
//     if (type === 'top') {
//       box.border.top = value;
//     } else if (type === 'bottom') {
//       box.border.bottom = value;
//     } else if (type === 'left') {
//       box.border.left = value;
//     } else {
//       box.border.right = value;
//     }
//   });
// };

export const getImageStyle = (backgroundColor, border, isTemplate = false) => {
  return {
    width: '100%',
    backgroundClip: 'content-box',
    backgroundColor:
      backgroundColor.label !== 'transparent' ? backgroundColor.style : null,
    borderTop:
      border.top === true
        ? border.size + 'px solid ' + border.color.style
        : null,
    borderBottom:
      border.bottom === true
        ? border.size + 'px solid ' + border.color.style
        : null,
    borderLeft:
      border.left === true
        ? border.size + 'px solid ' + border.color.style
        : null,
    borderRight:
      border.right === true
        ? border.size + 'px solid ' + border.color.style
        : null,
  };
};
