import { Button, Checkbox, Col, Form, InputNumber, Row, Select, Switch } from 'antd';
import { ItemTypes, roundButtonWidth } from '../../../constants/constants';
import {
  calculateLeftPadding,
  calculateWidth,
  formLabelMarginConfigWidth,
  searchInputWidth,
  unitName,
} from '../../../constants/gridConfig';
import { useStore } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { isATableBox } from '../../../utils/boxes';

const selector = ({
  configuration,
  setConfiguration,
  gridConfiguration,
  setGridConfiguration,
}) => ({
  configuration,
  setConfiguration,
  gridConfiguration,
  setGridConfiguration,
});

export const TabsMarges = () => {
  const {
    configuration,
    setConfiguration,
    gridConfiguration,
    setGridConfiguration,
  } = useStore(selector);
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const changeItemConfig = (item, margins, indentValue, indentLevel) => {
    setGridConfiguration({
      [item]: {
        ...gridConfiguration[item],
        width: calculateWidth(margins, indentValue, indentLevel),
        left: calculateLeftPadding(margins, indentValue, indentLevel),
      },
    });
  }

  const handleChangeEditableTemplate = (value) => {
    if (configuration?.editableTemplate || !configuration.hasOwnProperty('editableTemplate')) {
      setConfiguration({
        editableTemplate: false
      })
    } else {
      setConfiguration({
        editableTemplate: true
      })
    }
  }

  const handleChangeUnlockProposal = (value) => {
    if (configuration?.unlockProposal || !configuration.hasOwnProperty('unlockProposal')) {
      setConfiguration({
        unlockProposal: false
      })
    } else {
      setConfiguration({
        unlockProposal: true
      })
    }
  }

  const handleChangeLineSpacing = (value) => {
    setConfiguration({
      ...configuration,
      lineSpacing: value,
    });
  };

  const handleChangeColumnGap = (value) => {
    setConfiguration({
      ...configuration,
      columnGap: value,
    });
  };

  const handleChangeDefaultSizeSpaceContainer = (value) => {
    setConfiguration({
      ...configuration,
      defaultSizeSpaceContainer: value,
    });
  }
  const handleChangetSizeSpaceContainer = (value, sizeName) => {
    setConfiguration({
      ...configuration.sizeSpaceContainer,
      sizeSpaceContainer: configuration.sizeSpaceContainer.map(size =>
        size.id === sizeName ? { ...size, value: value } : size
      )
    });

  }

  const padLockState = () => {
    if (configuration?.editableTemplate || !configuration.hasOwnProperty('editableTemplate')) {
      return (<FontAwesomeIcon
        icon={icon({ name: 'lock-keyhole-open', style: 'light' })}
        style={{ margin: 0, fontSize: 15 }} />)
    }
    else {
      return (<FontAwesomeIcon
        icon={icon({ name: 'lock-keyhole', style: 'light' })}
        style={{ margin: 0, fontSize: 15 }} />)
    }
  }

  const handleChangeMargin = (side) => (value) => {
    setConfiguration({
      ...configuration,
      margins: {
        ...configuration.margins,
        [side]: value,
      },
    });
    [ItemTypes.TITLE, ItemTypes.TITLE_2, ItemTypes.TITLE_3, ItemTypes.TITLE_4, ItemTypes.SUMMARY, ItemTypes.TABLE].forEach(
      (item, index) => {
        let indentation = index;
        if (item === ItemTypes.SUMMARY || isATableBox(item)) { indentation = 0; }
        const margins = {
          ...configuration.margins,
          [side]: value,
        }
        changeItemConfig(item, margins, configuration.indentationTitles, indentation);
      }
    );
  };

  const handleChangeTitlesIndentation = (value) => {
    setConfiguration({
      ...configuration,
      indentationTitles: value,
    });
    [ItemTypes.TITLE, ItemTypes.TITLE_2, ItemTypes.TITLE_3, ItemTypes.TITLE_4, ItemTypes.TABLE, ItemTypes.SUMMARY].forEach(
      (item, index) => {
        let indentation = index;
        if (item === ItemTypes.SUMMARY || isATableBox(item)) { indentation = 0; }
        changeItemConfig(item, configuration.margins, value, indentation);
      }
    );
  };

  return (
    <>
      {['left', 'right'].map((side) => (
        <Form.Item
          label={t(`margin-${side}`)}
          labelCol={{
            flex: `${formLabelMarginConfigWidth + 59}px`,
          }}
        >
          <InputNumber
            min={0}
            max={16}
            onChange={handleChangeMargin(side)}
            value={configuration.margins[side]}
            addonAfter={unitName}
            style={{ width: 150 }}
          />
        </Form.Item>
      ))}
      <Form.Item
        label="Décalage des titres (vers la droite par rapport au titre de niveau supérieur)"
        labelCol={{
          flex: `${formLabelMarginConfigWidth + 59}px`,
        }}
      >
        <InputNumber
          min={0}
          max={16}
          onChange={handleChangeTitlesIndentation}
          value={configuration.indentationTitles}
          addonAfter={unitName}
          style={{ width: 150 }}
        />
      </Form.Item>
      <Form.Item
        name='page-format'
        label={'Containers éditables pour les utilisateurs ? (les nouveaux containers possèderont cette valeur)'}
        labelCol={{
          flex: `${formLabelMarginConfigWidth + 59}px`,
        }}
      >
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <Button shape="circle"
            size={14}
            onClick={(value) => handleChangeEditableTemplate()}
            style={{
              width: roundButtonWidth,
              height: roundButtonWidth,
              minWidth: roundButtonWidth,
              minHeight: roundButtonWidth,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            icon={
              padLockState()
            }
          />
        </div>
      </Form.Item>
      <Form.Item
        name='page-format'
        label={'Ajout et suppression de lignes autorisés ?'}
        labelCol={{
          flex: `${formLabelMarginConfigWidth + 59}px`,
        }}
      >
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <Checkbox checked={configuration.unlockProposal} onClick={(value) => handleChangeUnlockProposal()} />
        </div>
      </Form.Item>
      <Form.Item
        label={'Marge entre les lignes'}
        labelCol={{
          flex: `${formLabelMarginConfigWidth + 59}px`,
        }}
      >
        <InputNumber value={configuration.lineSpacing} onChange={handleChangeLineSpacing}
          min={10}
          max={100}
          addonAfter={unitName}
          style={{ width: 150 }}
        />
      </Form.Item>
      {/* <Form.Item
        label={'Marge entre les colonnes'}
      >
        <InputNumber value={configuration.columnGap} onChange={handleChangeColumnGap}
          min={4}
          max={150}
          addonAfter={unitName}
          style={{ width: 150 }}
        />
      </Form.Item> */}
      <Form.Item
        label={'Taille du container Espace par défaut'}
        labelCol={{
          flex: `${formLabelMarginConfigWidth + 59}px`,
        }}
      >
        <Select
          onChange={handleChangeDefaultSizeSpaceContainer}
          defaultValue={t(configuration.defaultSizeSpaceContainer)}
          style={{ width: 90 }}
          options={configuration.sizeSpaceContainer.map((size) => ({ label: t(size.name), value: size.id }))}
        >
        </Select>
      </Form.Item>
      <Form.Item
        label={'Hauteurs du container Espace'}
        labelCol={{
          flex: `${formLabelMarginConfigWidth}px`,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "left", gap: 8 }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "right", gap: 8 }}>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "51px" }} >Petit :</div>
            <InputNumber
              min={15} onChange={e => handleChangetSizeSpaceContainer(e, "small")}
              defaultValue={configuration.sizeSpaceContainer.find(b => b.id === "small").value}
              addonAfter={unitName}
              style={{ width: 150 }} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "right", gap: 8 }}>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "51px" }}>Moyen :</div>
            <InputNumber min={3} onChange={e => handleChangetSizeSpaceContainer(e, "medium")}
              defaultValue={configuration.sizeSpaceContainer.find(b => b.id === "medium").value}
              addonAfter={unitName}
              style={{ width: 150 }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "right", gap: 8 }}>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "51px" }}>Grand :</div>
            <InputNumber min={3} onChange={e => handleChangetSizeSpaceContainer(e, "large")}
              defaultValue={configuration.sizeSpaceContainer.find(b => b.id === "large").value}
              addonAfter={unitName}
              style={{ width: 150 }}
            />
          </div>
        </div>
      </Form.Item >
    </>
  );
};
