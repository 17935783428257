import { produce } from 'immer';
import { removeVariables } from '../utils/variables';
import {
  isATableBox,
  isATextBox,
  isATitleBox,
  isAVariableBox,
} from '../utils/boxes';
import { convertToRaw } from 'draft-js';
import { transformTextToEditorState } from '../utils/dataSync';
import { ItemTypes } from '../constants/constants';
import { mutateRemoveTriggersWithTriggerId } from '../utils/triggers';

export const variablesActions = (set, get) => ({
  /* VARIABLES */
  addVariable: (newVariable, callback) => {
    set(
      produce((draft) => {
        draft.defaultVariablesApi = {
          ...draft.defaultVariablesApi,
          custom_text_variables: {
            ...draft.defaultVariablesApi.custom_text_variables,
            [`${newVariable.name}.${newVariable.id}`]: '',
          },
        };
        const newVariableKey = `custom.${newVariable.id}`;
        draft.variables = {
          [newVariableKey]: { name: newVariable.name, value: '' },
          ...draft.variables,
        };
        if (typeof callback === 'function')
          callback(draft.variables, newVariableKey, draft);
      }),
      false,
      `add new Variable ${newVariable.name}`
    );
  },

  updateVariable: (variableKey, callback) => {
    set(
      produce((draft) => {
        if (typeof callback === 'function')
          callback(draft.variables[variableKey], draft);
      }),
      false,
      `update Variable ${variableKey}`
    );
  },

  updateVariables: (variableIds, callback) => {
    set(
      produce((draft) => {
        if (typeof callback === 'function') {
          const variablesToUpdate = variableIds.map(
            (id) => draft.variables[id]
          );
          callback(variablesToUpdate, draft);
        }
      }),
      false,
      `update Variables ${variableIds.join(' ')}`
    );
  },

  deleteVariableInDocument: (variableKey) => {
    set(
      produce((draft) => {
        draft.boxVariableSelectedId = null;
        draft.variables[variableKey].notUsed = true;
        const vGParentIndex = draft.variableGroups.findIndex((vG) => {
          if (vG.variableIds.includes(variableKey)) {
            return true;
          }
          return false;
        });
        if (
          vGParentIndex !== -1 &&
          !draft.variableGroups[vGParentIndex].variableIds.some(
            (id) => draft.variables[id] && !draft.variables[id].notUsed
          )
        ) {
          draft.variableGroups.push(
            draft.variableGroups.splice(vGParentIndex, 1)[0]
          );
        }
        const boxes = [
          ...draft.boxes,
          ...draft.masks.map((m) => m.boxes).flat(),
        ];
        boxes.forEach((box) => {
          if (isATextBox(box.type) || isATitleBox(box.type)) {
            box.content.editorState = removeVariables(box.content.editorState, [
              variableKey,
            ]);
          } else if (isATableBox(box.type)) {
            box.content.columns.forEach((elementColumn, indexColumn) => {
              box.content.columns[indexColumn].title = JSON.stringify(
                convertToRaw(
                  removeVariables(
                    transformTextToEditorState(
                      box.content.columns[indexColumn].title
                    ),
                    [variableKey]
                  ).getCurrentContent()
                )
              );
              box.content.data.forEach((element, index) => {
                box.content.data[index][elementColumn['key']] = JSON.stringify(
                  convertToRaw(
                    removeVariables(
                      transformTextToEditorState(
                        box.content.data[index][elementColumn['key']]
                      ),
                      [variableKey]
                    ).getCurrentContent()
                  )
                );
              });
            });
          }

          if (isAVariableBox(box.type)) {
            box.name = removeVariables(box.name, [variableKey]);

            if (
              box.type === ItemTypes.CHECKBOXS_VARIABLE ||
              box.type === ItemTypes.QUESTION
            ) {
              box.checkboxs = box.checkboxs.map((c) => ({
                ...c,
                label: removeVariables(c.label, [variableKey]),
              }));
            }

            if (box.type === ItemTypes.SELECTOR_VARIABLE) {
              box.options = box.options.map((c) => ({
                ...c,
                label: removeVariables(c.label, [variableKey]),
              }));
            }
          }
        });
        draft.variableOrderArray = draft.variableOrderArray.filter(({ id }) => {
          if (id === variableKey) return false;
          const vG = draft.variableGroups.find((vG) => id === vG.id);
          if (
            vG &&
            !vG.variableIds.some(
              (id) => draft.variables[id] && !draft.variables[id].notUsed
            )
          )
            return false;
          return true;
        });
        mutateRemoveTriggersWithTriggerId({ triggerId: variableKey, draft });
      }),
      false,
      `delete variable ${variableKey} in document`
    );
  },
});
