import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import '../RightSide/configurationModal/tableConfig.css';
import './Table.css';
import { useStore } from '../../store/store';
import TableHead from './TableHead';
import TableBody from './TableBody';
import ReactDOM from 'react-dom';
import NavbarTable from './NavbarTable';
import { useTranslation } from 'react-i18next';
import TableFooter from './TableFooter';
import { defaultTableConfiguration } from '../../constants/constants';
import ButtonRefresh from '../common/ButtonRefresh';
import { fetchTableData } from '../../utils/table';
import { isAVariableBox } from '../../utils/boxes';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';

export const MIN_COLUMN_WIDTH = 20;
export const DEFAULT_COLUMN_WIDTH = 100;

const Table = ({ box, drawMode }) => {
  const [isHovered, setIsHovered] = useState(false);
  const updateBox = useStore(({ updateBox }) => updateBox);
  const fetchTable = useStore(({ fetchTable }) => fetchTable)
  const setBoxMovable = useStore(({ setBoxMovable }) => setBoxMovable)
  const { updateLeftShownStatus } = useOverlay();
  const currentDocument = useStore(({ currentDocument }) => currentDocument)
  const defaultTableStyleId = useStore((state) => state.configuration.tableStyles[0].id)
  const isSelected = useStore(({ selectedBoxId }) => selectedBoxId === box.id)
  const isAdmin = useStore(({ user }) => user?.isAdmin)
  const configTableStyle = useStore((state) => (state.configuration.tableStyles.find(
    (style) => style.id === box.tableStyleId
  )))
  const tableStyle = useMemo(() => ({
    ...defaultTableConfiguration, ...configTableStyle
  }), [configTableStyle])

  const { t } = useTranslation();

  const tableRef = useRef()

  const [menuPosition, setmenuPosition] = useState({
    type: null,
    key: null,
  });
  const [theadHeight, setTheadHeight] = useState(0)

  const columns = useMemo(() => {
    if (!(box.content?.columns?.length > 0)) return [];

    return box.content.columns.map((col) => {
      return {
        accessorKey: col.key,
        size: col.width,
      };
    });
  }, [box.content.columns]);

  const initializeColumnSizeVars = useCallback(() => {
    const colSizes = {};
    columns.forEach(({ size, accessorKey }) => {
      colSizes[`--${accessorKey}-size`] = size
    })
    return colSizes
  }, [columns])

  const [columnSizeVars, setColumnSizeVars] = useState(initializeColumnSizeVars())

  const data = useMemo(() => {
    const { rowsSelectionStart: rowStart, rowsSelectionEnd: rowEnd } = box.content;
    return [...box.content?.data?.slice(rowStart - 1 || 0, rowEnd || box.content.data.length)] || []
  }, [box.content]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableColumnResizing: false,
    getRowId: (row) => row.key
  })

  const handleClickInTable = useCallback(
    ({ rowKey, columnKey, type }) => (e) => {
      if (!isSelected) {
        updateBox(box.id, (selectedBox) => {
          selectedBox.selectedItem = { rowKey, columnKey, type }
        });
      }
      e.preventDefault();
      setBoxMovable(false);
      setmenuPosition({
        key: rowKey || columnKey,
        type,
      });
    },
    [box.id, isSelected, setBoxMovable, updateBox]
  );

  const updateTheadHeight = useCallback(() => {
    if (tableRef.current && !!tableRef.current.querySelector("thead"))
      setTheadHeight(tableRef.current.querySelector("thead").getBoundingClientRect().height)
  }, [])

  useEffect(() => {
    updateTheadHeight()
  }, [updateTheadHeight])

  useEffect(() => {
    box.content.columns.forEach((col, index) => {
      if (!col.width) {
        const colWidth = box.width - box.content.columns.reduce((acc, col) => {
          const widthToAdd = col.width ?? 0;
          return acc + widthToAdd;
        }, 0);
        if (colWidth) {
          updateBox(box.id, (selectedBox) => {
            selectedBox.content.columns[index].width = colWidth;
          });
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeof box.tableStyleId === 'undefined') {
      updateBox(box.id, (selectedBox) => {
        selectedBox.tableStyleId = defaultTableStyleId;
      });
    }
  }, [box.id, box.tableStyleId, updateBox, defaultTableStyleId]);

  const convertAlign = useCallback((colAlign) => {
    if (colAlign === 'center') return 'center'
    if (colAlign === "end") return 'right'
    return 'left'
  }, [])

  const isAdminTable = isAdmin && currentDocument.type === 'template' && box.tableName
  const tableWidth = box.content?.columns?.length > 0
    ? box.content.columns.reduce((acc, col) => acc + col.width, 0)
    : box.width;

  useEffect(() => {
    setColumnSizeVars(initializeColumnSizeVars())
  }, [initializeColumnSizeVars]);

  const computeMaxColumnWidth = useCallback((colKey) => {
    const columnsSizeArray = Object.entries(columnSizeVars)
    return columnsSizeArray.reduce((acc, [key, value], idx) => {
      if (key === `--${colKey}-size`) {
        if (idx !== columnsSizeArray.length - 1 && columnsSizeArray[idx + 1][1] > MIN_COLUMN_WIDTH)
          return acc + value + columnsSizeArray[idx + 1][1]
        return acc + value
      }
      return acc
    }, 0)
  }, [columnSizeVars])

  const handleOnClickRefresh = () => {
    fetchTableData({
      fetchStoreFunc: fetchTable,
      updateBoxFunc: updateBox,
      box: {
        ...box,
        tableName: box.tableName,
      }
    });
  }

  return (
    <>
      <div ref={tableRef}
        onDoubleClick={(event) => {
          if (isAVariableBox(box?.type)) {
            updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
          }
          event.stopPropagation()
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          position: 'relative',
          width: tableWidth,
          ...columnSizeVars

        }}>
        {isAdminTable && (
          <table
            style={{
              width: "100%"
            }}
          >
            <thead style={{ backgroundColor: "#c8c8dd", height: 40 }}>
              <tr>
                <th style={{ borderBottom: "1px solid #c0c0d5" }}>
                  {t(box.tableName)}
                </th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "#cccce1", height: 30 }}>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
        )}
        {box.tableName && currentDocument.type !== 'template' && isHovered &&
          <div style={{
            position: 'absolute',
            zIndex: 999,
            top: -12,
            right: -12
          }}>
            <ButtonRefresh handleOnClickRefresh={handleOnClickRefresh} />
          </div>
        }
        {!isAdminTable &&
          <table style={{ width: "100%", tableLayout: 'fixed', }}>
            <TableHead
              drawMode={drawMode}
              table={table}
              box={box}
              columns={box.content.columns}
              isSelected={isSelected}
              boxId={box.id}
              handleClickInTable={handleClickInTable}
              updateTheadHeight={updateTheadHeight}
              tableStyle={tableStyle}
              convertAlign={convertAlign}
              rotation={box.rotation}
              setColumnSizeVars={setColumnSizeVars}
              computeMaxColumnWidth={computeMaxColumnWidth}
            />
            <TableBody
              drawMode={drawMode}
              table={table}
              columns={box.content.columns}
              isSelected={isSelected}
              boxId={box.id}
              box={box}
              handleClickInTable={handleClickInTable}
              tableStyle={tableStyle}
              theadHeight={theadHeight}
              data={data}
              convertAlign={convertAlign}
              computeMaxColumnWidth={computeMaxColumnWidth}
              setColumnSizeVars={setColumnSizeVars}
              rotation={box.rotation}
            />
            <TableFooter
              columnsLength={box.content.columns.length}
              tableStyle={tableStyle}
            />
          </table>
        }
      </div>
      {
        isSelected
          ? ReactDOM.createPortal(
            <NavbarTable
              selectedBox={box}
              menuPosition={menuPosition}
              setMenuPosition={setmenuPosition}
              tableStyle={tableStyle}
            />,
            document.getElementById('ComponentPortal')
          )
          : null
      }
    </>
  )
}

export default Table
