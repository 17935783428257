import React from 'react'
import { useStore } from '../../store/store';
import { columnDefaultDisposition, ItemTypes, ItemTypesForTraduction, MASKS_TYPE, VARIABLE_COLOR, variablesIcons } from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Divider, Tooltip } from 'antd';
import { initializeBoxContent, isADefaultBox, isADrawBox, isAOneRowBox, isATitleBox, isAVariableBox, reinitializeBoxWidthAndHeight } from '../../utils/boxes';
import { useTranslation } from 'react-i18next';
import { computeColumnWidth } from '../../utils/columns';
import { gridUnit } from '../../constants/gridConfig';

const size = 38
const iconFontSize = 25
const secondIconFontSizeReduction = 10

const basisSelector = (state) => ({

  handleUnSelectBox: state.handleUnSelectBox,
  handleUnSelectContainer: state.handleUnSelectContainer,
  setDisplayMargins: state.setDisplayMargins,
  maskEditing: state.maskEditing,
  setMaskEditing: state.setMaskEditing,
});

const selector = (columnId, containerId) => ({
  addContainers,
  updateColumnsInContainer,
  containers,
  gridConfiguration,
  configuration,
  currentDocument,
  boxes,
  landscape,
  copy
}) => {
  const container = columnId || containerId ? containers.find((c) => containerId ?
    c.id === containerId : c.columnsIds.includes(columnId))
    : undefined
  return {
    addContainers,
    updateColumnsInContainer,
    container,
    gridConfiguration,
    configuration,
    titles: boxes.filter((box) => isATitleBox(box.type)),
    currentDocument,
    marginRight: configuration.margins.right * gridUnit,
    marginLeft: configuration.margins.left * gridUnit,
    landscape,
    copy
  }
};

const DynamicContainerIcon = ({ containerType }) => {
  if (containerType === "drawMode") {
    return <span className="fa-layers" style={{ width: '21px' }}>
      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: "white" }} />
      <FontAwesomeIcon icon={icon({ name: 'scribble', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, color: "white" }} />
    </span>
  }
  else if (containerType === "mask") {
    return <span className="fa-layers" style={{ width: '21px' }}>
      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: "white" }} />
      <FontAwesomeIcon icon={icon({ name: 'masks-theater', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, color: "white" }} />
    </span>
  }
  else {
    return <span className="fa-layers" style={{ width: '21px' }}>
      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: "white" }} />
      < FontAwesomeIcon icon={variablesIcons[ItemTypes.CONTAINER_COPIED]} style={{ fontSize: iconFontSize - 12, color: 'white' }} />
    </span>
  }
}

const ItemButtonVariable = ({ type, text, icon, config, bgcolor, disp, label, color = '#000000', onAdderClick, columnId }) => {
  const { maskEditing, handleUnSelectBox, handleUnSelectContainer } = useStore(basisSelector);
  const isInMask = maskEditing.length > 0
  const isInHeaderFooter = isInMask &&
    (maskEditing.includes(MASKS_TYPE.HEADER.id) || maskEditing.includes(MASKS_TYPE.FOOTER.id))
  const disabled = (isInMask && (isADrawBox(type) || isADefaultBox(type) || isAVariableBox(type))) || (isInHeaderFooter && isAOneRowBox(type))


  return (
    <Tooltip
      placement='bottom'
      mouseLeaveDelay='0'
      title={text}
      mouseEnterDelay={0.5}
    >
      <li
        onDragStart={() => {
          handleUnSelectBox();
          handleUnSelectContainer();
        }}
        style={{
          display: disp,
          marginLeft: '5px',
          marginBottom: '8px',
          width: size,
          height: size,
          backgroundColor: bgcolor,
          borderStyle: 'none',
          borderRadius: '5px',
          fontSize: '9px',
          fontFamily: 'Montserrat',
          color,
          textAlign: 'center',
        }}
      >
        <Button
          onClick={() => onAdderClick({ type, config, columnId })}
          style={{
            borderStyle: 'none',
            backgroundColor: bgcolor,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: '100%',
            color,
          }}
          icon={icon}
          disabled={disabled}
        >{label}</Button>
      </li>
    </Tooltip>
  );
};

const ItemButtonEdition = ({ type, text, icon, config, bgcolor, disp, label, color = '#000000', onAdderClick, columnId }) => {
  const { maskEditing, handleUnSelectBox, handleUnSelectContainer } = useStore(basisSelector);
  const isInMask = maskEditing.length > 0
  const isInHeaderFooter = isInMask &&
    (maskEditing.includes(MASKS_TYPE.HEADER.id) || maskEditing.includes(MASKS_TYPE.FOOTER.id))
  const disabled = (isInMask && (isADrawBox(type) || isADefaultBox(type) || isAVariableBox(type))) || (isInHeaderFooter && isAOneRowBox(type))


  return (
    <Tooltip
      placement='bottom'
      mouseLeaveDelay='0'
      title={text}
      mouseEnterDelay={0.5}
    >
      <li
        onDragStart={() => {
          handleUnSelectBox();
          handleUnSelectContainer();
        }}
        style={{
          display: disp,
          marginLeft: '5px',
          marginBottom: '8px',
          width: size,
          height: size,
          backgroundColor: bgcolor,
          borderStyle: 'none',
          borderRadius: '5px',
          fontSize: '9px',
          fontFamily: 'Montserrat',
          color,
          textAlign: 'center',
        }}
      >
        <Button
          onClick={() => onAdderClick({ type, config, columnId })}
          style={{
            borderStyle: 'none',
            backgroundColor: bgcolor,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: '100%',
            color,
          }}
          icon={icon}
          disabled={disabled}
        >{label}</Button>
      </li>
    </Tooltip>
  );
};
const ItemButtonPage = ({ type, text, icon, config, bgcolor, disp, label, color = '#000000', onAdderClick, columnId }) => {
  const { maskEditing, handleUnSelectBox, handleUnSelectContainer } = useStore(basisSelector);
  const isInMask = maskEditing.length > 0
  const isInHeaderFooter = isInMask &&
    (maskEditing.includes(MASKS_TYPE.HEADER.id) || maskEditing.includes(MASKS_TYPE.FOOTER.id))
  const disabled = (isInMask && (isADrawBox(type) || isADefaultBox(type) || isAVariableBox(type))) || (isInHeaderFooter && isAOneRowBox(type))


  return (
    <Tooltip
      placement='bottom'
      mouseLeaveDelay='0'
      title={text}
      mouseEnterDelay={0.5}
    >
      <li
        onDragStart={() => {
          handleUnSelectBox();
          handleUnSelectContainer();
        }}
        style={{
          display: disp,
          marginLeft: '5px',
          marginBottom: '8px',
          width: size,
          height: size,
          backgroundColor: bgcolor,
          borderStyle: 'none',
          borderRadius: '5px',
          fontSize: '9px',
          fontFamily: 'Montserrat',
          color,
          textAlign: 'center',
        }}
      >
        <Button
          onClick={() => onAdderClick({ type, config, columnId })}
          style={{
            borderStyle: 'none',
            backgroundColor: bgcolor,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: '100%',
            color,
          }}
          icon={icon}
          disabled={disabled}
        >{label}</Button>
      </li>
    </Tooltip>
  );
};


const GenerateDivider = ({ text }) => {
  return (
    <Divider
      style={{
        borderColor: 'white',
        color: 'white',
        width: '212px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginTop: '3px',
        marginBottom: '3px',

      }}
      plain
    >
      {text}
    </Divider>
  );
};


const ItemButtonCopied = ({ icon, bgcolor, disp, label, text, onAdderClick, columnId }) => {
  return (
    <Tooltip
      placement='bottom'
      mouseLeaveDelay='0'
      title={text}
      mouseEnterDelay={0.5}
    >
      <li
        style={{
          display: disp,
          marginBottom: '8px',
          width: size,
          height: size,
          backgroundColor: bgcolor,
          borderStyle: 'none',
          borderRadius: '5px',
          fontSize: '9px',
          fontFamily: 'Montserrat',
          color: '#000000',
          textAlign: 'center',
        }}
      >
        <Button
          onClick={() => onAdderClick({ columnId, fromCopied: true })}
          style={{
            borderStyle: 'none',
            backgroundColor: bgcolor,
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center"
          }}
          icon={icon}
        >{label}</Button>
      </li></Tooltip>
  );
};

const dynamicTextIcon = ({ containerType }) => {
  if (containerType === "drawMode") return "drawing"
  if (containerType === "mask") return ItemTypes.MASK
  return ItemTypes.CONTAINER_COPIED
}

const WhatIsCopied = ({ type, containerType = null, onAdderClick, columnId }) => {
  const { gridConfiguration } = useStore(selector);
  const { t } = useTranslation();

  switch (type) {
    case 'text': {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.TEXT}
          text={t('text')}
          icon={< FontAwesomeIcon icon={icon({ name: 'pen-fancy', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'image': {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.IMG}
          text={t('image')}
          icon={< FontAwesomeIcon icon={icon({ name: 'image', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'shape': {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.SHAPE}
          text={t('shape')}
          icon={< FontAwesomeIcon icon={icon({ name: 'star', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'line': {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.LINE}
          text={t('line')}
          icon={< FontAwesomeIcon icon={icon({ name: 'dash', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'table': {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.TABLE}
          text={t('table')}
          icon={< FontAwesomeIcon icon={icon({ name: 'table', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'title1': {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.TITLE}
          text={t('title1')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'title2': {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.TITLE_2}
          text={t('title2')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'title3': {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.TITLE_3}
          text={t('title3')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'title4': {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.TITLE_4}
          text={t('title4')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case 'summary': {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.SUMMARY}
          text={t('summary')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: 'list-ol', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.LINE_BREAK: {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.LINE_BREAK}
          text={t(ItemTypes.LINE_BREAK)}
          icon={< FontAwesomeIcon icon={icon({ name: 'turn-down-left', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.CHECKBOXS_VARIABLE: {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.CHECKBOXS_VARIABLE}
          text={t(ItemTypes.CHECKBOXS_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.CHECKBOXS_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.SELECTOR_VARIABLE: {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.SELECTOR_VARIABLE}
          text={t(ItemTypes.SELECTOR_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.SELECTOR_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.TEXT_VARIABLE: {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.TEXT_VARIABLE}
          text={t(ItemTypes.TEXT_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.TEXT_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.IMG_VARIABLE: {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.IMG_VARIABLE}
          text={t(ItemTypes.IMG_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.IMG_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.TABLE_VARIABLE: {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.TABLE_VARIABLE}
          text={t(ItemTypes.TABLE_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.TABLE_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.SPACE: {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.SPACE}
          text={t(ItemTypes.SPACE)}
          icon={<span className="fa-layers" style={{ width: '21px' }}>
            <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />
            <FontAwesomeIcon icon={icon({ name: 'arrow-up-right-and-arrow-down-left-from-center', style: 'regular' })} style={{ fontSize: iconFontSize - 14, transform: 'rotate(135deg)', color: 'white' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    case ItemTypes.CONTAINER_COPIED: {
      return (
        <ItemButtonCopied
          onAdderClick={onAdderClick}
          columnId={columnId}
          type={ItemTypes.CONTAINER_COPIED}
          text={t(dynamicTextIcon({ containerType }))}
          icon={DynamicContainerIcon({ containerType })}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    default:
      break;
  }
};

const CopyItem = ({ copy, onAdderClick, columnId }) => {

  let containerType = null
  if (copy?.drawMode) containerType = 'drawMode'
  if (copy?.maskId) containerType = 'mask'

  return (
    <div
      style={{
        width: size,
        height: size,
        marginLeft: '5px',
      }}
    >
      {WhatIsCopied({ type: ItemTypesForTraduction[copy.type], containerType, onAdderClick, columnId })}
    </div>
  );
};

const RenderCopiedElement = ({ copy, onAdderClick, columnId }) => {
  if (copy != null && columnId && (copy.type === ItemTypes.SHAPE || copy.type === ItemTypes.LINE || copy.type === ItemTypes.IMG || copy.type === ItemTypes.TEXT || copy.type === ItemTypes.CHECKBOXS_VARIABLE || copy.type === ItemTypes.TEXT_VARIABLE || copy.type === ItemTypes.SELECTOR_VARIABLE)) {
    return (
      <>
        <GenerateDivider text='Élément copié' />
        <CopyItem copy={copy} onAdderClick={onAdderClick} columnId={columnId} />
      </>
    )
  } else if (copy !== null && !columnId) {
    return <>
      <GenerateDivider text='Élément copié' />
      <CopyItem copy={copy} onAdderClick={onAdderClick} columnId={columnId} />
    </>
  }
  else return <></>
}

const AdderMenu = ({ containerIndex, closeMenu, columnId, columnIndex, containerId }) => {
  const { t } = useTranslation();
  const { addContainers, updateColumnsInContainer, container, gridConfiguration, currentDocument, configuration, titles, landscape, marginLeft, marginRight, copy } = useStore(selector(columnId, containerId));
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))

  const onAdderClick = ({ type, config, columnId, fromCopied = false }) => {
    let index;
    if (containerIndex === 0) { index = 0 } else {
      index = containerIndex
    }
    if (fromCopied && copy.type === ItemTypes.CONTAINER_COPIED) {

      let newBoxes = copy.boxes.map((b) => initializeBoxContent({
        box: b,
        configuration,
        titles,
      }));

      addContainers({
        index,
        drawMode: copy.drawMode,
        columnsSizes: copy.drawMode ? [1] : copy.boxes.map((b) => b.colSize),
        box: newBoxes.map(({ colSize, ...rest }) => rest),
        fromCopiedContainer: { container_height: copy.copiedContainer.height, maskId: copy.maskId }
      })
      closeMenu();
      return
    }

    const box = (copy && fromCopied) ? copy : { type, ...config[type], copied: false }

    if (columnId || containerId) {
      updateColumnsInContainer({
        columnsSizes: columnDefaultDisposition[container.columnsIds.length + 1],
        index: columnIndex,
        containerId: container.id,
        newBoxes: [box]
      });
      closeMenu();
      return;
    }

    let newBox = initializeBoxContent({
      box,
      configuration,
      titles,
    });
    const drawMode = isADrawBox(box.type);

    if (!drawMode) {
      newBox = reinitializeBoxWidthAndHeight({
        box: newBox,
        columnWidth: computeColumnWidth({
          landscape,
          marginRight,
          marginLeft
        }),
        onCreate: true,
        header,
        footer
      })
    }

    addContainers({
      index,
      drawMode,
      box: newBox,
    })
    closeMenu();
  }

  return (
    <div style={{
      position: "absolute",
      top: "calc(100%)",
      padding: 16,
      boxShadow: "rgb(94, 94, 94) 0px 0px 15px -4.6px",
      display: "flex",
      flexDirection: "column",
      gap: 32,
      borderRadius: 12,
      width: 250,
      zIndex: 1001,
      backgroundColor: "#0B2239"
    }}>
      <div style={{
        display: "flex", alignItems: "baseline", whiteSpace: "nowrap", gap: 10, flexDirection: "column"
      }}>
        <div style={{ fontSize: 15, display: "flex", justifyContent: "center", width: "100%", color: "white" }}>{columnId ? "Ajouter une colonne" : "Ajouter une ligne"}</div>

        <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>

          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <RenderCopiedElement copy={copy} onAdderClick={onAdderClick} columnId={columnId} />
              <GenerateDivider text='Eléments disponibles' />
              {!columnId && <>
                {/* <GenerateDivider text='Mise en page' /> */}
                <div style={{ display: "flex", flexWrap: "wrap", flex: "1 1 '17%'" }}>
                  <ItemButtonPage
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.SECTION}
                    text={t('section')}
                    icon={< FontAwesomeIcon icon={icon({ name: 'rectangle-history-circle-plus', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                    config={gridConfiguration}
                    bgcolor='#FFFFFF'
                    disp='inline-block'
                  />
                  <ItemButtonPage
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.LINE_BREAK}
                    text={t('line_break')}
                    icon={< FontAwesomeIcon icon={icon({ name: 'turn-down-left', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                    config={gridConfiguration}
                    bgcolor='#FFFFFF'
                    disp='inline-block'
                  />
                  <ItemButtonPage
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.SPACE}
                    text={t('space')}
                    icon={<span className="fa-layers" style={{ width: '21px' }}>
                      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                      <FontAwesomeIcon icon={icon({ name: 'arrow-up-right-and-arrow-down-left-from-center', style: 'regular' })} style={{ fontSize: iconFontSize - 14, transform: 'rotate(135deg)' }} />
                    </span>}
                    config={gridConfiguration}
                    bgcolor='#FFFFFF'
                    disp='inline-block'
                  />
                  <ItemButtonPage
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.MASK}
                    text={t('mask')}
                    icon={<span className="fa-layers" style={{ width: '21px' }}>
                      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                      <FontAwesomeIcon icon={icon({ name: 'masks-theater', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
                    </span>}
                    config={gridConfiguration}
                    bgcolor='#FFFFFF'
                    disp='inline-block'
                  />
                  <ItemButtonPage
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.DRAW}
                    text={t('drawing')}
                    icon={<span className="fa-layers" style={{ width: '21px' }}>
                      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                      <FontAwesomeIcon icon={icon({ name: 'scribble', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
                    </span>}
                    config={gridConfiguration}
                    bgcolor='#FFFFFF'
                    disp='inline-block'
                  />
                  <ItemButtonPage
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.TITLE}
                    text={t('title1')}
                    icon={<span className="fa-layers" style={{ width: '21px' }}>
                      <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                      <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
                    </span>}
                    config={gridConfiguration}
                    bgcolor='#FFFFFF'
                    disp='inline-block'
                  />
                  <ItemButtonPage
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.TITLE_2}
                    text={t('title2')}
                    icon={<span className="fa-layers" style={{ width: '21px' }}>
                      <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                      <FontAwesomeIcon icon={icon({ name: '2', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
                    </span>}
                    config={gridConfiguration}
                    bgcolor='#FFFFFF'
                    disp='inline-block'
                  />
                  <ItemButtonPage
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.TITLE_3}
                    text={t('title3')}
                    icon={<span className="fa-layers" style={{ width: '21px' }}>
                      <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                      <FontAwesomeIcon icon={icon({ name: '3', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
                    </span>}
                    config={gridConfiguration}
                    bgcolor='#FFFFFF'
                    disp='inline-block'
                  />
                  <ItemButtonPage
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.TITLE_4}
                    text={t('title4')}
                    icon={<span className="fa-layers" style={{ width: '21px' }}>
                      <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                      <FontAwesomeIcon icon={icon({ name: '4', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
                    </span>}
                    config={gridConfiguration}
                    bgcolor='#FFFFFF'
                    disp='inline-block'
                  />
                  <ItemButtonPage
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.SUMMARY}
                    text={t('summary')}
                    icon={<span className="fa-layers" style={{ width: '21px' }}>
                      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                      <FontAwesomeIcon icon={icon({ name: 'list-ol', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
                    </span>}
                    config={gridConfiguration}
                    bgcolor='#FFFFFF'
                    disp='block'
                  />
                </div>
              </>}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {/* <GenerateDivider text='Édition' /> */}
              <ItemButtonEdition
                onAdderClick={onAdderClick}
                columnId={columnId}
                type={ItemTypes.TEXT}
                text={t('text')}
                icon={< FontAwesomeIcon icon={icon({ name: 'pen-fancy', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                config={gridConfiguration}
                bgcolor='#FFFFFF'
                disp='inline-block'
              />{!columnId && <>
                <ItemButtonEdition
                  onAdderClick={onAdderClick}
                  columnId={columnId}
                  type={ItemTypes.TABLE}
                  text={t('table')}
                  icon={< FontAwesomeIcon icon={icon({ name: 'table', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                /></>}
              <ItemButtonEdition
                onAdderClick={onAdderClick}
                columnId={columnId}
                type={ItemTypes.IMG}
                text={t('image')}
                icon={< FontAwesomeIcon icon={icon({ name: 'image', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                config={gridConfiguration}
                bgcolor='#FFFFFF'
                disp='inline-block'
              />
              <ItemButtonEdition
                onAdderClick={onAdderClick}
                columnId={columnId}
                type={ItemTypes.SHAPE}
                text={t('shape')}
                icon={< FontAwesomeIcon icon={icon({ name: 'star', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                config={gridConfiguration}
                bgcolor='#FFFFFF'
                disp='inline-block'
              />
              <ItemButtonEdition
                onAdderClick={onAdderClick}
                columnId={columnId}
                type={ItemTypes.LINE}
                text={t('line')}
                icon={< FontAwesomeIcon icon={icon({ name: 'dash', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                config={gridConfiguration}
                bgcolor='#FFFFFF'
                disp='inline-block'
              />
              {!columnId && <>
                <ItemButtonEdition
                  onAdderClick={onAdderClick}
                  columnId={columnId}
                  type={ItemTypes.FILE_PDF}
                  text={"PDF"}
                  icon={< FontAwesomeIcon icon={icon({ name: 'file-pdf', style: 'light' })} style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor='#FFFFFF'
                  disp='inline-block'
                />
              </>}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {/* <GenerateDivider text='Variables' /> */}

              {currentDocument.type === 'template' &&
                <><ItemButtonVariable
                  onAdderClick={onAdderClick}
                  columnId={columnId}
                  type={ItemTypes.TEXT_VARIABLE}
                  text={t('text_variable')}
                  icon={< FontAwesomeIcon
                    icon={variablesIcons[ItemTypes.TEXT_VARIABLE]}
                    style={{ fontSize: iconFontSize }} />}
                  config={gridConfiguration}
                  bgcolor={VARIABLE_COLOR}
                  disp='inline-block'
                  color="white"
                />
                  {!columnId && <>
                    <ItemButtonVariable
                      onAdderClick={onAdderClick}
                      columnId={columnId}
                      type={ItemTypes.SECTION_VARIABLE}
                      text={t('section_variable')}
                      icon={< FontAwesomeIcon
                        icon={variablesIcons[ItemTypes.SECTION_VARIABLE]}
                        style={{ fontSize: iconFontSize }} />}
                      config={gridConfiguration}
                      bgcolor={VARIABLE_COLOR}
                      disp='inline-block'
                      color="white"
                    />
                  </>}
                  <ItemButtonVariable
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.SELECTOR_VARIABLE}
                    text={t('selector_variable')}
                    icon={< FontAwesomeIcon
                      icon={variablesIcons[ItemTypes.SELECTOR_VARIABLE]}
                      style={{ fontSize: iconFontSize }} />}
                    config={gridConfiguration}
                    bgcolor={VARIABLE_COLOR}
                    disp='inline-block'
                    color="white"
                  />
                  <ItemButtonVariable
                    onAdderClick={onAdderClick}
                    columnId={columnId}
                    type={ItemTypes.CHECKBOXS_VARIABLE}
                    text={t('checkboxs_variable')}
                    icon={< FontAwesomeIcon
                      icon={variablesIcons[ItemTypes.CHECKBOXS_VARIABLE]}
                      style={{ fontSize: iconFontSize }} />}
                    config={gridConfiguration}
                    bgcolor={VARIABLE_COLOR}
                    disp='inline-block'
                    color="white"
                  />
                </>}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default AdderMenu
