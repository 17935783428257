import React, { forwardRef } from 'react'

const FloatyStylingWrapper = forwardRef(function FloatyStylingWrapper({
  menuPosition,
  children
}, ref) {
  if (menuPosition.top === null || menuPosition.left === null) return null

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        zIndex: 9998,
        background: '#0b2239',
        padding: '4px 6px',
        borderRadius: 8,
        ...menuPosition
      }}
      onMouseDown={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      {children}
    </div>
  )
})

export default FloatyStylingWrapper
