import React, { createContext, useMemo } from 'react';

const EditorContext = createContext();

const useEditor = () => {
  const context = React.useContext(EditorContext);

  if (!context) {
    return { setEditorState: null }
  }

  return context;
};

const EditorProvider = ({ children, setEditorState, editorState, readOnly }) => {

  const value = useMemo(() => {
    return { setEditorState, editorState, readOnly }
  }, [setEditorState, editorState, readOnly])

  return (
    <EditorContext.Provider value={value}>
      {children}
    </EditorContext.Provider>
  );
};

export { EditorProvider, useEditor };
