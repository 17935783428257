// Axios
import { instance } from './axios.instance';

export const createCustomTextVariable = async ({ name }) => {
  const { data } = await instance.post('/custom_text_variable/create', {
    custom_text_variable: { name },
  });
  return data;
};

export const updateCustomTextVariable = async ({ name, id }) => {
  const { data } = await instance.post('/custom_text_variable/update', {
    id,
    custom_text_variable: { name },
  });
  return data;
};
