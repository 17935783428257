import { faCirclePlus, faTrashXmark } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useState } from 'react'
import { antdFormError } from '../../constants/constants'

const ButtonCommon = ({ type, onMouseEnter, onMouseLeave, style, icon, children, disabled, ...props }) => {
  const [isHovered, setIsHovered] = useState(false)

  const getIcon = () => {
    if (icon) return icon
    if (type === "add") {
      return <FontAwesomeIcon icon={faCirclePlus} />
    }
    if (type === "delete") {
      return <FontAwesomeIcon icon={faTrashXmark} />
    }
    return undefined
  }

  const handleOnMouseEnter = () => {
    setIsHovered(true);
    if (typeof onMouseEnter === "function")
      onMouseEnter()
  }


  const handleOnMouseLeave = () => {
    setIsHovered(false);
    if (typeof onMouseLeave === "function")
      onMouseLeave()
  }

  const getBgColor = () => {
    if (type === 'cancel') {
      if (isHovered) return "#858585"
      if (disabled) return "#F5F5F5"
      return "#B8B8B8"
    }
    if (!(disabled || isHovered)) {
      return 'white'
    }
    if (type === 'delete') {
      return antdFormError
    }
    return '#2166ab'
  }

  const getColor = () => {
    if (type === 'cancel') {
      return "white"
    }
    if (disabled || isHovered) {
      return 'white'
    }
    if (type === 'delete') {
      return antdFormError
    }
    return '#2166ab'
  }

  const getStyle = () => {
    const newStyle = {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      backgroundColor: getBgColor(),
      color: getColor(),
      borderRadius: '30px',
      border: `1.5px solid ${getBgColor()}`,
      opacity: disabled ? 0.4 : 1,
    }
    if (style) {
      return {
        ...newStyle,
        ...style
      }
    }

    return newStyle
  }

  return (
    <Button
      icon={getIcon()}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      style={getStyle()}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  )
}

export default ButtonCommon
