import React from 'react';
import { useTranslation } from 'react-i18next';

// ANT Design
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const TemplateActions = ({ record, deleteTemplate }) => {
  const { t } = useTranslation();

  return (
    <Popconfirm
      title={t('delete-template')}
      onConfirm={() => deleteTemplate(record.id)}
      okText={t('yes')}
      cancelText={t('cancel')}
    >
      <Button danger type='text'>
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  );
};

export default TemplateActions;
