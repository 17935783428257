import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Layer, Stage } from 'react-konva';
import { SHAPES } from '../../constants/constants';
import { useStore } from '../../store/store';
import { iconButtonStyle } from '../../utils/styles';
import { NavbarButton } from '../Layout/NavbarButton';
import { NavbarInputNumber } from '../Layout/NavbarInputNumber';
import NavbarSelector from '../Layout/NavbarSelector';
import './../common/Utils.css';
import { getShapeComponent, LOCK_PROPORTIONS_SHAPES, shapeRatios } from './shapeUtils';

const selector =
  (boxId) =>
    ({ updateBox, selectedBoxId, landscape, setBoxMovable }) => {
      return {
        updateBox,
        isSelected: selectedBoxId === boxId,
        landscape,
        setBoxMovable,
      };
    };

const Shape = ({ box }) => {
  const { isSelected } = useStore(selector(box.id));

  return (
    <>
      <Stage width={box.width} height={box.height}>
        <Layer>{getShapeComponent(box)}</Layer>
      </Stage>
      {isSelected
        ? ReactDOM.createPortal(
          <ShapeComponent selectedBox={box} />,
          document.getElementById('ComponentPortal')
        )
        : null}
    </>
  );
};

export const ShapeComponent = ({ selectedBox }) => {
  const boxId = selectedBox?.id;
  const { t } = useTranslation();
  const { updateBox } = useStore(selector(boxId));

  const isAnArrow = selectedBox?.type === 'shape' && selectedBox?.content.shape === 'arrow';

  // update which shape
  const selectShape = (shapeId) => {
    updateBox(boxId, (box) => {
      if (shapeId === 'arrow') {
        box.height = box.stroke + box.arrows.stroke + 20
      } else if (LOCK_PROPORTIONS_SHAPES.includes(shapeId)) {
        box.height = box.width * shapeRatios(shapeId);
      }
      box.content.shape = shapeId;
    });
  };

  const handleArrowsChangement = (place) => {
    if (
      selectedBox.arrows[place] &&
      ((place === 'left' && selectedBox.arrows.right === false) ||
        (place === 'right' && selectedBox.arrows.left === false))
    ) {
      return;
    }
    updateBox(boxId, (selectedBox) => {
      selectedBox.arrows[place] = !selectedBox.arrows[place];
    });
  };

  const handleChangeBordersSize = (value) => {
    updateBox(boxId, (selectedBox) => {
      selectedBox.borders.size = value;
    });
  };

  return (
    <div className='navbar__formshape-properties-container'>
      <div className='navbar__fstop-properties-container'>
        <NavbarSelector
          key="shapeTypeSelector"
          tooltipContent={t('shape')}
          disabled={selectedBox?.type !== 'shape'}
          suffixIcon={< FontAwesomeIcon icon={icon({ name: 'vector-polygon', style: 'light' })} />}
          onSelect={selectShape}
          selectedValue={selectedBox.content.shape}
          options={SHAPES.map(({ id, name }) => ({ label: t(name), value: id }))}
        />
        <NavbarButton
          tooltipContent={t('left-carret')}
          onClick={() => handleArrowsChangement('left')}
          disabled={!isAnArrow}
          icon={< FontAwesomeIcon icon={icon({ name: 'caret-left', style: 'light' })} style={iconButtonStyle(isAnArrow && selectedBox.arrows['left'])} />}
        />
        <NavbarButton
          tooltipContent={t('right-carret')}
          onClick={() => handleArrowsChangement('right')}
          disabled={!isAnArrow}
          icon={< FontAwesomeIcon icon={icon({ name: 'caret-right', style: 'light' })} style={iconButtonStyle(isAnArrow && selectedBox.arrows['right'])} />}
        />
        <NavbarInputNumber
          min={1}
          max={30}
          disabled={selectedBox?.content?.shape !== 'arrow'}
          tooltipContent={t('line-stroke')}
          value={selectedBox?.stroke}
          onChange={(v) =>
            updateBox(selectedBox.id, (selectedBox) => {
              selectedBox.stroke = v;
              selectedBox.height = selectedBox.stroke + selectedBox.arrows.stroke + 20
            })
          }
          defaultValue={1}
        />
        <NavbarInputNumber
          min={1}
          max={30}
          disabled={selectedBox?.content?.shape !== 'arrow'}
          tooltipContent={t('arrow-stroke')}
          value={selectedBox?.arrows?.stroke}
          onChange={(v) => {
            updateBox(selectedBox.id, (selectedBox) => {
              selectedBox.arrows.stroke = v;
              selectedBox.height = selectedBox.stroke + selectedBox.arrows.stroke + 20
            })
          }}
          defaultValue={1}
        />
        <NavbarInputNumber
          min={1}
          max={40}
          disabled={selectedBox?.content?.shape !== 'arrow'}
          tooltipContent={t('arrow-strokelength')}
          value={selectedBox?.arrows?.strokeLength}
          onChange={(v) => {
            updateBox(selectedBox.id, (selectedBox) => {
              selectedBox.arrows.strokeLength = v;
            })
          }}
          defaultValue={1}
        />
        <NavbarInputNumber
          min={0}
          max={30}
          disabled={selectedBox?.content?.shape === 'arrow'}
          tooltipContent={t("border's-size")}
          value={selectedBox?.borders?.size}
          onChange={handleChangeBordersSize}
          defaultValue={0}
        />
      </div>
    </div>
  );
};

export default React.memo(Shape);
