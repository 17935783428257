export const getTitleStyle = (backgroundColor, border, style, color, rest) => {
  return {
    ...style,
    ...rest,
    color,
    width: '100%',
    display: 'flex',
    flexDirection: 'inline',
    backgroundClip: 'content-box',
    backgroundColor:
      backgroundColor?.label !== 'transparent' ? backgroundColor?.style : null,
    borderTop:
      border?.top === true
        ? border?.size + 'px solid ' + border?.color?.style
        : null,
    borderBottom:
      border?.bottom === true
        ? border?.size + 'px solid ' + border?.color.style
        : null,
    borderLeft:
      border?.left === true
        ? border?.size + 'px solid ' + border?.color.style
        : null,
    borderRight:
      border?.right === true
        ? border?.size + 'px solid ' + border?.color.style
        : null,
  };
};
