import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// ANT Design
import { Button, Input, Popconfirm, Select, Switch, Modal } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { marginOnPageButton } from '../../constants/gridConfig';
import { useStore } from '../../store/store';
import { actionButtonBottomMargin } from '../../utils/styles';
import DocumentTypesSelector from '../common/DocumentTypesSelector';
const { Option } = Select;

const selector = (state) => ({
  user: state?.user,
  currentTab: state.currentTab,
});

const NewTemplateModal = ({ open, handleOk, handleCancel, type }) => {
  const { user, currentTab } = useStore(selector);
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [templatable, setTemplatable] = useState('opportunity');
  const [templatePattern, setTemplatePattern] = useState(currentTab);
  const [landscape, setLandscape] = useState(false);
  const [work, setWork] = useState(user.works[0]);
  const [documentTypeIds, setDocumentTypeIds] = useState([]);

  const changeTemplatePatternValue = () => {
    setTemplatePattern(!templatePattern);
  };

  const changeTemplatableValue = () => {
    setTemplatable(templatable === 'opportunity' ? 'offer' : 'opportunity');
  };

  const handleLandscapeButton = () => {
    setLandscape(!landscape);
  };

  useEffect(() => {
    if (templatable === 'offer') {
      setWork(null);
    }
  }, [templatable, templatePattern]);

  return (
    <Modal
      title={`${t('new-name')} ${type}`}
      open={open}
      onCancel={handleCancel}
      cancelText={t('cancel')}
      okText={t('create')}
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('cancel')}
        </Button>,
        <Popconfirm
          title={t('changes-not-saved')}
          onConfirm={() => {
            handleOk({ name, templatable, templatePattern, landscape, work, documentTypeIds });
            setName('');
          }}
          cancelText={t('cancel')}
          okText={t('continue')}
        >
          <Button
            style={{ marginTop: 10, marginLeft: 0 }}
            disabled={name === '' ? true : false}
          >
            {t('create')}
          </Button>
        </Popconfirm>,
      ]}
    >
      <Input
        placeholder={t(type)}
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
          gap: marginOnPageButton,
        }}
      >
        <div
          style={{ display: 'flex', marginBottom: actionButtonBottomMargin }}
          className='--hide-from-pdf'
        >
          <p style={{ marginRight: '3.5%', marginBottom: 0, fontSize: 15 }}>
            {t('opportunity')}
          </p>
          <Switch
            checked={templatable === 'offer'}
            onChange={changeTemplatableValue}
          />
          <p style={{ marginLeft: '3.5%', marginBottom: 0, fontSize: 15 }}>
            {t('offer')}
          </p>
        </div>
        <div
          style={{ display: 'flex', marginBottom: actionButtonBottomMargin }}
          className='--hide-from-pdf'
        >
          <p style={{ marginRight: '0.5rem', marginBottom: 0, fontSize: 15 }}>
            {t('portrait-mode')}
          </p>
          <Switch checked={landscape} onChange={handleLandscapeButton} />
          <p style={{ marginLeft: '3.5%', marginBottom: 0, fontSize: 15 }}>
            {t('landscape')}
          </p>
        </div>
        <div
          style={{ display: 'flex', marginBottom: actionButtonBottomMargin }}
          className='--hide-from-pdf'
        >
          <p style={{ marginRight: '0.5rem', marginBottom: 0, fontSize: 15 }}>
            {t('is_content')}
          </p>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={templatePattern}
            onChange={changeTemplatePatternValue}
          />
        </div>
        {templatable !== 'offer' ? (
          <div style={{ display: 'flex', marginBottom: actionButtonBottomMargin }}>
            <div>Choix du métier</div>
            <Select
              value={work}
              onChange={(e) => setWork(e)}
              placeholder='Sélectionner un métier'
              style={{ minWidth: '170px', marginLeft: '2%' }}
            >
              {user.works.map((item) => {
                return <Option value={item}>{t(item)}</Option>;
              })}
            </Select>
          </div>
        ) : null}
        <div
          style={{ display: 'flex' }}
          className='--hide-from-pdf'
        >
          <p style={{ marginRight: '0.5rem', marginBottom: 0, fontSize: 15 }}>
            {t('tags')}
          </p>
          <DocumentTypesSelector value={documentTypeIds}
            onChange={(e) => setDocumentTypeIds(e)} />
        </div>
      </div>
    </Modal>
  );
};

export default NewTemplateModal;
