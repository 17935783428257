import React, { useEffect, useState } from 'react'
import { useStore } from '../../../store/store';
import { Input } from 'antd';

const TriggerValueInput = ({ box, value, record }) => {
  const [inputValue, setInputValue] = useState(value)
  const updateTrigger = useStore(({ updateTrigger }) => updateTrigger)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const updateValue = (e) => {
    updateTrigger(record.id, box.id, { value: e.target.value ?? null })
  }

  const updateInputValue = (e) => {
    setInputValue(e.target.value)
  }

  return (
    <div style={{
      display: 'flex',
      width: "100%"
    }}>
      <Input.TextArea onChange={updateInputValue} onBlur={updateValue} value={inputValue} disabled={record.from_linked_section} />
    </div>
  )
}

export default TriggerValueInput
