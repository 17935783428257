import React, { useCallback, useEffect, useRef, useState } from 'react'

const MenuWrapper = ({ startOffset, children, hide }) => {
  const ref = useRef(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  const updateMenuPosition = useCallback(() => {
    const selection = window.getSelection && window.getSelection();
    if (selection && selection.rangeCount > 0) {
      let selectionRange = selection.getRangeAt(0);
      if (selectionRange) {
        const leftPosition = selectionRange.getBoundingClientRect().left;
        const left = ref.current?.offsetWidth && leftPosition + ref.current.offsetWidth >= window.innerWidth ?
          window.innerWidth - ref.current.offsetWidth : leftPosition
        setMenuPosition({ left, top: selectionRange.getBoundingClientRect().top + 16 })
      }
    }
  }, []);

  useEffect(() => {
    updateMenuPosition()
  }, [updateMenuPosition, startOffset])

  return (
    <ol
      ref={ref}
      className='no-scrollbar'
      style={{
        position: "absolute",
        ...menuPosition,
        zIndex: 9998,
        backgroundColor: "#fff",
        // eslint-disable-next-line no-multi-str
        boxShadow: "0 1px 1px hsl(0deg 0% 0% / 0.075),\
      0 2px 2px hsl(0deg 0% 0% / 0.075),\
      0 4px 4px hsl(0deg 0% 0% / 0.075),\
      0 8px 8px hsl(0deg 0% 0% / 0.075),\
      0 16px 16px hsl(0deg 0% 0% / 0.075)",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        borderRadius: 8,
        height: 300,
        minWidth: 350,
        overflowY: "scroll",
        listStyleType: "none",
        visibility: hide ? 'hidden' : 'visible'
      }}
    >
      {children}
    </ol>
  )
}

export default MenuWrapper;
