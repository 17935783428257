import { useEffect } from 'react';
import './Layout.css';
import { Layout as AntLayout, ConfigProvider } from 'antd';
import RightSide from '../RightSide/RightSide';
import LeftSide from '../LeftSide/LeftSide';
import Navbar from './Navbar';
import EventManager from '../common/EventManager';
import { useStore } from '../../store/store';
import { PRIMARY_COLOR, containerBackground, pageBackground } from '../../constants/constants';
import Middle from '../Middle/Middle';
import { OverlayProvider } from '../../contexts/OverlayContext';

const { Content } = AntLayout;
const selector = ({
  proposalList,
  currentDocument,
  handleProposalList,
  handleMaskList,
  handleUnSelectBox,
  handleUnSelectContainer,
  currentProposal
}) => ({
  proposalList,
  currentDocument,
  currentProposal,
  handleProposalList,
  handleMaskList,
  handleUnSelectBox,
  handleUnSelectContainer
});

const Layout = () => {
  const { proposalList, currentDocument, currentProposal, handleProposalList, handleMaskList, handleUnSelectBox, handleUnSelectContainer } =
    useStore(selector);

  useEffect(() => {
    if (currentDocument.type === 'template' || currentProposal.id) {
      handleProposalList(false);
      handleMaskList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: PRIMARY_COLOR,
        },
      }}
    >
      <OverlayProvider>
        <EventManager>
          <AntLayout
            data-role={pageBackground}
            onClick={(event) => {
              var selection = window.getSelection();
              if (!(event.ctrlKey || event.metaKey) && event.target.dataset.role === pageBackground &&
                selection.type !== "Range" && selection.type !== "None") {
                handleUnSelectBox();
                if (event.target.dataset.parent !== containerBackground) {
                  handleUnSelectContainer()
                }
              }
            }}>
            <Navbar />
            <Content style={{ zIndex: 1 }}>
              <Middle />
            </Content>
            {!proposalList && <LeftSide />}
            {!proposalList && <RightSide />}
            <div id="SelectionVariablePortal" />
            <div id="MenuBarPortal" />
          </AntLayout>
        </EventManager>
      </OverlayProvider>
    </ConfigProvider>
  );
};

export default Layout;
