import produce from 'immer';
import { ItemTypes } from '../constants/constants';
import { isATitleBox } from '../utils/boxes';

export const titlesActions = (set, get) => ({
  getSummaryBoxes: () =>
    get()
      .boxes.filter((box) =>
        [ItemTypes.TITLE, ItemTypes.TITLE_2].includes(box.type)
      )
      .map((b) => ({
        ...b,
        text: b.content.editorState.getCurrentContent().getPlainText(),
        indexTitle: b.content.indexTitle,
      })),
 unCollapseTitles: ({index}) => {
    set(
      produce((draft) => {
        draft.boxes.map((box) => {
          if(isATitleBox(box.type) && box?.content?.indexTitle?.[0] === index) {
            box.collapsed = false;
          }
        }
        )
      }),
      false,
      `unCollapseTitles`
    );
  },
});
