import React, { useCallback, useMemo } from "react";
import { useStore } from "../../store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styleCheckbox } from "../../constants/constants";

const selector = (box) =>
  ({ updateBox, selectedBoxId, configuration, }) => {
    return {
      isSelected: selectedBoxId === box?.id,
      updateBox: box?.updateBox ?? updateBox,
      configuration,
    };
  };

export default function CheckboxCustom({
  getStyleOnCheckbox,
  label,
  checked,
  disabled = false,
  box,
  idx,
  selectedStyleId,
  maxNumberOfAnwser }) {
  const {
    updateBox,
    configuration,
  } = useStore(selector(box));

  const currentStyle = useCallback(() => {
    if (box) {
      return configuration.checkboxStyles.find((ts) => ts.id === box?.checkboxStyleId)
    } else {
      return configuration.checkboxStyles.find((ts) => ts.id === selectedStyleId)
    }
  }, [box, configuration.checkboxStyles, selectedStyleId])

  const labelStyle = useMemo(() => {

    return {
      wordWrap: 'anywhere',
      wordBreak: 'normal',
      whiteSpace: "break-spaces",
      flex: 1,
      overflow: 'hidden',
      textDecoration: currentStyle().textDecoration,
      fontStyle: currentStyle().fontStyle,
    }
  }, [currentStyle])


  return (
    <div style={{
      display: 'flex',
      columnGap: currentStyle()?.label?.margin,
      alignItems: 'center',
      zIndex: 1000,
      flex: 1,
      direction: currentStyle()?.box?.direction,
    }}
    >
      <div style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <input type="checkbox"
          style={{
            position: 'absolute',
            opacity: 0,
            zIndex: 1,
            width: 'auto',
            inset: 0
          }}
          disabled={disabled}
          checked={checked}
          onChange={box ? () => updateBox(box.id, (box) => {
            box.checkboxs[idx].checked = !checked;
            if (maxNumberOfAnwser === 1) {
              box.checkboxs = box.checkboxs.map((c) => c.id !== box.checkboxs[idx].id
                ? { ...c, checked: false }
                : c
              );
            }
          }) : undefined}
        />
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          color: currentStyle()?.check?.color?.style,
          fontSize: `${currentStyle()?.check?.height}px`,
          fontWeight: `${currentStyle()?.check?.weight}`,
          minWidth: Math.max(currentStyle()?.check?.height, currentStyle()?.box?.width)
        }}>
          <div style={{
            ...getStyleOnCheckbox(),
          }}>
          </div>
          {checked &&
            <FontAwesomeIcon
              icon={styleCheckbox[currentStyle()?.styleBox]}
              style={{ position: 'absolute' }}
            />
          }
        </div>
      </div>
      <div style={labelStyle}
        className="checkbox-variable__label"
      >
        {label}
      </div>
    </div>
  )
}
