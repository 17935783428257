import { maxLeftBounds, maxTopBounds } from '../constants/gridConfig';

import {
  BORDER_GRAY,
  PRIMARY_COLOR,
  RESIZING_BORDER,
  BG_BADGE,
  VALID_GREEN,
} from '../constants/constants';
import { marginOnPageButton } from '../constants/gridConfig';
import { boxHeight } from './boxes';

export const actionButtonBottomMargin = 8;

export const getBackgroundBorderStyle = (
  backgroundColor,
  border,
  style = {}
) => {
  return {
    ...style,
    width: '100%',
    backgroundClip: 'content-box',
    borderRadius: border?.radius + 'px',
    backgroundColor:
      backgroundColor?.label !== 'transparent' ? backgroundColor?.style : null,
    borderTop:
      border?.top === true
        ? border?.size + 'px solid ' + border?.color?.style
        : null,
    borderBottom:
      border?.bottom === true
        ? border?.size + 'px solid ' + border?.color?.style
        : null,
    borderLeft:
      border?.left === true
        ? border?.size + 'px solid ' + border?.color?.style
        : null,
    borderRight:
      border?.right === true
        ? border?.size + 'px solid ' + border?.color?.style
        : null,
  };
};

export const navbarElementStyle = {
  padding: 0,
  heigth: 32,
  marginRight: marginOnPageButton,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
};

export const navbarButtonStyle = {
  ...navbarElementStyle,
  width: 32,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: BORDER_GRAY,
  borderRadius: '5px',
  backgroundColor: 'white',
};

export const checkOffset = [-10, 8];
export const checkStyle = {
  fontSize: 14,
  color: VALID_GREEN,
  backgroundColor: BG_BADGE,
  borderRadius: 7,
};

export const iconButtonStyle = (active = false) => ({
  fontSize: 20,
  margin: 0,
  color: active ? PRIMARY_COLOR : 'black',
});

export const getPositionStyles = (box, isDragging) => {
  let { top, left } = box;
  if (left < maxLeftBounds) left = maxLeftBounds;
  if (top < maxTopBounds) top = maxTopBounds;
  let transform = `translate3d(${left}px, ${top}px, 0)`;
  if (isDragging) {
    const offsetMove = getRotationMove(box);
    transform = `translate3d(${left - offsetMove.x}px, ${
      top - offsetMove.y
    }px, 0)`;
  }
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
  };
};

export const getRotationMove = (box) => {
  const { rotation, width } = box;
  if (rotation === 0) {
    return { x: 0, y: 0 };
  }
  const w = width;
  const h = boxHeight(box);
  const aplhaZeroRad = Math.atan(h / w);
  const aplhaOneRad = aplhaZeroRad + (rotation * Math.PI) / 180;
  const hyp = Math.sqrt((w * w) / 4 + (h * h) / 4);
  const xOne = hyp * Math.cos(aplhaOneRad);
  const yOne = hyp * Math.sin(aplhaOneRad);
  return {
    x: xOne - w / 2,
    y: yOne - h / 2,
  };
};

export const getRotationStyle = (rotation) => {
  // let offset = '';
  // if (isDragging) {
  //   const offsetMove = getRotationMove(box);
  //   offset = isDragging ? `translate3d(${offsetMove.x}px, ${offsetMove.y}px, 0) ` : '';
  // }
  const transform = `rotate(${rotation}deg)`;
  return {
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
  };
};

export const getDragStyles = (isDragging, isHovered) => {
  const shouldNotDisplay = isDragging && isHovered;
  return {
    opacity: shouldNotDisplay ? 0 : 1,
    height: shouldNotDisplay ? 0 : '',
  };
};

export const boxActionColor = ({ selected, inGroup, hovered }) => {
  let color;
  switch (true) {
    case selected:
      color = 'transparent';
      break;
    case hovered:
      color = '#BAD6F2';
      break;
    case inGroup:
      color = '#5499DE';
      break;
    default:
      color = 'transparent';
      break;
  }
  // if (!selected && !inGroup && hovered) {
  //   color = "#5499DE";
  // } else if (selected && hovered) {
  //   color = '#BAD6F2';
  // } else if (inGroup && hovered) {
  //   color = '#BAD6F2';
  // } else if (selected) {
  //   color = "#5499DE";
  // } else if (inGroup) {
  //   color = "#5499DE";
  // } else {
  //   color = 'transparent';
  // }
  return color;
};

export const resizeHandlersPresence = ({
  top = false,
  right = false,
  bottom = false,
  left = false,
  topRight = false,
  bottomRight = false,
  bottomLeft = false,
  topLeft = false,
}) => ({
  top,
  right,
  bottom,
  left,
  topRight,
  bottomRight,
  bottomLeft,
  topLeft,
});

export const resizeHandlersStyles = {
  bottom: {
    width: '30px',
    height: '7px',
    marginTop: -3,
    marginLeft: -15,
    border: '1px solid ' + RESIZING_BORDER,
    backgroundColor: 'white',
    borderRadius: '25px',
    left: '50%',
    cursor: 'ns-resize',
    boxSizing: 'border-box',
    zIndex: 100,
    // transform: `rotate(${rotation}deg)`,
  },
  right: {
    width: '7px',
    height: '30px',
    marginTop: -15,
    marginLeft: -3,
    border: '1px solid ' + RESIZING_BORDER,
    backgroundColor: 'white',
    borderRadius: '25px',
    top: '50%',
    cursor: 'ew-resize',
    boxSizing: 'border-box',
    zIndex: 100,
    // transform: `rotate(${rotation}deg)`,
  },
};

export const variableStyle = {
  left: {
    borderRight: '0.5px solid white',
    paddingRight: 8,
  },
  middle: {
    borderRight: '0.5px solid white',
    paddingRight: 8,
    borderLeft: '0.5px solid white',
    paddingLeft: 8,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  right: {
    borderLeft: '0.5px solid white',
    paddingLeft: 8,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
};
