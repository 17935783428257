import React from 'react';
import ReactDOM from 'react-dom';
import 'draft-js/dist/Draft.css';
import './index.less';
import * as serviceWorker from './serviceWorker';
import './i18n';
import AppSelector from './appSelector';

// Fragment (<></>) wrapping app should be <React.StrictMode> but raise findDomNode error with Ant-design)
ReactDOM.render(<AppSelector />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
