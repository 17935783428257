import React from 'react'
import { convertPadding } from '../../utils/table';

const TableFooter = ({
  columnsLength,
  tableStyle,
}) => {
  if (!tableStyle.footer) return null
  const { padding } = convertPadding(tableStyle.size);

  return (
    <tfoot>
      <tr>
        <th
          colSpan={columnsLength}
          style={{
            padding: 0,
          }}
        >
          <div style={{
            backgroundColor: "#fafafa",
            border: "1px solid #f0f0f0",
            borderRadius: "0 0 8px 8px",
            color: "rgba(0, 0, 0, 0.88)",
            borderTop: 0,
            textAlign: 'left',
            fontSize: 12,
            padding
          }}>
            <div style={{
              padding: 4,
            }}>
              {tableStyle.footerData}
            </div>
          </div>
        </th>
      </tr>
    </tfoot>
  )
}

export default TableFooter
