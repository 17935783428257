import { Input } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useStore } from '../../../store/store'
import { mutateInjectVariablesInDocument } from '../../../utils/variables'
import { pageBackground } from '../../../constants/constants'
import { debounce } from 'lodash'
import { mutateSearchAndLaunchTrigger } from '../../../utils/triggers'

const selector = (variableKey, updateVariableFn) => ({
  variables,
  updateVariable,
}) => {
  return {
    variables,
    variable: variables[variableKey],
    updateVariable: updateVariableFn ?? updateVariable
  }
}

const CustomTextVariable = ({ variableKey, updateVariableFn, isBoxSimulated = false, variableValue }) => {
  const { variable, updateVariable, variables } = useStore(selector(variableKey, updateVariableFn))
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')
  const [value, setValue] = useState(variableValue ?? variable.value)
  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef()

  useEffect(() => {
    setValue(variableValue ?? variable.value)
  }, [variableValue, variable.value])

  const save = useCallback(debounce((val) => {
    if (isTemplate && !isBoxSimulated) return
    updateVariable(variableKey, (variable, draft) => {
      variable.value = val ?? '';
      if (!isBoxSimulated) {
        mutateSearchAndLaunchTrigger({ triggerId: variableKey, draft });
        mutateInjectVariablesInDocument({
          variables,
          variable,
          variableKey,
          draft
        })
      }
    })
  }, 100), [isBoxSimulated, isTemplate, updateVariable, variableKey, variables])

  useEffect(() => {
    if (isFocused)
      save(value)
  }, [save, value, isFocused]);

  return (
    <div style={{
      padding: "8px 16px",
      marginTop: 'auto'
    }}>
      <Input.TextArea
        value={value}
        data-role={pageBackground}
        ref={ref}
        onClick={() => ref.current.focus()}
        onChange={(e) => setValue(e.currentTarget.value ?? '')}
        placeholder="Insérer la valeur..."
        disabled={!isBoxSimulated && isTemplate}
        onFocus={() => setIsFocused(true)}
        onBlur={(e) => {
          setIsFocused(false)
          save(e.target.value)
        }}
      />
    </div>
  )
}

export default CustomTextVariable
