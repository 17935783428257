import React, { useCallback } from 'react'
import FormBoxName from './FormBoxName'
import FormBoxRemark from './FormBoxRemark'
import VariableDisposition from '../VariableDisposition'
import { useCreateVariableGroup, useUpdateVariableGroup } from '../../../hooks'
import { useStore } from '../../../store/store'
import { rawVariableGroupToStoreVariableGroup, variableGroupToRaw } from '../../../utils/variableGroups'
import { convertToRaw } from 'draft-js'
import ButtonCommon from '../../common/ButtonCommon'
import { useTranslation } from 'react-i18next'

const FormGroupInfos = ({ box, validateFormAndRetrieveValues, form, setCreateItemType }) => {
  const { t } = useTranslation();
  const addVariableGroup = useStore(({ addVariableGroup }) => addVariableGroup)
  const updateVariableGroup = useStore(({ updateVariableGroup }) => updateVariableGroup)
  const {
    mutate: updateVariableGroupMutation,
  } = useUpdateVariableGroup();
  const {
    mutate: createVariableGroup,
    isLoading
  } = useCreateVariableGroup();


  const handleOnBlur = useCallback(async () => {
    if (box.id === 'create-group') return;
    try {
      const values = await validateFormAndRetrieveValues();
      updateVariableGroupMutation(variableGroupToRaw({ id: box.id, name: values.name, remark: values.remark, disposition: values.disposition }), {
        onSuccess: (updatedVariableGroup) => {
          updateVariableGroup(box.id,
            (variableGroup) => {
              const newVG = rawVariableGroupToStoreVariableGroup(updatedVariableGroup)
              Object.keys(newVG).forEach((key) => {
                variableGroup[key] = newVG[key]
              }
              )
            })
        }
      })
    } catch (errors) {
    }
  }, [box.id, updateVariableGroup, updateVariableGroupMutation, validateFormAndRetrieveValues])

  const handleOnBlurRemark = async () => {
    if (box.id === 'create-group') return;
    try {
      const values = await validateFormAndRetrieveValues();
      updateVariableGroup(box.id, (variableGroup) => {
        variableGroup.remark = JSON.stringify(convertToRaw(values.remark.getCurrentContent()));
      })
    } catch (errors) {
    }
  }

  const onCreate = useCallback(async () => {
    try {
      const values = await validateFormAndRetrieveValues()
      createVariableGroup(variableGroupToRaw({ name: values.name, remark: values.remark, disposition: values.disposition }), {
        onSuccess: (newVariableGroup) => {
          addVariableGroup(rawVariableGroupToStoreVariableGroup(newVariableGroup),
            (newVariableGroup, draft) => {
              draft.boxVariableSelectedId = newVariableGroup.id
            })
          setCreateItemType()
        }
      })
    } catch (errors) {
    }
  }, [validateFormAndRetrieveValues, createVariableGroup, addVariableGroup, setCreateItemType])


  return (
    <div className='variable-editor__left'>
      {box.id === 'create-group' && <div style={{
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: 8
      }}>
        <ButtonCommon
          type="add"
          onClick={onCreate}
          loading={isLoading}
          disabled={isLoading}
        >
          {t('create')}
        </ButtonCommon>
      </div>}

      <FormBoxName box={box} onBlur={handleOnBlur} form={form} />
      <VariableDisposition box={box} onBlur={handleOnBlur} />
      <FormBoxRemark box={box} onBlur={handleOnBlurRemark} form={form} />
    </div>
  )
}

export default FormGroupInfos
