// ANT Design
import {
  DeleteColumnOutlined,
  DeleteRowOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import './../common/Utils.css';
import { useTranslation } from 'react-i18next';
import { iconButtonStyle } from '../../utils/styles';
import { NavbarButton } from '../Layout/NavbarButton';

const ButtonTableNavbar = ({ itemKey, type, onClick }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex' }}>
      <NavbarButton
        tooltipContent={t('left-alignment')}
        onClick={(e) => onClick({ action: 'alignment', value: 'start' }, itemKey)}
        disabled={type !== 'columns'}
        icon={< FontAwesomeIcon icon={icon({ name: 'align-left', style: 'light' })} style={iconButtonStyle()} />}
      />
      <NavbarButton
        tooltipContent={t('center-alignment')}
        onClick={(e) => onClick({ action: 'alignment', value: 'center' }, itemKey)}
        disabled={type !== 'columns'}
        icon={< FontAwesomeIcon icon={icon({ name: 'align-center', style: 'light' })} style={iconButtonStyle()} />}
      />
      <NavbarButton
        tooltipContent={t('right-alignment')}
        onClick={(e) => onClick({ action: 'alignment', value: 'end' }, itemKey)}
        disabled={type !== 'columns'}
        icon={< FontAwesomeIcon icon={icon({ name: 'align-right', style: 'light' })} style={iconButtonStyle()} />}
      />
      {/* <NavbarButton
        tooltipContent={t('image')}
        onClick={(e) => onClick({ action: 'type', value: 'image' }, itemKey)}
        disabled={type !== 'columns'}
        icon={< FontAwesomeIcon icon={icon({ name: 'image', style: 'light' })} style={iconButtonStyle()} />}
      />
      <NavbarButton
        tooltipContent={t('text')}
        onClick={(e) => onClick({ action: 'type', value: 'text' }, itemKey)}
        disabled={type !== 'columns'}
        icon={< FontAwesomeIcon icon={icon({ name: 'text', style: 'light' })} style={iconButtonStyle()} />}
      /> */}
      <NavbarButton
        tooltipContent={t('delete-column')}
        onClick={() => onClick({ action: 'remove' }, itemKey)}
        disabled={type !== 'columns'}
        icon={<DeleteColumnOutlined style={iconButtonStyle(type === 'columns')} />}
      />
      <NavbarButton
        tooltipContent={t('delete-row')}
        onClick={() => onClick({ action: 'remove' }, itemKey)}
        disabled={type !== 'data'}
        icon={<DeleteRowOutlined style={iconButtonStyle(type === 'data')} />}
      />
    </div>
  );
};

export default ButtonTableNavbar;
