import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// ANT Design
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
} from '@ant-design/icons';
import {
  message,
  Button,
  Modal,
  Table,
  Tabs,
  Tooltip,
  Popconfirm,
  Input,
  Form
} from 'antd';

import { useStore } from '../../store/store';
import { updateUrl } from '../../utils/browser';
import ModalImage from 'react-modal-image';
import { duplicateTemplate, updateTemplateInfos } from '../../requests/templates';
import { useModalOpener, useQueryDocumentTypes } from '../../hooks';
import DocumentTypesSelector from '../common/DocumentTypesSelector';
const { Search } = Input;

const selector = (state) => {
  return {
    applyTemplate: state.applyTemplate,
    deleteTemplate: state.deleteTemplate,
    currentDocument: state.currentDocument,
    currentProposal: state.currentProposal,
    proposal: state.currentProposal,
    applyTemplateAndSaveProposal: state.applyTemplateAndSaveProposal,
    user: state.user,
    templates: state.templates,
    sections: state.sections,
    changeCurrentTab: state.changeCurrentTab,
    updateTemplateInfosStore: state.updateTemplateInfosStore,
    duplicateTemplateStore: state.duplicateTemplateStore,
  };
};

const TemplateNameColumn = ({ record }) => {
  const { t } = useTranslation();
  return (
    <div
      type='text'
      style={{
        width: '100%',
        margin: 0,
        padding: 0,
        border: 0,
      }}
    >
      <div>{t(record.name)}</div>
    </div>
  );
};

const TemplateModal = ({
  open,
  handleOk,
  handleCancel,
  forbidClose,
  newTemplateOnClick,
  isAdmin,
}) => {
  const {
    applyTemplate,
    deleteTemplate,
    applyTemplateAndSaveProposal,
    currentDocument,
    currentProposal,
    templates,
    sections,
    changeCurrentTab,
    updateTemplateInfosStore,
    duplicateTemplateStore,
  } = useStore(selector);
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(null);
  const [nameSearch, setNameSearch] = useState('');

  const [isOpen, setIsOpen] = useModalOpener(false);
  const [modalSelection, setModalSelection] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [form] = Form.useForm();
  const { data: documentTypes } = useQueryDocumentTypes()

  const showModal = (origin, proposal) => {
    setIsOpen(true);
    setModalSelection(origin);
    setSelectedTemplate(proposal);
    if (origin === "updateTemplateInfos") {
      form.setFieldsValue({ name: proposal.name, document_type_ids: proposal.document_type_ids })
    }
    else
      form.setFieldsValue({ name: '' })
  };

  const handeApplyTemplate = (id) => {
    if (currentDocument.type === 'template') {
      updateUrl({ tid: id });
      applyTemplate(id);
      handleOk();
    } else if (currentProposal?.id) {
      applyTemplate(id);
      handleOk();
      message.success('Contenu modifié, mais non sauvegardé');
    } else {
      applyTemplateAndSaveProposal(id);
      handleOk();
    }
  };

  const handleSecondModalOk = () => {
    const values = form.getFieldsValue()
    if (modalSelection === 'updateTemplateInfos') {
      updateTemplateInfos(selectedTemplate.id, { name: values.name, document_type_ids: values.document_type_ids || [] }).then((e) => {
        updateTemplateInfosStore(e, currentTab);
      });
    } else if (modalSelection === 'duplicateTemplate') {
      duplicateTemplate(selectedTemplate.id, values.name).then(
        (e) => {
          duplicateTemplateStore(e);
        }
      );
    }
    setIsOpen(false);
  };
  const handleSecondModalCancel = () => {
    setIsOpen(false);
  };
  const cols = (documentType = 'template') => {
    const columns = [
      {
        title: 'Nom',
        key: 'name',
        className: 'nameColumn',
        width: '40%',
        onHeaderCell: () => ({
          style: {
            backgroundColor: '#0B2239',
            color: 'white',
          },
        }),
        onCell: (record) => {
          return {
            style: {
              wordWrap: 'anywhere',
              wordBreak: 'normal',
            },
            onClick: () => {
              handeApplyTemplate(record.id);
            },
          };
        },
        filteredValue: [nameSearch],
        onFilter: (value, record) => {
          return record.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(
              value
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
            );
        },
        render: (record) => (
          <TemplateNameColumn record={record} />
        ),
      },
      {
        title: 'Format',
        key: 'format',
        align: 'center',
        className: 'nameColumn',
        onHeaderCell: () => ({
          style: {
            backgroundColor: '#0B2239',
            color: 'white',
            borderLeft: '1px solid white',
          },
        }),
        render: (record) => (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {record.landscape ? (
              <Tooltip title='Format paysage'>
                <FileOutlined
                  style={{
                    transform: 'rotate(-90deg)',
                    fontSize: 18,
                    margin: 0,
                    marginRight: 8,
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title='Format portrait'>
                <FileOutlined
                  style={{
                    transform: 'scaleX(-1)',
                    fontSize: 18,
                    margin: 0,
                    marginRight: 8,
                  }}
                />
              </Tooltip>
            )}
          </p>
        ),
      },
    ];
    if (documentType === 'template') {
      columns.push({
        title: 'Type',
        key: 'templatable',
        align: 'center',
        onHeaderCell: () => ({
          style: {
            backgroundColor: '#0B2239',
            color: 'white',
            borderLeft: '1px solid white',
          },
        }),
        render: (record) => (
          <p style={{ textAlign: 'center', margin: 0 }}>
            {t(record.templatable)}
          </p>
        ),
      });
    }
    // if (user?.works)
    columns.push({
      title: 'Métier',
      key: 'work',
      align: 'center',
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#0B2239',
          color: 'white',
          borderLeft: '1px solid white',
        },
      }),
      render: (record) => (
        <p style={{ textAlign: 'center', margin: 0 }}>{t(record.work)}</p>
      ),
    });
    columns.push({
      title: 'Tags',
      key: 'tags',
      align: 'center',
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#0B2239',
          color: 'white',
          borderLeft: '1px solid white',
        },
      }),
      render: (record) => (
        <p style={{ textAlign: 'center', margin: 0 }}>
          {documentTypes?.filter((dT) => record.document_type_ids.includes(dT.id))
            .map((dT) => dT.name).join(", ")}
        </p>
      ),
    });
    // columns.push({
    //   title: 'Aperçu',
    //   key: 'preview',
    //   align: 'center',
    //   onHeaderCell: () => ({
    //     style: {
    //       backgroundColor: '#0B2239',
    //       color: 'white',
    //       borderLeft: '1px solid white',
    //     },
    //   }),
    //   render: (record) => (
    //     <div style={{ display: 'flex', justifyContent: 'center' }}>
    //       {record.preview ? (
    //         <div style={{ width: '40%' }}>
    //           <ModalImage
    //             small={record.preview}
    //             large={record.preview}
    //             alt='Aperçu'
    //           />
    //         </div>
    //       ) : (
    //         <p style={{ textAlign: 'center' }}>
    //           Aperçu non disponible
    //         </p>
    //       )}
    //     </div>
    //   ),
    // });
    columns.push({
      title: 'Actions',
      key: 'action',
      align: 'center',
      width: '10%',
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#0B2239',
          color: 'white',
          borderLeft: '1px solid white',
        },
      }),
      render: (text, record) => (
        <div
          style={{
            justifyContent: 'space-evenly',
            fontSize: '1.7em',
            display: 'flex',
          }}
        >
          {/* <TemplateActions record={record} deleteTemplate={deleteTemplate} /> */}
          <Tooltip title='Supprimer'>
            <Popconfirm
              title={'Supprimer ce document ?'}
              onConfirm={() => {
                deleteTemplate(record.id);
              }}
              okText={t('yes')}
              cancelText={t('cancel')}
            >
              <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title='Renommer'>
            <EditOutlined
              onClick={() => {
                showModal('updateTemplateInfos', record);
              }}
            />
          </Tooltip>
          <Tooltip title='Dupliquer'>
            <div
              style={{
                transform: 'rotate(180deg)',
                fontSize: '1em',
                marginTop: '-3%',
              }}
            >
              <CopyOutlined
                style={{ transform: 'scaleX(-1)' }}
                onClick={() => {
                  showModal('duplicateTemplate', record);
                }}
              />
            </div>
          </Tooltip>
        </div>
      ),
    });
    return columns;
  };

  const columns = cols(currentDocument.type).map((c) => {
    if (!c.editable) return c;

    return {
      ...c,
      onCell: (record) => ({
        record,
        editable: c.editable,
        dataIndex: c.dataIndex,
        title: c.title,
      }),
    };
  });
  const handleTabChange = (e) => {
    if (e === 'templates') setCurrentTab(false);
    else if (e === 'sections') setCurrentTab(true);
  };

  return (
    <Modal
      style={{ textAlign: 'center', minWidth: '730px' }}
      closable={!forbidClose}
      maskClosable={!forbidClose}
      title={
        forbidClose ? t('Choisissez un template ci dessous') : t('documents')
      }
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText={t('close')}
      okText={t('ok')}
      width='60%'
      okButtonProps={{ disabled: forbidClose }}
      cancelButtonProps={{ disabled: forbidClose }}
    >
      {isAdmin && currentDocument.type === 'template' && (
        <div style={{ paddingBottom: '20px' }}>
          <Button
            style={{ marginTop: 10, marginLeft: 0 }}
            onClick={() => {
              newTemplateOnClick();
              changeCurrentTab(currentTab);
            }}
          >
            {t('new-template')}
          </Button>
        </div>
      )}
      <Tabs
        defaultActiveKey='1'
        centered
        onTabClick={(e) => {
          handleTabChange(e);
        }}
      >
        <Tabs.TabPane tab='Modèles' key='templates'>
          <div
            style={{
              display: 'flex',
              width: '70%',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '1%',
            }}
          >
            <div style={{ width: '25%' }}>Recherche par nom:</div>
            <Search
              key='nameSearch'
              placeholder='Rechercher un modèle par nom'
              value={nameSearch}
              style={{
                width: '50%',
                marginBottom: '2%',
                marginTop: '2%',
                marginLeft: '2%',
              }}
              onChange={(e) => {
                setNameSearch(e.target.value);
              }}
            />
          </div>
          <Table pagination={false} columns={columns} dataSource={templates} rowKey={(record) => record.id} />
        </Tabs.TabPane>
        {currentDocument.type === 'template' ? (
          <Tabs.TabPane tab='Sections' key='sections'>
            <div
              style={{
                display: 'flex',
                width: '70%',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '1%',
              }}
            >
              <div style={{ width: '25%' }}>Recherche par nom:</div>
              <Search
                key='nameSearch'
                placeholder='Rechercher un modèle par nom'
                value={nameSearch}
                style={{
                  width: '50%',
                  marginBottom: '2%',
                  marginTop: '2%',
                  marginLeft: '2%',
                }}
                onChange={(e) => {
                  setNameSearch(e.target.value);
                }}
              />
            </div>
            <Table pagination={false} columns={columns} dataSource={sections} rowKey={(record) => record.id} />
          </Tabs.TabPane>
        ) : null}
      </Tabs>

      <Modal
        title={
          modalSelection === 'updateTemplateInfos'
            ? 'Edition du document'
            : modalSelection === 'createTemplate'
              ? 'Creation du document'
              : modalSelection === 'duplicateTemplate'
                ? 'Duplication du document'
                : null
        }
        open={isOpen}
        onOk={handleSecondModalOk}
        onCancel={handleSecondModalCancel}
        cancelText={t('cancel')}
        width='50%'
        destroyOnClose
      >
        {modalSelection === 'updateTemplateInfos' ? (
          <Form
            form={form}
            layout='horizontal'
            labelCol={{
              flex: "150px",
            }}
            labelAlign='left'
            style={{
              marginTop: 16
            }}
          >
            <Form.Item
              label="Nouveau nom"
              name="name"
            >
              <Input
                key='renameInput'
                placeholder='Nouveau nom'
                size='middle'
              />
            </Form.Item>
            <Form.Item
              label="Tags"
              name="document_type_ids"
            >
              <DocumentTypesSelector />
            </Form.Item>
          </Form>
        ) : modalSelection === 'duplicateTemplate' ? (
          <Form
            form={form}
            layout='horizontal'
            labelAlign='left'
            style={{
              marginTop: 16
            }}
          >
            <div style={{ marginBottom: '3%', display: 'flex' }}>
              Document dupliqué :
              <div style={{ fontWeight: 'bold', marginLeft: '1%' }}>
                {selectedTemplate.name}
              </div>
            </div>
            <Form.Item
              label="Nom du template dupliqué"
              name="name"
            >
              <Input
                key='renameInput'
                placeholder='Nouveau nom du modèle dupliqué'
                size='middle'
              />
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </Modal >
  );
};

export default TemplateModal;
