import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { FONT_SIZES } from '../../constants/constants';
import NavbarSelector from '../Layout/NavbarSelector';
import { NavbarButton } from '../Layout/NavbarButton';

const FontSizeControls = (props) => {
  var currentStyle = props?.editorState?.getCurrentInlineStyle();
  let selectedFontSize = props.defaultFontSize;
  props.fontSizes.forEach((type) => {
    if (currentStyle?.has(`FONT_SIZE_${type.value}`)) {
      selectedFontSize = type.value;
    }
  });

  const { t } = useTranslation();

  const fontSizeOptions = FONT_SIZES.filter((fs) => fs.display).map((type) => {
    return {
      value: type.value,
      label: type.label,
    }
  });

  return (
    <div
      id="font-size-control"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <NavbarButton
        tooltipContent={t('decrease-font-size')}
        buttonStyle={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRight: 0,
          marginRight: 0,
        }}
        icon={
          <FontAwesomeIcon
            icon={icon({ name: 'minus' })}
            style={{ fontSize: 16 }}
          />}
        onClick={() => {
          if (Number(selectedFontSize) > FONT_SIZES[0].value) {
            props.applyFontSize(selectedFontSize - 1);
          }
        }}
      />
      <NavbarSelector
        key="fontSizeSelector"
        tooltipContent={t('font-size')}
        onSelect={props.applyFontSize}
        selectedValue={selectedFontSize}
        options={fontSizeOptions}
        searchable={false}
        arrow={false}
        customStyle={{
          borderRadius: 0,
          width: 50,
          margin: 0,
        }}
      />
      <NavbarButton
        tooltipContent={t('increase-font-size')}
        buttonStyle={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: 0,
        }}
        icon={
          <FontAwesomeIcon
            icon={icon({ name: 'plus' })}
            style={{ fontSize: 16 }}
          />
        }
        onClick={() => {
          if (Number(selectedFontSize) < FONT_SIZES[FONT_SIZES.length - 1].value) {
            props.applyFontSize((selectedFontSize) + 1);
          }
        }}
      />
    </div >
  );
};

export default FontSizeControls;
