import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import CascaderCustom from '../../utils/CascaderCustom';
import { isATableBox } from '../../utils/boxes';

const selector = (state) => state.tables;
const TableCascader = ({ onChange, selectedBoxType, selectedBox }) => {
  const tables = useStore(selector);
  const { t } = useTranslation();
  const formattingCascaderData = (initialData) => {
    let processedData = [];

    // Vérifiez si offer_tables est un tableau et le trier si c'est le cas
    const offerTables = Array.isArray(initialData.offer_tables) ? [...initialData.offer_tables] : [];
    const sortedOfferTables = offerTables.sort((a, b) => t(a).localeCompare(t(b)));

    // Traiter les offer_tables triées
    sortedOfferTables.forEach((table) => {
      processedData.push({
        value: table,
        label: t(table),
      });
    });

    // Traiter les opportunities_table
    initialData.opportunities_table?.forEach((opportunity) => {
      // Vérifiez si opportunity_tables est un tableau et le trier si c'est le cas
      const opportunityTables = Array.isArray(opportunity.opportunity_tables) ? [...opportunity.opportunity_tables] : [];
      const sortedChildren = opportunityTables.sort((a, b) => t(a).localeCompare(t(b)));

      // Créer les objets enfants après tri
      const children = sortedChildren.map((table) => {
        return {
          key: table,
          value: table,
          label: t(table),
        };
      });

      processedData.push({
        key: opportunity.opportunity_id,
        value: opportunity.opportunity_id,
        label: opportunity.opportunity_name,
        children: children,
      });
    });

    // Si processedData est toujours vide, ajouter les tables sans transformation, triées par t(table)
    if (processedData.length <= 0) {
      // Vérifiez si initialData est un tableau et le trier si c'est le cas
      const initialDataArray = Array.isArray(initialData) ? [...initialData] : [];
      const sortedInitialData = initialDataArray.sort((a, b) => t(a).localeCompare(t(b)));

      sortedInitialData.forEach((table) => {
        processedData.push({
          key: table,
          value: table,
          label: t(table),
        });
      });
    }

    return processedData;
  };
  const componentOnChange = (value) => {
    if (value.length >= 2) {
      onChange(value[1], { id: value[0], type: 'opportunity' });
    } else if (value.length === 1) {
      onChange(value[0]);
    } else {
      onChange(undefined);
    }
  };
  return (
    <CascaderCustom
      sytleCustom={{ height: 32, width: 170 }}
      disableCascader={!isATableBox(selectedBoxType)}
      tablesToDisplay={formattingCascaderData(tables)}
      functionToExec={componentOnChange}
      placeholderToDisplay='Variables Tableaux'
      selectedBox={selectedBox}
    />
  );
};
export default TableCascader;
