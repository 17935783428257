import React from 'react'
import { useStore } from '../../../store/store'
import { Dragger } from '../../common/Dragger'
import ContainerWrapper from '../../Container/ContainerWrapper'
import { gridUnit } from '../../../constants/gridConfig'
import { isASelectionBox, simulateVariableTextBox } from '../../../utils/boxes'
import CustomTextVariable from './CustomTextVariable'
import { ItemTypes, viewerTypes } from '../../../constants/constants'

const selector = (box) => ({
  columns,
  landscape,
  containers,
  configuration,
}) => {
  const hasBox = !!box
  const isCustomVariableText = hasBox && box.type === ItemTypes.CUSTOM_TEXT_VARIABLE
  const drawMode = hasBox && !isCustomVariableText && columns.find((c) => c.id === box.columnId)?.drawMode
  return {
    isCustomVariableText,
    container: hasBox && !isCustomVariableText && containers.find((c) => c.columnsIds.includes(box.columnId)),
    drawMode,
    landscape,
    lineSpacing: configuration.lineSpacing,
    marginLeft: configuration.margins.left * gridUnit,
    marginRight: configuration.margins.right * gridUnit,
    showOnlyBox: hasBox && ((drawMode && !isASelectionBox(box?.type)) || !box?.columnId),
    hasBox
  }
}

const FormBox = ({ boxId }) => {
  const box = useStore(({ boxes, variables }) => {
    const boxInStore = boxes.find((b) => b.id === boxId)
    if (boxInStore) return boxInStore
    const variableEntry = Object.entries(variables).find(([key]) => key === boxId)
    if (variableEntry) return simulateVariableTextBox(variableEntry)
  })

  const { container, drawMode, marginLeft, marginRight, isCustomVariableText, showOnlyBox, hasBox, lineSpacing } = useStore(selector(box))

  if (!hasBox) return null

  return (
    <>
      {!isCustomVariableText && !showOnlyBox && <ContainerWrapper
        containerId={container.id}
        fromViewer={viewerTypes.VARIABLES}
        type='container'
      />
      }
      {!isCustomVariableText && showOnlyBox &&
        <div style={{
          width: 'fit-content',
          paddingLeft: marginLeft,
          paddingRight: marginRight,
          position: 'relative'
        }}>
          <Dragger fromViewer={viewerTypes.VARIABLES} id={boxId} drawMode={drawMode} />
        </div>
      }
      {isCustomVariableText && <CustomTextVariable variableKey={boxId} />}
      <div style={{ height: lineSpacing, width: "100%" }}></div>
    </>
  )
}

export default FormBox
