import { InputNumber, Tooltip } from "antd";
import { useState } from "react";
import { useKeyboard } from "../../hooks";
import { navbarButtonStyle } from "../../utils/styles";

export const NavbarInputNumber = ({
  tooltipContent,
  value,
  onChange,
  min,
  max,
  disabled = false,
  suffix = null,
  defaultValue = 1
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const setIsUsingKeyboard = useKeyboard(false)

  const inputNumberStyle = {
    ...navbarButtonStyle,
    width: 70,
  }

  return (
    <Tooltip
      open={tooltipVisible}
      placement='bottom'
      mouseLeaveDelay='0'
      title={tooltipContent}
    >
      <InputNumber
        disabled={disabled}
        onMouseEnter={() => {
          setTooltipVisible(true)
        }
        }
        onMouseLeave={() => setTooltipVisible(false)}
        onFocus={() => setIsUsingKeyboard(true)}
        onBlur={() => setIsUsingKeyboard(false)}
        style={inputNumberStyle}
        formatter={suffix ? ((value) => `${value}${suffix}`) : null}
        parser={suffix ? (value) => value?.replace(suffix, '') : null}
        min={min}
        max={max}
        value={value}
        onChange={(value) => value ? onChange(value) : onChange(defaultValue)}
      />
    </Tooltip>
  )
}
