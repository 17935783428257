// ANT Design
import { useStore } from '../../store/store';
import { isASelectionBox } from '../../utils/boxes';
// Common
import { Dragger } from './Dragger';
import SelectionBox from './SelectionBox';
import Resizer from './Resizer';
import './Utils.css';
import { viewerTypes } from '../../constants/constants';

const selector =
  (id) =>
    ({
      selectedBoxId,
      groupSelection,
      slider,
      boxes,
      masks,
    }) => ({
      isSelected: selectedBoxId === id,
      isSelectionBox: isASelectionBox(boxes.find((b) => b.id === id)?.type),
      isInSelectedGroup: groupSelection.includes(id),
      isSlided: slider.boxes.includes(id),
      box: [
        ...boxes,
        ...masks.map((m) => m.boxes).flat()
      ].find((b) => b.id === id),
    });

const BoxWrapper = ({ id, drawMode, columnWidth, fromViewer }) => {
  const {
    isSelected,
    isSelectionBox,
  } = useStore(selector(id));

  if (isSelectionBox) {
    return <SelectionBox id={id} />;
  }

  if (isSelected && fromViewer !== viewerTypes.PAGENUMBER) {
    return <Resizer id={id} drawMode={drawMode} columnWidth={columnWidth} />;
  } else {
    return <Dragger id={id} drawMode={drawMode} fromViewer={fromViewer} />;
  }
};

export default BoxWrapper;
