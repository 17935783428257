import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
// import Select from 'rc-select';
import { useTranslation } from 'react-i18next';
import { iconButtonStyle } from '../../utils/styles'
import { useStore } from '../../store/store';
import { onChangeColor } from '../RichEditor/utils';
import { NavbarListButton } from './NavbarListButton';
import { useCallback } from 'react';
import { ItemTypes } from '../../constants/constants';
import { isASelectionBox, isAVariableBox } from '../../utils/boxes';

const selector = ({ updateBox, configuration, selectedBoxId, boxes, masks, containers, currentProposal }) => {
  const box = [
    ...boxes,
    ...masks.map((m) => m.boxes).flat(),
  ].find((box) => selectedBoxId && box.id === selectedBoxId)
  const container = containers.find(container => container.columnsIds.includes(box?.columnId))

  return {
    updateBox,
    configuration,
    selectedBox: box,
    container: container,
    currentProposal,
    isSelectionBox: isASelectionBox(boxes.find((b) => b.id === selectedBoxId)?.type),

  }
};

export const BackgroundColorPicker = () => {
  const { t } = useTranslation();
  const { updateBox, configuration, selectedBox, container, currentProposal, isSelectionBox } = useStore(selector);

  const selectedBoxIsShape =
    selectedBox?.type === 'shape' || selectedBox?.type === 'line';
  const bgColorDisabled =
    selectedBox &&
    ['title1', 'title2', 'title3', 'title4', 'summary', 'table', ItemTypes.TABLE_VARIABLE].includes(
      selectedBox.type
    );

  const toggleBgColor = (newColor) => {
    if (selectedBoxIsShape) {
      updateBox(selectedBox.id, (box) => {
        const colorStr = configuration.colors.find(
          (color) => color.label === newColor
        ).style;
        if (box.color) {
          box.color = colorStr;
        } else if (box.content) {
          box.content.color = colorStr;
        } else {
          box.backgroundColor = colorStr;
        }
      });
    } else {
      onChangeColor(
        selectedBox.id,
        updateBox,
        configuration
      )(newColor);
    }
  }

  const unCommonBox =
    !selectedBox ||
    ['title1', 'title2', 'title3', 'title4', 'summary', 'line_break'].includes(selectedBox?.type) ||
    isAVariableBox(selectedBox?.type)

  const backgroundColorPickerDisabled = useCallback(() => {
    if (isSelectionBox) return true
    if (!container?.editable &&
      container?.hasOwnProperty('editable') &&
      currentProposal.id !== null) {
      return true
    } else return unCommonBox || bgColorDisabled
  }, [isSelectionBox, container, currentProposal?.id, unCommonBox, bgColorDisabled])

  return (
    <NavbarListButton
      key="bgColorList"
      title={
        selectedBox?.type === 'shape'
          ? 'Couleur de forme'
          : t('background-color')
      }
      disabled={backgroundColorPickerDisabled()}
      icon={< FontAwesomeIcon icon={icon({ name: 'fill-drip', style: 'light' })} style={iconButtonStyle()} />}
      onListElementClick={toggleBgColor}
      listElements={selectedBoxIsShape ? configuration.colors.filter((c) => c.label !== 'transparent') : configuration.colors}
      selectedElement={selectedBox ? (selectedBoxIsShape ? configuration.colors.find((c) => c.style === (selectedBox.color ?? selectedBox.content.color)) : selectedBox.backgroundColor) : null}
    />
  );
};
